import {
  Typography,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  DialogContentText,
  Divider,
  AvatarGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button as MuiButton,
  Menu,
  MenuItem,
  TableContainer,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Pagination,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardArrowDown, OpenInNew, Troubleshoot } from "@mui/icons-material";
import { Button } from "../components";
import { useState, useMemo, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import launch from "../assets/creatorOutreachAvatar.PNG";
import brand_clip from "../assets/brands_clip.PNG";
import brand_insights from "../assets/brand insights.PNG";
import TrendingHashtags from "../assets/TrendingHashtags.PNG";
import Olympics from "../assets/Olympics.PNG";
import familyTravel from "../assets/familyTravel.PNG";
import MoreCreators from "../assets/moreCreators.PNG";
import RedirectIcon from "../assets/trending/elements.png"
import AudioTrack from "../assets/trending/audio-track.png"
import Carousel from "react-multi-carousel";
import TrendingNow from "../assets/trending/trending-now.png"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { parseFollowers, fetchGet, formatStat, PLATFORMS, PLATFORMTOVALUE } from "../util/helpers";
import { InstagramEmbed } from "react-social-media-embed";
import SectionHeader from "./SectionHeader";
import InformationRow, { RightInformation } from "./InformationRow";
import InformationHeader from "./InformationHeader";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import {BRAND_CONTENT_FORMATE, POPULAR_BRAND_CHANNEL, SOCIAL_LISTING, TRENDING_AUDIO, TRENDING_HASHTAG, TOP_CREATORS} from "../util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopCreatorCard from "./TopCreatorCard";
import { IconChartArrows, IconChartBarPopular, IconChartDots, IconChevronLeft, IconChevronRight, IconExternalLink, IconHash, IconMessages, IconPhotoSpark, IconPlaylist, IconTrendingUp, IconUsersGroup, IconVideo } from "@tabler/icons-react";




const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px', // Rounded border for the Menu
    marginTop: theme.spacing(.8), // Space between button and menu
  },
}));

const channelsROI = [{ platform: "Instagram", roi: 47 }, { platform: "Facebook", roi: 30 }, { platform: "Youtube", roi: 25 }, { platform: "TikTok", roi: 19 }, { platform: "LinkedIn", roi: 10 }, { platform: "Twitter", roi: 4 }]



const topPartnershipFormats = [{
  title: "Sponsored Content",
  subtitle: "Brands pay creators to feature products in their regular content.",
  icon: "color-dollor"
},
{
  title: "Affiliate Marketing",
  subtitle: "Creators promote products with a tracking link and earn a commission on sales.",
  icon: "color-link"
},
{
  title: "Product Reviews",
  subtitle: "Creators offer in-depth reviews of products to their audience.",
  icon: "color-star"
},
{
  title: "Giveaways/Contests",
  subtitle: "Creators run giveaways or contests to promote brand awareness.",
  icon: "color-gift"
},
{
  title: "Long-Term Ambassador",
  subtitle: "Brands partner with creators over the long term for ongoing promotion.",
  icon: "color-users"
}
]

const popularVideoContent = [{
  title: "Vlogging & GRWM",
  industries: ["Beauty", "Fashion", "Lifestyle"],
  engagementRate: "High",
  topContent: ["Short Form Video"],
  iconImg: "trending/music.png"
},
{
  title: "How To’s & DIY",
  industries: ["Home & Garden", "Business"],
  engagementRate: "High",
  topContent: ["Short Form Video"],
  iconImg: "trending/topscore.png"
},
{
  title: "Discussion & Reviews",
  industries: ["Business", "Technology", "Entertainment"],
  engagementRate: "High",
  topContent: ["Long Form Video", "Podcasts"],
  iconImg: "trending/discussion.png"
},
{
  title: "ASMR",
  industries: ["Food & Beverage", "Beauty", "Automotive"],
  engagementRate: "High",
  topContent: ["Long Form Video"],
  iconImg: "trending/travel.png"
},
{
  title: "Recipes & Cooking",
  industries: ["Food & Beverage", "Home & Garden", "Lifestyle"],
  engagementRate: "High",
  topContent: ["Short Form Video"],
  iconImg: "trending/bulb.png"
}
]

export const CustomGrid = styled(DataGrid)(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
  height: "60vh",
  width: "100%",
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: "#F9FAFB",
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontSize: ".8rem",
    fontWeight: "600"
  },
}));

export const CustomOutlinedButton = styled(MuiButton)(({ theme }) => ({
  color: "black",
  textTransform: "none",
  border: "1px solid black",
  '&:hover': {
    backgroundColor: "white",
    borderColor: "black",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
  }

}))


const Landscape = () => {
  const [rightGridHeight, setRightGridHeight] = useState(0);
  const rightGridRef = useRef(null);
  const navigate = useNavigate();
  const [nameSearch, setNameSearch] = useState("");
  const [landScapeData, setLanScapeData] = useState([]);
  const [externalDialogOpen, setExternalDialogOpen] = useState(false)
  const [externalCreator, setExternalCreator] = useState(null)
  const [externalLoading, setExternalLoading] = useState(false)
  const [socialExpanded, setSocialExpanded] = useState(-1);
  const [soundPlatform,setSoundPlatform] = useState("");
  const [hashtagPlatform, setHashtagPlatform] = useState("");
  const [brandTablePage, setBrandTablePage] = useState(0);
  const [brandRowsPerPage, setBrandRowsPerPage] = useState(10);
  const [listeningPage, setListeningPage] = useState(1);
  const [listeningRowsPerPage, setListeningRowsPerPage] = useState(6);

  const menuList = ["","July 2024", "August 2024", "September 2024", "October 2024"]
  const [socialTimeframe, setSocialTimeframe] = useState("");

  const handleChangePage = (newPage, type) => {
    if(type === "brand"){
      setBrandTablePage(newPage);
    }
    if(type === "listening"){
      setListeningPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event, type) => {
    if(type === "brand"){
      setBrandRowsPerPage(parseInt(event.target.value, 10));
      setBrandTablePage(0);
    }
    if(type === "listening"){
      setListeningRowsPerPage(parseInt(event.target.value, 10));
      setListeningPage(0);
    }
  };

  const handleSocialChange = (panel) => (event, isExpanded) => {
    setSocialExpanded(isExpanded ? panel : false);
  };

  const followerComparator = (a, b) => {
    return parseFollowers(a) - parseFollowers(b);

  };
  const landscapeHeaders = ["Brand", "Industry", "Instagram", "YouTube", "TikTok", "LinkedIn", "Twitter", "Visit Primary Channel"]
  
  const fetchLandScape = async () => {
    fetchGet(`/brand/landscape`)
      .then((result) => result.result)
      .then((result) => {
        setLanScapeData(result.map((res) => ({ ...res, id: res.company })));
      });
  }
  useEffect(() => {
    fetchLandScape();
  }, []);

  useEffect(() => {
    if (externalDialogOpen && nameSearch) {
      setExternalLoading(true)
      fetchGet(`/brand/external/outreach?username=${nameSearch}`)
        .then((res) => {
          setExternalCreator(res)
        })
        .finally(() => setExternalLoading(false))
    }
  }, [externalDialogOpen, nameSearch])

  useEffect(() => {
    if (rightGridRef.current) {
      const updateHeight = () => {
        setRightGridHeight(rightGridRef.current.clientHeight);
      };

      updateHeight();

      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [rightGridRef.current]);

  const filteredLandscapeCompanies = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.company.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.primary_channel.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };
    const filtered = landScapeData.filter((rowData) => {
      return checkName(rowData);
    })
    if (nameSearch && filtered.length < 10) {
      filtered.push({
        id: 'custom-row',
        company: "",
        followers: "",
        industry: "Can't find the brand?",
        instagram_followers: "",
        youtube_followers: "",
        tiktok_followers: "",
        linkedin_followers: "",
        twitter_followers: "",
        actions: ""
      })
    }
    return filtered
  }, [landScapeData, nameSearch])

  console.log("top creators", TOP_CREATORS);

  const filteredSocialListing = useMemo(()=>{
    if(socialTimeframe){
      return SOCIAL_LISTING.filter((data)=>data.timeline === socialTimeframe);

    }
    return SOCIAL_LISTING;
  },[socialTimeframe])

  const filteredSounds = useMemo(()=>{
    console.log({soundPlatform});
    
    if(soundPlatform != "" && soundPlatform != "All Platform"){
      return TRENDING_AUDIO.filter((data)=>data.platforms.includes(soundPlatform.toLowerCase()));

    }
    return TRENDING_AUDIO;
  },[soundPlatform])

  const filteredHashtags = useMemo(()=>{
    if(hashtagPlatform != "" && hashtagPlatform != "All Platform"){
      return TRENDING_HASHTAG.filter((data)=>data.platforms.includes(hashtagPlatform.toLowerCase()));
    }
    return TRENDING_HASHTAG;
  },[hashtagPlatform])

  const responsive = { //For carousel responsiveness 
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  }

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div style={{position: "absolute", bottom: "0", right: "50%"}}>
        <IconButton onClick={() => previous()}>
          <IconChevronLeft size={20} className="icon-colored" color="var(--body-text-color-600)"/>
        </IconButton>
        <IconButton onClick={() => next()}>
          <IconChevronRight size={20} className="icon-colored" color="var(--body-text-color-600)"/>
        </IconButton>
      </div>
    );
  };

  return (
    <Box sx={{height: "100%", width: "100%", padding: "3%"}}>
      <Stack direction="column" spacing={4} width="100%">
          <Typography variant="h2">Trending</Typography>
          <Stack direction="column" spacing={2} width="100%">
              <Stack direction="row" spacing={2}>
                  <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                      <IconChartBarPopular size="20"className="icon"/>
                  </Box>
                  <Typography variant="h3">Competitive Landscape</Typography>
              </Stack>
              <Stack className="box" direction="column" spacing={1} alignItems="center">
              <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                          {landscapeHeaders.map((header) => (
                            <TableCell>
                              <Typography variant="bodysm" color="var(--body-text-color-600)">
                                {header}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLandscapeCompanies && filteredLandscapeCompanies.slice(brandTablePage * brandRowsPerPage, (brandTablePage * brandRowsPerPage) + brandRowsPerPage).map((company) => <TableRow key={company.id}>
                            <TableCell width="20%">
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.company}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.industry}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.instagram_followers}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.youtube_followers}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.tiktok_followers}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.linkedin_followers}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="bodysm" color="var(--main-text-color)">{company.twitter_followers}</Typography>
                            </TableCell>
                            <TableCell>
                            <IconButton
                              sx={{
                                width: 30, height: 30, backgroundColor: "#4158D0",
                                color:"white",
                                fontSize:"17px",
                                backgroundImage: "linear-gradient(to right, #5CA4FB,#823FF9,#BC0BF3,#ED0CE9)",
                              }}
                              onClick={() => {
                                if (company.id === "custom-row") {
                                  setExternalDialogOpen(true)
                                } else {
                                  window.open(company.primary_channel, "blank")
                                }
                                }}
                              >
                                <IconExternalLink size={17} className="icon" />
                              </IconButton>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
              count={filteredLandscapeCompanies.length}
              page={brandTablePage}
              onPageChange={(event, newPage) => handleChangePage(newPage, "brand")}
              rowsPerPage={brandRowsPerPage}
              onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, "brand")}
            />
            </Stack>
            </Stack>
            <Stack direction="column" spacing={2} width="80vw">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                  <IconMessages size="20"className="icon"/>
                </Box>
                <Stack direction="column">
                  <Typography variant="h3">Popular Brand Channels</Typography>
                  <Typography variant="bodyreg" color="var(--body-text-color-500)">Discover the top platforms brands are leveraging for influencer marketing</Typography>
                </Stack>
              </Stack>

              <Carousel
                additionalTransfrom={0}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="landscape-carousel-container"
                draggable={false}
                focusOnSelect={false}
                infinite
                itemClass="landscape-carousel-item"
                keyBoardControl
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
              { POPULAR_BRAND_CHANNEL.map((data)=> {
                  return (
                  <div className="info-card h-300" style={{margin: "10px"}}>
                  <InformationHeader header={data.platform} headerImg={data.icon}>
                  </InformationHeader>
                  <Divider />
                  <div className="info-card-body">
                    <InformationRow title="Audience" subtitle={`${data.audience} Monthly Active Users`} icon="audience" subtitleClass="text-black" titleClass="text-light" />
                    <InformationRow title="Engagement Rate" subtitle={data.engagementRate} icon="engagementrate" subtitleClass="text-black" titleClass="text-light" />
                    <InformationRow title="Popular Format" subtitle={data.popularFormate.join(", ")} icon="popularformat" subtitleClass="text-black" titleClass="text-light" />
                    <InformationRow title="Growth Rate" subtitle={`${data.growthRate} Year-over-Year`} icon="growthrate" subtitleClass="text-black" titleClass="text-light" showDivider={false} />

                  </div>
                  </div>
                )})
              }
            </Carousel>
            </Stack>
            <Stack direction="column" spacing={2} width="80vw">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                  <IconPhotoSpark size="20"className="icon"/>
                </Box>
                <Stack direction="column">
                  <Typography variant="h3">Popular Brand Content Formats</Typography>
                  <Typography variant="bodyreg" color="var(--body-text-color-500)">Discover which content formats brands are using to drive engagement across platforms</Typography>
                </Stack>
              </Stack>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="landscape-carousel-container"
                draggable={false}
                focusOnSelect={false}
                infinite
                itemClass="landscape-carousel-item"
                keyBoardControl
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass="landscape-carousel-slider"
                slidesToSlide={1}
                swipeable
              >
                {
                  BRAND_CONTENT_FORMATE.map((data)=>{
                    return <div className="info-card h-200">
                    <InformationHeader header={data.title} headerImg={data.icon} headerSubtitle={<div className="star-row">{[1, 2, 3, 4, 5].map((i) => <StartRow key={i} />)}</div>} />
                    <Divider />
                    <div className="info-card-body">
                      <InformationRow title="Engagement Rate" subtitle={data.engagementRate} icon="engagementrate" subtitleClass="text-black" titleClass="text-light" />
                      <InformationRow title="Platform" subtitle={<PlatformRow platforms={data.platforms} />} icon="popularformat" subtitleClass="text-black" titleClass="text-light" showDivider={false} />
                    </div>
                  </div>
                  })
                }
              </Carousel>
            </Stack>
            <Stack direction="column" spacing={2} width="80vw">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                    <IconTrendingUp size="20"className="icon"/>
                  </Box>
                  <Stack direction="column">
                    <Typography variant="h3">Social Listening</Typography>
                    <Typography variant="bodyreg" color="var(--body-text-color-500)">Explore the top trending topics and conversations across social media platforms this month</Typography>
                  </Stack>
                </Stack>
                <FilterDropdown btnText={"All Listening"} btnIcon={faAngleDown} menuList={menuList} selectedOptions={socialTimeframe} setSelectedOptions={setSocialTimeframe}   />
              </Stack>
              <Stack direction="column" width="100%" alignItems="center">
                { filteredSocialListing.slice(listeningPage * listeningRowsPerPage, (listeningPage * listeningRowsPerPage) + listeningRowsPerPage).map((listing, i) => {
                  return <Accordion sx={{
                    border: 'none',
                    borderRadius: "10px",
                    '&:before': {
                      display: 'none',
                    },
                    boxShadow: "none",
                    marginY: "5px",
                  }} key={i} expanded={socialExpanded == i} onChange={handleSocialChange(i)}>
                    <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                      <div className="accordion-header-container">
                        <div className="acocrdion-header">
                          <div className="accordion-order">{i + 1}</div>
                          <div className="accordion-title">{listing.title}</div>
                        </div>
                        <div className={`accordion-desc ${socialExpanded == i && 'd-none'}`}>
                          {listing.headerDesc}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="body-desc" dangerouslySetInnerHTML={{__html: listing.description}}>
                        </div>
                      <Divider />
                    </AccordionDetails>
                    <AccordionActions>
                      <div className="accordion-footer">
                        <div className="left-footer">
                          Primary Channels
                        </div>
                        <div className="right-footer">
                        <PlatformRow platforms={listing.primaryChannel} />
                        </div>
                      </div>
                    </AccordionActions>
                  </Accordion>
                })
              }
              <Pagination style={{marginY: "10px"}} count={Math.ceil(filteredSocialListing.length / listeningRowsPerPage - 1)} page={listeningPage} onChange={(event, newPage) => handleChangePage(newPage, "listening")} />
            </Stack>
            </Stack>
          <div className="full-row">
            <div className="w-50 info-card">
              <div className="card-header">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                    <IconUsersGroup size="20"className="icon"/>
                  </Box>
                  <Typography variant="h4" color="var(--main-text-color)">Top Creator Partnership Formats</Typography>
                </Stack>
              </div>
              <Divider />
              <div className="info-card-body mb-1">
                {
                  topPartnershipFormats.map((data, i) => {
                    return <>
                      <InformationRow key={i} title={data.title} subtitle={data.subtitle} icon={data.icon} subtitleClass="text-light" titleClass="text-black" showDivider={false} />
                      {i != topPartnershipFormats.length - 1 && <Divider />}
                    </>

                  })
                }
              </div>
            </div>
            <div className="w-50 info-card">
              <div className="card-header">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                    <IconChartArrows size="20"className="icon"/>
                  </Box>
                  <Typography variant="h4" color="var(--main-text-color)">Top Channels Driving ROI</Typography>
                </Stack>
              </div>
              <Divider />
              <div className="info-card-body mb-1">
                <ResponsiveContainer width="100%" height={305}>
                  <BarChart data={channelsROI} margin={{ top: 20, right: 10 }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={.5} />
                    <XAxis tickLine={false} axisLine={false} dataKey="platform" tick={{ fontSize: ".8rem", fill: "#6B7280", paddingTop: "10px" }} />
                    <YAxis tickLine={false} axisLine={false} tick={{ fontSize: ".8rem", fill: "#6B7280", paddingTop: "10px" }} tickCount={6} tickFormatter={(tick) => `${tick}%`} />
                    <Tooltip />
                    <Bar dataKey="roi" fill="#7A5AF8" radius={[5, 5, 0, 0]} barSize={30} />

                  </BarChart>

                </ResponsiveContainer>
                <BarChart
                  dataset={channelsROI}
                  xAxis={[{ scaleType: "band", dataKey: "platform" }]}
                  series={[{ dataKey: "roi", label: "Conversions" }]}
                  height={300}
                  colors={["#7A5AF8"]}
                  slotProps={{
                    borderRadius: "10px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="full-row">
            <div className="w-50 info-card ">
              <div className="card-header-row">
                <div className="card-header">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                      <IconPlaylist size="20"className="icon"/>
                    </Box>
                    <Typography variant="h4" color="var(--main-text-color)">Trending Sounds and Audio</Typography>
                  </Stack>  
                </div>
                {/* <div className="card-header-filter">
                  <FilterDropdown btnIcon={faAngleDown} btnText={soundPlatform ? PLATFORMTOVALUE[soundPlatform] :"All Platform"} menuList={[{label:"All Platform", value:""},...PLATFORMS]} selectedOptions={soundPlatform} setSelectedOptions={setSoundPlatform} />
                </div> */}
              </div>
              
              <Divider />
              <div className="info-card-body mb-1 h-270">
                {
                  filteredSounds.map((data, i) => <InformationRow key={i} title={<a href={data.link} target="blank">{data.title}</a>} icon="btn_play" rowClass="align-center" titleClass="text-black" subtitle={<img src={AudioTrack} alt="audio" />} showDivider={filteredSounds.length - 1 != i}>
                    <RightInformation totalPost={data.posts} platforms={data.platforms} />
                  </InformationRow>)
                }
              </div>
            </div>
            <div className="w-50 info-card">
              <div className="card-header-row">
                <div className="card-header">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                      <IconHash size="20"className="icon"/>
                    </Box>
                    <Typography variant="h4" color="var(--main-text-color)">Top Trending Hashtags</Typography>
                  </Stack>
                </div>
                <div className="card-header-filter">
                  <FilterDropdown btnIcon={faAngleDown} btnText={hashtagPlatform ? PLATFORMTOVALUE[hashtagPlatform] :"All Platform"} menuList={[{label:"All Platform", value:""},...PLATFORMS]} selectedOptions={hashtagPlatform} setSelectedOptions={setHashtagPlatform} />
                </div>
              </div>
              <Divider />
              <div className="info-card-body mb-1 h-270">
                {
                  filteredHashtags.map((data, i) => <InformationRow key={i} title={data.hashtag} icon="trending" rowClass="align-center" titleClass="text-black" showDivider={filteredHashtags.length - 1 != i}>
                    <RightInformation totalPost={data.posts} platforms={data.platforms} />
                  </InformationRow>)
                }
              </div>
            </div>
          </div>
          <Stack direction="column" spacing={2} width="80vw">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                  <IconVideo size="20"className="icon"/>
                </Box>
                <Typography variant="h3">Popular Video Content</Typography>
              </Stack>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="landscape-carousel-container"
                draggable={false}
                focusOnSelect={false}
                infinite
                itemClass="landscape-carousel-item"
                keyBoardControl
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass="landscape-carousel-slider"
                slidesToSlide={1}
                swipeable
              >
                {popularVideoContent.map((data) => {
                  return <div className="info-card gap-5 h-250">
                    <InformationHeader header={data.title} headerImg={data.iconImg} />
                    <Divider />
                    <div className="info-card-body">
                      <InformationRow title="Best Performing Industries" subtitle={data.industries.join(", ")} icon="audience" subtitleClass="text-black" titleClass="text-light" />
                      <InformationRow title="Engagement Rate" subtitle={data.engagementRate} icon="engagementrate" subtitleClass="text-black" titleClass="text-light" />
                      <InformationRow title="Top Content" subtitle={data.topContent.join(", ")} icon="popularformat" subtitleClass="text-black" titleClass="text-light" showDivider={false} />
                    </div>
                  </div>
                })}
              </Carousel>
          </Stack>
          {/* <Stack direction="column" spacing={2} width="80vw">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                  <IconChartDots size="20"className="icon"/>
                </Box>
                <Typography variant="h3">Top Creators</Typography>
              </Stack>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                autoPlaySpeed={2000}
                centerMode={false}
                className=""
                containerClass="landscape-carousel-container"
                draggable={false}
                focusOnSelect={false}
                infinite
                itemClass="landscape-carousel-item"
                keyBoardControl
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass="landscape-carousel-slider"
                slidesToSlide={1}
                swipeable
            >
              {TOP_CREATORS.map((data) => {
                return (
                  <TopCreatorCard data={data} />
                )
              })}
            </Carousel>
            </Stack> */}
          {/* <SectionHeader title="Trending Creators on Gigl this Month" icon="users" /> */}
        </Stack>
  </Box>
  )


  //   return <>
  //   <Dialog fullWidth maxWidth="lg" open={externalDialogOpen} onClose={() => {setExternalDialogOpen(false); setExternalCreator(null)}}>
  //   {externalLoading && <DialogTitle>Fetching user data!</DialogTitle> }
  //  {!externalLoading && externalCreator === null && <DialogContent>
  //     <DialogTitle>Not Found</DialogTitle>
  // The user {nameSearch} could not be found!
  //   </DialogContent>}
  //   {externalCreator !== null && <DialogContent>
  //     <DialogTitle>{nameSearch} <Avatar
  //           fetchPriority="high"
  //           src={launch}
  //           style={{ width: 80, height: 80, marginRight: 5, float: "right" }}
  //         /></DialogTitle>
  //         <Box sx={{width: "95%", margin: "0 auto"}}>
  // <DialogContentText>{externalCreator.full_name}</DialogContentText>
  // <DialogContentText>{externalCreator.biography}</DialogContentText>
  // <DialogContentText>{formatStat(externalCreator.follower_count)} Followers</DialogContentText>
  // <DialogContentText>{formatStat(externalCreator.media_count)} Posts</DialogContentText>
  // <Grid container>

  // {externalCreator?.medias?.slice(0, 3).map(media => (<Grid item xs={4}> <InstagramEmbed width="100%" key={media.code} url={`https://www.instagram.com/p/${media.code}/`} /></Grid>))}
  // </Grid>
  // </Box>

  //   </DialogContent>}
  // </Dialog>
  //     <Grid container>
  //       <div style={{ width: "90vw" }}>
  //         <Grid container>
  //           <Grid item xs={2} style={{ textAlign: "left", height: rightGridHeight }}>
  //             <LeftSideBar />
  //           </Grid>
  //           <Grid item xs={10} style={{ textAlign: "left" }} ref={rightGridRef}>
  //             <Typography
  //               style={{ fontSize: 30, fontWeight: 600 }}
  //             >
  //               Competitive Landscape: Check Out Trending Brands
  //             </Typography>
  //             <p
  //               style={{
  //                 fontSize: 20,
  //                 marginTop: 10,
  //               }}
  //             >
  //               With Gigl's Competitive Analysis, you can see how other brands are performing across social channels. Check out some of the top trending brands across industries.
  //             </p>
  //             <img
  //               alt="brand_clip"
  //               fetchpriority="high"
  //               src={brand_clip}
  //               style={{
  //                 maxWidth: "100%",
  //               }}
  //             ></img>
  //             <Typography
  //               variant="h5"
  //               component="div"
  //               style={{ textAlign: "left", marginBottom: 20, marginTop: 40 }}
  //             >
  //               Filter Below
  //             </Typography>
  //             <TextField
  //               label="Name"
  //               sx={{ height: 25, marginRight: 1, minWidth: "100%", marginBottom: 8 }}
  //               defaultValue={nameSearch}
  //               onChange={(e) => {
  //                 setNameSearch(e.target.value);
  //               }}
  //               InputProps={{
  //                 style: {
  //                   borderRadius: "50px",
  //                 },
  //               }}
  //             />
  //             <div style={{ height: 500, marginBottom: 50 }}>
  //               <DataGrid
  //                 rows={rows}
  //                 columns={columns}
  //                 initialState={{
  //                   pagination: {
  //                     paginationModel: { page: 0, pageSize: 20 },
  //                   },
  //                 }}
  //                 pageSizeOptions={[20, 50, 100]}
  //               />
  //             </div>

  //             <Typography
  //               style={{ fontSize: 25, fontWeight: 600 }}
  //             >
  //               Brand Insights
  //             </Typography>
  //             <p
  //               style={{
  //                 fontSize: 15,
  //                 marginTop: 10,
  //               }}
  //             >
  //               Keep track of the latest trends on social.
  //             </p>
  //             <img
  //               alt="brand_clip"
  //               fetchpriority="high"
  //               src={brand_insights}
  //               style={{
  //                 maxWidth: "100%",
  //               }}
  //             ></img>

  //             <Typography
  //               style={{ fontSize: 20, fontWeight: 600 }}
  //             >
  //               Relevant and Top Trending Hashtags this Month
  //             </Typography>
  //             <p
  //               style={{
  //                 fontSize: 15,
  //                 marginTop: 10,
  //               }}
  //             >
  //               Use some of these trending hashtag suggestions in your next post.
  //             </p>
  //             <img
  //               alt="brand_clip"
  //               fetchpriority="high"
  //               src={TrendingHashtags}
  //               style={{
  //                 maxWidth: "100%",
  //               }}
  //             ></img>


  //             <Typography
  //               style={{ fontSize: 20, fontWeight: 600 }}
  //             >
  //               What's Trending on Social?
  //             </Typography>
  //             <p
  //               style={{
  //                 fontSize: 15,
  //                 marginTop: 10,
  //               }}
  //             >
  //               Check out some of the trending topics and content this month
  //             </p>



  //             <Grid container>
  //                             <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 5, }}>
  //                                 <div style={{marginBottom: 8}}>
  //                                     <Typography
  //                                         variant="h8"
  //                                         style={{ fontWeight: "bold" }}
  //                                     >
  //                                         1. 2024 Paris Olympics
  //                                         <br />
  //                                     </Typography>

  //                                     <Typography
  //                                         variant="body"
  //                                         gutterBottom
  //                                     >
  //                                   The Olympics is a top trending topic this month. Content featuring Olympics reviews, athletics, sports, and athlete/workout routines is also a trending topic. Sub-topics that are trending around the Olympics are: The Comeback of Simone Biles and Celine Dion, The Opening Ceremony, Paris as a travel destination is also trending.
  //                                   </Typography>

  //                                 </div>
  //                                 <div style={{marginBottom: 8}}>

  //                                 <Typography
  //                                     variant="h7"
  //                                     style={{ fontWeight: "bold", marginBottom: 10 }}
  //                                     gutterBottom
  //                                 >
  //                                     Check out some examples: 
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C-LgWkFvupc/?igsh=bXdtbXowanNtYWFt'> here </a> and
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C-UD_dWtEH3/?igsh=MTZoNnA3b3BmMDY1ZA=='> here </a>

  //                                 </Typography>

  //                                 </div>

  //                                 <div style={{marginBottom: 8, marginTop: 30}}>
  //                                     <Typography
  //                                         variant="h8"
  //                                         style={{ fontWeight: "bold" }}
  //                                     >
  //                                         2. Paging Dr. Beat
  //                                         <br />
  //                                     </Typography>

  //                                     <Typography
  //                                         variant="body"
  //                                         gutterBottom
  //                                     >
  //                                       Content using the Paging Dr. Beat format has been going viral, with videos using this trending format gaining higher viewership and engagement. These videos focus on inspirational content, like outfit inspiration, recipe inspiration, business growth tips, and travel inspiration. From a business lens, we have seen companies showcasing their favorite products, or comedic/educational content around their business and team.                                     
  //                                       </Typography>

  //                                 </div>

  //                                 <div style={{marginBottom: 8}}>

  //                                   <Typography
  //                                       variant="h7"
  //                                       style={{ fontWeight: "bold", marginBottom: 10 }}
  //                                       gutterBottom
  //                                   >
  //                                       Check out some examples: 
  //                                       <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9x6vn2PZ0X/?igsh=cm1yZnlzN25kaXFh'> here </a> and
  //                                       <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9tA-BgOWGr/?igsh=ODR1Yng0bWt0ejg3'> here </a>
  //                                   </Typography>

  //                                   </div>


  //                                   <div style={{marginBottom: 8, marginTop: 30}}>
  //                                     <Typography
  //                                         variant="h8"
  //                                         style={{ fontWeight: "bold" }}
  //                                     >
  //                                         3. Politics
  //                                         <br />
  //                                     </Typography>

  //                                     <Typography
  //                                         variant="body"
  //                                         gutterBottom
  //                                     >
  //                                       Politics is a trending topic of discussion across social channels. Discussion on the political landscape has exponentially grown in engagement and viewership.
  //                                     </Typography>

  //                                 </div>
  //                                 <div style={{marginBottom: 8}}>

  //                                 <Typography
  //                                     variant="h7"
  //                                     style={{ fontWeight: "bold", marginBottom: 10 }}
  //                                     gutterBottom
  //                                 >
  //                                     Check out some examples: 
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/explore/tags/politics/?hl=en'> here </a> 
  //                                 </Typography>

  //                                 </div>

  //                             </Grid>
  //                             <Grid item xs={6}  sx={{ marginTop: 1 }}> 
  //                                 <img
  //                                     alt=""
  //                                     fetchpriority="high"
  //                                     src={Olympics}
  //                                     style={{
  //                                         maxWidth: "80%",
  //                                         maxHeight: "80%",
  //                                         marginLeft: 130
  //                                     }}
  //                                 ></img>
  //                             </Grid>
  //                             </Grid>




  //                             <Grid container>
  //                             <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 8, }}>
  //                                 <div style={{marginBottom: 8}}>
  //                                     <Typography
  //                                         variant="h8"
  //                                         style={{ fontWeight: "bold" }}
  //                                     >
  //                                         4. Back to School
  //                                         <br />
  //                                     </Typography>

  //                                     <Typography
  //                                         variant="body"
  //                                         gutterBottom
  //                                     >
  // Back to school is one of the top  topics trending on social, with students and parents sharing their back-to-school essentials, outfits, meal prep, and more. Comedic posts surrounding back-to-school have been trending at this time, as well as teacher-focused content, and parent’s tips/views on back-to-school for their kids.                                    
  //                                     </Typography>

  //                                 </div>
  //                                 <div style={{marginBottom: 8}}>

  //                                 <Typography
  //                                     variant="h7"
  //                                     style={{ fontWeight: "bold", marginBottom: 5 }}
  //                                     gutterBottom
  //                                 >
  //                                     Check out some examples: 
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9qeXJFNHuE/?igsh=am9iaGZwZ20xNngz'> here </a> and
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9hw0NbJa6j/?igsh=MXg2aXlxdHZqbWVvbw=='> here </a>
  //                                 </Typography>

  //                                 </div>

  //                                 <div style={{marginBottom: 8, marginTop: 30}}>
  //                                     <Typography
  //                                         variant="h8"
  //                                         style={{ fontWeight: "bold" }}
  //                                     >
  //                                         5. Family Fun & Travel
  //                                         <br />
  //                                     </Typography>

  //                                     <Typography
  //                                         variant="body"
  //                                         gutterBottom
  //                                     >
  // Family and nostalgia centered content is trending. Video content surrounding family travel ideas, family-focused comedy, family recipe favorites, family dynamics etc. is gaining higher veiwership and engagement.                          
  //                                     </Typography>

  //                                 </div>
  //                                 <div style={{marginBottom: 8}}>

  //                                 <Typography
  //                                     variant="h7"
  //                                     style={{ fontWeight: "bold", marginBottom: 5 }}
  //                                     gutterBottom
  //                                 >
  //                                     Check out some examples: 
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9gFKglSvkk/?igsh=MXQxbHVrNTc0c3F0dQ=='> here </a> and
  //                                     <a target="_blank" rel="noopener noreferrer"  href='https://www.instagram.com/reel/C9QqImcSB9x/?igsh=azRocWJpaW1jaW5r'> here </a>     
  //                                     <br/>
  //                                     <br/>                           
  //                                     </Typography>



  //                                 </div>

  //                             </Grid>
  //                             <Grid item xs={6}  sx={{ marginTop: 1 }}> 
  //                                 <img
  //                                     alt=""
  //                                     fetchpriority="high"
  //                                     src={familyTravel}
  //                                     style={{
  //                                         maxWidth: "85%",
  //                                         maxHeight: "85%",
  //                                         marginLeft: 130
  //                                     }}
  //                                 ></img>
  //                             </Grid>
  //                             </Grid>



  //                             <Typography
  //                               style={{ fontSize: 20, fontWeight: 600 }}
  //                             >
  //                               Trending Sounds & Audio 
  //                             </Typography>
  //                             <p
  //                               style={{
  //                                 fontSize: 15,
  //                                 marginTop: 10,
  //                               }}
  //                             >
  //                               Check out some of the trending sounds and audio this month. Use these sounds to increase relevance and boost performance of your content.
  //                             </p>

  //                             <Grid container>
  //                             <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", gap: "1em", }}>
  //                                 <div >

  //                                 <ul>
  //                                       <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/921447351682109?igsh=MTVodzdvbHNucjBmeQ==">August - Taylor Swift</a></li>
  //                                       <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/475442598416077?igsh=MWRweTgyNTh1d2tlMg==">Big Dawgs - Hunumankind</a></li>
  //                                       <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/252168608699319/">Bye Bye Bye - NSYNC</a></li>
  //                                       <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1235408740804610/">Paging Dr. Beat - Gloria Estefan</a></li>
  //                                       <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1662934874536027/">Juna - Clairo</a></li>


  //                                 </ul>

  //                                 </div>
  //                             </Grid>
  //                             <Grid item xs={4}> 
  //                             <div >

  //                                   <ul>
  //                                         <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1014523328754040?igsh=dWF6OTh2cDR0dGFm"> Kickstart My Heart - Motley Creu </a></li>
  //                                         <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/268777446194642?igsh=MTY4dnE3bjB5enAzNQ==">Apple - Charli_xcx</a></li>
  //                                         <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1235451451148870?igsh=dWswbjB3OHNpMXBp">Guess - Charli_xcx</a></li>
  //                                         <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/461115589969942?igsh=MXJ3ZmQ0azAwNXB0Ng==">Soh-Soh - Iamodeal</a></li>


  //                                   </ul>

  //                               </div>
  //                             </Grid>

  //                             <Grid item xs={4}  > 
  //                             <div >

  //                                   <ul>
  //                                         <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/592467431123776?igsh=aWlxMzJ0bndlcGN5">Sparks - Coldplay</a></li>
  //                                         <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/1727097854417516?igsh=cTU4OHMzbzluazV0">You - Tash</a></li>
  //                                         <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/998641734507477?igsh=eG04Y2RpMjFybjIy">Hours - Hotel Pools</a></li>
  //                                         <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reels/audio/760459706041276?igsh=ZDloMWw2dGplbjFn">Too Sweet - The Macrons Project</a></li>

  //                                   </ul>

  //                               </div>
  //                             </Grid>
  //                             </Grid>

  //                             <img
  //                             alt=""
  //                             fetchpriority="high"
  //                             src={MoreCreators}
  //                             style={{
  //                               maxWidth: "95%",
  //                               marginTop: 50
  //                             }}
  //                           ></img>


  //                             <Grid container>
  //                             <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", gap: "1em", }}>
  //                                 <div >

  //                                 <Typography
  //                             variant="h6"
  //                             component="div"
  //                             style={{ textAlign: "left", marginTop: 30, marginBottom: 10, fontWeight: 600 }}
  //                           >
  //                             Looking for Creator Insights? 
  //                             <br />
  //                             Visit Your Discovery Page.
  //                           </Typography>

  //                                 </div>

  //                             </Grid>
  //                             <Grid item xs={6} sx={{marginTop: 1}}> 
  //                             <div >


  //                                   <Button
  //                                   basic
  //                                   style={{
  //                                     marginTop: 25,
  //                                     borderRadius: 3,
  //                                     width: "100%",
  //                                     height: 60,
  //                                     marginBottom: 30,
  //                                     marginLeft: 50,
  //                                     backgroundColor: "#4158D0",
  //                                     backgroundImage:
  //                                       "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
  //                                     fontSize: 12,
  //                                   }}
  //                                   onClick={() => navigate("/campaigns/creatordiscovery")}
  //                                 >
  //                                   Visit Creator Discovery
  //                                 </Button>

  //                               </div>
  //                             </Grid>
  //                             </Grid>




  //           </Grid>
  //         </Grid>
  //       </div>
  //     </Grid>
  //   </>
}

export default Landscape;

export const StartRow = () => <img src={require("../assets/trending/filled-start.png")} alt="star" />

export const PlatformRow = ({ platforms }) => {

  return <div className="star-row">
    {
      platforms.map((name) => <img src={require(`../assets/${name}-vector.png`)} alt="star" key={name} />)
    }
  </div>
}

const FilterDropdown = ({ btnText, btnIcon, menuList, selectedOptions, setSelectedOptions, fullWidth = false, startIcon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    setSelectedOptions(value);
    handleClose();
  };

  

  return <><MuiButton
    variant="outlined"
    className={`custom-button`}
    onClick={handleClick}
    endIcon={<FontAwesomeIcon className="btn-icon" icon={btnIcon} />}
  >
    {selectedOptions || btnText}
  </MuiButton>
  <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          borderRadius: "20px !important"
        }}
      >
        {menuList.map((platform) => (
          <MenuItem key={platform.value || platform } onClick={()=>handleSelect(platform.label || platform)}>{ platform.label || platform || btnText}</MenuItem>
        ))}
      </StyledMenu>
  </>
}