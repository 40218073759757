import { useEffect, useState, useRef } from "react";
import {getFileType} from "../util/helpers"

const FileViewer = ({url})=>{
  const [fileType, setFileType] = useState('');
  

  useEffect(()=>{
    if(url){
      const type = getFileType(url);
      setFileType(type);
    }
    
  },[url]);

  const handleFileClick = () => {
    const a = document.createElement('a');
    a.href = url;
    a.target = "blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
    return <div className="file-container">
        {fileType === "image" && <ImageViewer url={url} onClick={handleFileClick}/>}
        {fileType === "document" && <DocumentViewer url={url} onClick={handleFileClick}/>}
        {fileType === "video" && <VideoPlayer url={url} onClick={handleFileClick}/>}
        {fileType === "unknown" && <div className="unknown-file" >File not supported !</div>}
  </div>
  
}

const DocumentViewer = ({ url, onClick }) => {
  return <div style={{ position: 'relative', width: '100%', height: '100%' }}>
    <iframe
      src={url}
    ></iframe>
    <div
      onClick={() => onClick()}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        zIndex: 1,
        backgroundColor: 'transparent',
      }}
    ></div>
  </div>

}

const ImageViewer = ({url,onClick}) => {
  return <img src={url} alt="Deliverables Image file" height="100%" width="100%" onClick={()=>onClick()}/>
}

const VideoPlayer = ({url,onClick})=>{
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.autoplay = false;
      videoRef.current.pause();
    }
  }, []);
    return <video
      ref={videoRef}
      controls
      onClick={(e)=>{
        e.preventDefault();
        onClick()
        
      }}
    >
      <source
        src={`${url}`}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
}

export default FileViewer;