import { Divider } from "@mui/material";

const InformationRow = ({icon,title,subtitle,titleClass="",subtitleClass="",rowClass="",showDivider=true, children})=>{

    return <><div className="info-body-row">
        <div className={`info-section ${rowClass}`}>
        <div className="info-row-img"><img src={require(`../assets/trending/${icon}.png`)} alt="row" /></div>
        <div className="info-row-content">
            <div className={`row-info ${titleClass}` }>{title}</div>
            <div className={`row-info ${subtitleClass}`}>{subtitle}</div>
        </div>
        </div>
        {
            children 
        }
        
         
    </div>
    {showDivider && <Divider sx={{margin:"0 .5rem"}} />}
    </>
    

}

export default InformationRow;

export const RightInformation = ({ totalPost, platforms = [] }) => {
    return <>
        <div className="left-info">
            {platforms.length > 0 && 
            <div className="left-icons">
                { platforms.map((name, i) => <img key={i} src={require(`../assets/${name}-vector.png`)} alt="social" />) }
            </div>}
            {totalPost && <div className="post-content">{totalPost} posts</div>}

        </div>
    </>
}