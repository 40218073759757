export default function Privacy() {
  return (
    <>
      {" "}
      <iframe
        src={
          "https://docs.google.com/viewer?url=" +
          encodeURIComponent(
            "https://firebasestorage.googleapis.com/v0/b/gigl-bd30b.appspot.com/o/privacy%2FGigl%20Privacy%20Policy.pdf?alt=media&token=e978803c-c76e-4a57-9208-b176e0a895f4",
          ) +
          "&embedded=true"
        }
        title="file"
        width="100%"
        height="600"
      ></iframe>
    </>
  );
}
