import {
    Typography,
    Grid,
    TextField,
    Box
} from "@mui/material";

import { useEffect, useRef, useState, useMemo, useContext } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import NoRecordImg from "../assets/dashboard.png"
import { fetchGet, fetchDelete } from "../util/helpers";
import {Button} from "../components";
import { DeleteForever as Delete} from '@mui/icons-material'
import LoadingContext from "../contexts/LoadingContext";





const Favorite = () => {
    const [rightGridHeight, setRightGridHeight] = useState(0);
    const rightGridRef = useRef(null);
    const [nameSearch, setNameSearch] = useState("");
    const [myFavoriteList, setMyFavoriteList] = useState([]);
    const { setIsLoading } = useContext(LoadingContext);

    const columns = useMemo(
        () => [
          { field: "name", headerName: "Name", flex: 1 },
          {
            field: "followers",
            headerName: "Followers",
            flex: 0.8,
            valueGetter: (params) => params.value.toUpperCase()
          },
          { field: "industry", headerName: "Industry", width: 130, flex: 2 },
          {
            field: "location",
            headerName: "Location",
            flex: 1,
            valueGetter: (params) => params.value.join(", "),
          },
          {
            field: "relevance",
            headerName: "Relevance",
            flex: .5,
            valueGetter: (params) => params.value.join(", "),
            sortable: false,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Action",
            flex:1.5,
              sortable: false,
              getActions: (params) => [
                  <Button
                      style={{
                          backgroundColor: "#4158D0",
                          backgroundImage: "linear-gradient(90deg, #0062ff, #da61ff)",
                          borderRadius: 20,
                          fontSize: 14,
                      }}
                      endIcon={<Delete />}
                      onClick={() => {
                          handleFavorite(params.row)
                      }}
                  >Remove From List</Button>
              ]
          },
          
        ],
        [myFavoriteList],
      );

    const rows = useMemo(() => {
        const checkName = (row) => {
            if (nameSearch) {
              if (
                row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
                row.handle.toLowerCase().includes(nameSearch.toLowerCase())
              ) {
                return true;
              }
              return false;
            }
            return true;
          };
        return myFavoriteList.filter((rowData) => {
            return checkName(rowData);
        });
    }, [
        nameSearch,
        myFavoriteList
    ]);

    const fetchMyFavorites = async () => {
        const response = await fetchGet(`/brand/outreach/myfavorite/list`,setIsLoading);
            if (response.data) {
                setMyFavoriteList([ ...response.data]);
            }
    }

    useEffect(()=>{
        fetchMyFavorites();

    },[]);

    const handleFavorite = async (creatorData) => {
          await fetchDelete(`/brand/outreach/favorite/${creatorData.id}`);
          fetchMyFavorites();
      }


    useEffect(() => {
        if (rightGridRef.current) {
            const updateHeight = () => {
                setRightGridHeight(rightGridRef.current.clientHeight);
            };

            updateHeight();

            window.addEventListener('resize', updateHeight);

            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, [rightGridRef.current]);

    return <>
        {/* <div
            style={{
                width: "100%",
                height: "100%",
                background: "black",
                textAlign: "left",
                marginTop: 5,
                color: "white",
                paddingTop: 10,
                paddingBottom: 20,
            }}
        >
            <Typography
                variant="h2"
                component="div"
                style={{ color: "white", marginLeft: "15vw" }}
            >
                My Favorites
            </Typography>
        </div> */}
        <Grid container>
            <div style={{ width: "100vw" }}>
                <Grid container>
                    <Grid item xs={2} style={{ textAlign: "left", height: rightGridHeight }}>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "left"}} ref={rightGridRef}>
                        <Typography
                            variant="h4"
                            style={{ marginBottom: 20, textAlign: "left", fontWeight: 600 }}
                        >
                            My Favorites: Check Out Your Saved Creators
                        </Typography>
                        <Typography variant="body">
                            See your saved creators below. You can partner with these creators by outreaching to them or launching a campaign.
                        </Typography>
                        <Typography
                            variant="h5"
                            component="div"
                            style={{ textAlign: "left", marginBottom: 20, marginTop: 40 }}
                        >
                            Filter Below
                        </Typography>
                        <TextField
                            label="Name"
                            fullWidth
                            sx={{ height: 25, marginRight: 1, marginBottom: 8 }}
                            defaultValue={nameSearch}
                            onChange={(e) => {
                                setNameSearch(e.target.value);
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: "50px",
                                },
                            }}
                        />
                        <div style={{ height:"55vh", width: "100%" }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                },
                            }}
                            pageSizeOptions={[20, 50, 100]}
                            slots={{
                                noRowsOverlay:CustomNoRowsOverlay
                            }}
                            slotProps={{
                                noRowsOverlay:{
                                    message:"No Favorites Found"
                                }
                            }}
                        />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    </>
}

const CustomNoRowsOverlay = ({message="No Data Found"}) => {
    return (
      <GridOverlay>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color:"#d57eeb"
          }}
        >
            <img src={NoRecordImg} height="50%" width="50%" alt="No Data Image" />
          <Typography variant="h6" component="div">
            {message}
          </Typography>
        </Box>
      </GridOverlay>
    );
  };

export default Favorite;