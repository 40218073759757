import { useParams } from "react-router-dom";
import { fetchGet } from "../util/helpers";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, YouTubeEmbed } from "react-social-media-embed";
function LiveContent() {
    const { campaignId } = useParams();
    const [postLinkList, setPostLinkList] = useState([]);
    const getAllDeliverables = async () => {
        const results = await fetchGet(
          `/brand/deliverables?campaignId=${campaignId}`,
        );
        if (results.result) {
          const postLinks = [];
          console.log("results", results);
          results.result.forEach((item) => {
            if (item.platform !== "draft" && item.platform !== "concept") {
              postLinks.push({...item})
            }
          });
          setPostLinkList([...postLinks]);
    
        }
      };
      useEffect(() => {
        getAllDeliverables();
      }, [campaignId]);
    return <Box sx={{ width: '90%', height: '90vh',  marginLeft: '5%' }}>
    <ImageList variant="masonry" cols={3} gap={8}>
      {postLinkList.filter(item => item.link).map((item) => {
        console.log("item", item);
        const link = item.link;
        if (link.includes("instagram")){
            return (
                <ImageListItem key={link}>
                 <InstagramEmbed url={link} width="100%" captioned />
                </ImageListItem>
            )
        } else if (link.includes("facebook")){
            return (
                <ImageListItem key={link}>
                 <FacebookEmbed url={link} width="100%" captioned />
            </ImageListItem>
            )
        } else if (link.includes("youtube")){
            return (
                <ImageListItem key={link}>
                 <YouTubeEmbed url={link} width="100%" captioned />
                </ImageListItem>
            )
        } else if (link.includes("tiktok")){
            return (
                <ImageListItem key={link}>
                 <TikTokEmbed url={link} width={325}  captioned />
                </ImageListItem>
            )
        }
        return  <ImageListItem key={link}>
       {link}
       </ImageListItem>;
    })}
    </ImageList>
  </Box>
}

export default LiveContent;
