import React, { useState } from 'react';
import MeyerPerson from "../assets/MeyerPerson.png"
import HauslanePerson from "../assets/HauslanePerson.png"
import MeyerLogo from "../assets/brand-slider/Meyer.png"
import HauslaneLogo from "../assets/brand-slider/Hauslane.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import { useRef } from 'react';




const brandPersons = [
  {
    src: MeyerPerson,
    post: 'Marketing Director',
    logo: MeyerLogo,
    company: 'Meyer',
    detail: '" We had a great experience using the Gigl platform to launch our influencer marketing efforts, from partnering with the right creators in our industry to tracking analytics. Our team surpassed our viewership and brand reach goals by partnering with top creators on Gigl. "'
  },
  {
    src: HauslanePerson,
    post: 'Head of Content Marketing',
    logo: HauslaneLogo,
    company: 'Hauslane',
    detail: '" The Gigl platform has significantly optimized our influencer marketing strategies at Hauslane. It has been instrumental in fostering robust brand-creator relationships and noteworthy growth in our social media audience. We highly recommend leveraging the Gigl platform for streamlining influencer marketing endeavors. "'
  }
];

const BrandCarousel = () => {
  const isSmallMobile = useMediaQuery("(max-width:453px)");
  const carouselRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const slidePrev = () => {
    const newIndex = activeIndex - 1 < 0 ? brandPersons
      .length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
    carouselRef.current.goToSlide(newIndex + 2, true)

  }

  const slideNext = () => {
    const newIndex = activeIndex + 1 == brandPersons
      .length ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
    carouselRef.current.goToSlide(newIndex + 2, true);

  }

  return (
    <div className='carousel-container'>
      <div className='carousle-body'>

        <Carousel
          ref={carouselRef}
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={1}
          centerMode={true}
          className="mycontainer"
          customTransition="all 1s linear"
          infinite
          itemClass="myitemclass"
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            }
          }}
          shouldResetAutoplay
          showDots={false}
          swipeable={false}
          transitionDuration={1000}
          draggable={false}
        >
          {
            brandPersons
              .map((data, index) => <div className="image-container" key={index}>
              <img className={`person-img ${activeIndex != index && 'passive-iamge'}`} src={data.src} />
            </div>)
          }
        </Carousel>

      </div>
      <div className='carousle-info'>
        <div className='brand-title'>
          <img src={brandPersons[activeIndex].logo} alt='Huawei Icon' className='brand-icon' />
        {/* <div className='brand-name'>{brandPersons[activeIndex].company}</div> */}
        </div>
        <div className='brand-detail'>{brandPersons
        [activeIndex].detail}</div>
        <div className='brand-person'>
          <div className='person-name'>{brandPersons
        [activeIndex].post}</div>
        {/* <div className='person-post'>{brandPersons
        [activeIndex].post}</div> */}
        </div>
        <div className='navigators'>
          <div className='icon-button' onClick={slidePrev}><FontAwesomeIcon icon={faAngleLeft} /></div>
          <div className='icon-button' onClick={slideNext}><FontAwesomeIcon icon={faAngleRight} /></div>

        </div>
      </div>


    </div>
  );
};

export default BrandCarousel;
