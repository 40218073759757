import StepWizard from "react-step-wizard";
import BasicInfo from "./BasicInfo";
import { useState } from "react";
import Nav from "./Nav";
import Socials from "./Socials";
import Plan from "./Plan";
import Payment from "./Payment";
import AcceptTerms from "./AcceptTerms";
import "./Setup.css";
export default function Setup() {
  const [form, setForm] = useState({});
  return (
    <div style={{ paddingTop: 5, maxWidth: "100vw" }}>
      <StepWizard isLazyMount className="wizard">
        <AcceptTerms />
        <BasicInfo setForm={setForm} form={form} />
        <Socials setForm={setForm} form={form} />
        <Plan setForm={setForm} form={form} />
        <Payment setForm={setForm} form={form} />
      </StepWizard>
    </div>
  );
}
