import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchGet } from "../util/helpers";

export function ProductRedirect(props) {
  const { productId } = useParams();
  const [product, setProduct] = useState({});

  useEffect(() => {
    const redirect = async () => {
      if (productId) {
        const response = await fetchGet(`/product/${productId}/redirect`);
        setProduct(response.result);
        setTimeout(() => {
          let url = response.result.productURL
          if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }
          window.location.assign(url);
        }, 3000);
      }
    };
    redirect();
  }, [productId]);

  return (
    <>
      You are being redirected to{" "}
      <a href={product.productURL}>{product.productURL}</a>{" "}
    </>
  );
}
