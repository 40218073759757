import React from "react";
import {
  LineChart as ReLineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  AreaChart,
  Area,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";

const COLORS = ["#f43e57", "#d985e3", "gray", "#138bee", "#f8a244"];

const CustomTooltip = (props) => {
  // payload[0] doesn't exist when tooltip isn't visible
  if (props.payload !== null && props.payload[0] != null) {
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    console.log("payload", props.payload);
    let total = 0;
    for (let i = 0; i < props.payload.length; i++) {
      total += props.payload[i].value;
    }
    const newPayload = [
      {
        name: "Total",
        // all your data which created the tooltip is located in the .payload property
        value: total,
        // you can also add "unit" here if you need it
      },
      ...props.payload,
    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};

export default function LineChart({ data, width, height, keyToLabel }) {
  if (data === undefined || !(data.length > 0)) {
    return;
  }
  const values = Object.keys(data[0]).filter((key) => key !== "date");

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <XAxis dataKey="date" hide />
        <YAxis hide />
        <Tooltip content={<CustomTooltip />} />
        {values.map((key, index) => {
          return (
            <Area
              name={keyToLabel[key]}
              type="monotone"
              dataKey={key}
              stackId="1"
              stroke={COLORS[index]}
              fill={COLORS[index]}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}
