import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import blogpic4 from "../assets/blogpic4.PNG";
import blogpic2 from "../assets/blogpic2.PNG";

import * as React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function BlogArticle4(props) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "center" : "left",
          height: 200,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 16 : 40,
            width: isMobile ? "80%" : "85%",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          How Influencer Marketing Works: Answering Your Questions
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 25,
            width: isMobile ? "80%" : "70%",
          }}
        >
          What is influencer marketing? Influencer marketing is the process of
          partnering with a content creator/influencer to market your brand.
          <br></br>
          <br></br>
          By: Disha | Gigl Expert
          <br></br>
          Date: 01/08/2024
        </p>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <Grid
            elevation={3}
            sx={{
              height: isMobile ? 340 : 420,
              float: isMobile ? "" : "right",
              borderRadius: 10,
              marginBottom: 2,
              width: "35vw",
              marginRight: 10,
            }}
          >
            <img
              src={blogpic4}
              height="100%"
              style={{
                borderRadius: 20,
                maxHeight: isMobile ? 400 : 500,
                maxWidth: isMobile ? "40vw" : "",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
          marginBottom: 100,
          marginTop: 20,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "left" : "center",
          maxWidth: isMobile ? "100vw" : "80vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 1800 : 1500,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 220 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Influencer marketing is the process of partnering with a content
          creator/influencer to market your brand. It's a powerful way to drive
          organic reach and engagement.
          <br></br>
          <br></br>
          There are different types of influencer marketing partnerships:
          <br></br>
          <br></br>
          1. Paid partnership
          <br></br>
          2. Merchandise Partnership
          <br></br>
          3. Affiliate marketing partnership
          <br></br>
          4.Creator events
          <br></br>
          5. User Generated Content partnership
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 5 : 5,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          How does influencer marketing help brands, and is it effective?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 250 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Influencer marketing is one of the most effective ways to market your
          brand, and build relevance, trust, and community. Many influencers
          have the power to sell out a product in minutes. According to recent
          data, 68% of brands plan to increase their influencer marketing
          budgets. On average, influencer marketing drives 11X more ROI at ⅓
          cost compared to traditional ads marketing. <br></br>
          <br></br>
          As a result, influencer marketing is one of the most cost-effective
          marketing strategies for 2024. As economic uncertainty grows and
          budgets are constrained, influencer marketing is one of the best
          strategies to boost reach, engagement, and sales for a small to large
          brand.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          How do you partner with creators?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 180 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Finding the right influencer for your brand can be tough, but you can
          do it within minutes on Gigl. Find, partner, and collaborate with
          hundreds of creators for your next influencer marketing campaign on
          Gigl. Discover the right creators for your brand and outreach in one
          click. From paid ad campaigns, affiliate partnerships, to creator
          events, Gigl can support your team. Track performance every step of
          the way in your custom dashboard
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          How do you launch an influencer marketing campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 340 : "",
          }}
        >
          Once you find the right creator for your marketing campaign. Share
          your content vision/key talking points with them, with some details on
          how you want your product to show up for the audience. It’s great to
          provide creators with the freedom to shape that vision for their
          audience because they know what resonates with their audience.
          <br></br>
          <br></br>
          If you want to track clicks to site and conversions, you should join
          Gigl. On gigl you can generate your custom site/product link and track
          all clicks of that link to your site. This allows you to measure how
          many people were driven to your site via your influencer marketing
          campaign.
          <br></br>
          <br></br>
          Once you’ve reviewed and approved the content, its time to go live! If
          you’ve partnered with the creator on Gigl, you can manage all the
          content and track performance every step of the way on your dashboard.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
            marginTop: 50,
            height: isMobile ? 80 : "",
          }}
        >
          Want to take your business to the next level through social media
          marketing?
          <br></br>
          Sign up on Gigl to launch your next influencer marketing campaign.
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: 40,
            marginLeft: isMobile ? 30 : 0,
          }}
          onClick={() => navigate("/register")}
        >
          Get Started on Gigl Today
        </Button>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 250 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
          height: isMobile ? 350 : 450,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 12 : 18,
            marginBottom: 10,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          Check Out the Next Article
        </Typography>

        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          3 Tips to launch a successful social media campaign, according to top
          creators
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          We asked @feeedmi, one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media. Her
          channel has over 1.4 Million followers and 1 Billion views...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - @feeedmi | 1.4 Million Followers | Top Gigl Creator
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle2")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic2}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 35 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 20,
        }}
      ></Grid>

      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
