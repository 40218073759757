import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import {Drawer, styled, Button as MuiButton} from "@mui/material"
import { Close } from '@mui/icons-material'
import UserContext from "../contexts//UserContext";
import { useContext } from 'react';
import { logout } from "../util/helpers";



const CustomTextButton = styled(MuiButton)(({ theme }) => ({
  
    color: "black",
    fontSize: "20px",
    fontWeight: "500",
    borderRadius:"10px",
    '&.bold-text':{
      fontWeight:"600"
    },
    width:"100%",
    backgroundColor: "white",
    borderColor: "white",
    boxShadow: "0px 1px 2px 0px #1018280D",
    padding: "8px 16px",
    textTransform: "none !important",
    '&:hover': {
      backgroundColor: '#D3D3D3',
  
  
    }
  }));


const MobileDrawer = ({isOpen,toggleDrawer})=>{
  const { authed, user } = useContext(UserContext);


    return <Drawer anchor="top" open={isOpen} onClose={()=>toggleDrawer()}
    ModalProps={{
      keepMounted: true,
      BackdropProps: {
        invisible: true, 
      },
    }}
    sx={{
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: "100%", height: "100vh" },

    }}>
    <div className='mobile-drawer' role="presentation">
      <div className="mobile-drawer-header">
        <div className="drawer-header" >GIGL</div>
        <span onClick={()=>toggleDrawer()} className="pointer-curser drawer-top-btn"><Close /></span>
      </div>
      <div className="mobile-menus">
      <div className="menu-button" onClick={() => {
              window.location.href = "/pricing";
            }}>Pricing</div>
            <div className="menu-button" onClick={() => {
              window.location.href = "/blog";
            }}>Blog & Insights</div>
            <div className="menu-button" onClick={() => {
              window.location.href = (user.type === "creator") ? "/creators" : "/dashboard";
            }}>My Dashboard</div>
            <div className="menu-button" onClick={
              authed
                ?
                () => { logout() }
                :
                () => { window.location.href = "/login" }
            }>{authed ? "Logout" : "Login"}</div>
      </div>
      <div className='mobile-drawer-btn'>
      <MuiButton onClick={()=>{
                window.location.href= "/creatorSignUp";
              }} className="custom-button text-white" style={{fontSize:"20px"}}>Become a Creator</MuiButton>
      <CustomTextButton onClick={()=>{
                window.location.href= "/register";
              }} className="bold-text half-width" endIcon={<FontAwesomeIcon className="btn-icon" icon={faAngleRight} />}>Get Started Today</CustomTextButton>

      </div>
    </div>

  </Drawer>

}

export default MobileDrawer;