import { useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";
import LoadingContext from "./LoadingContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../util/firebase";
import { logout } from "../util/helpers";
export default function UserProvider(props) {
  const [user, setUser] = useState({});
  const [authed, setAuthed] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);

  const getUser = async (uid) => {
    const q = query(
      collection(db, "users"),
      where("id", "==", uid)
    );
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
      return { ...docs.docs[0].data(), userDocId: docs.docs[0].id };
    } else {
      throw new Error("Can't find user doc");
    }
  };

  const refreshUser = async (user, force = false) => {
    if (user && user.uid) {
      if (force) {
        setUser({
          ...user,
          isSetup:  user.isSetup || (user.plan !== undefined && user.plan !== null),
        });
        return;
      }
      try {
        const userData = await getUser(user.uid);
        setUser({
          ...user,
          ...userData,
          isSetup: (userData.type === "creator" && userData.isSetup) || (userData.plan !== undefined && userData.plan !== null),
        });
      } catch (err) {
        console.log(err);
        setUser({}); // TODO: potential race condition where a user creates an account and a get user doc doesn;t exist yet. that is what `force` param is trying to fix
      }
    } else {
      setUser({});
    }
    setIsInit(true);
  };
  useEffect(() => {
    const removeListener = getAuth().onAuthStateChanged(async (user) => {
      setIsLoading(true);
      await refreshUser(user);
      setIsLoading(false);
    });

    return () => removeListener();
  }, []);

  useEffect(() => {
    if (user.uid) {
      setAuthed(true);
    } else {
      setAuthed(false);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ authed, user, refreshUser, isInit }}>
      {props.children}
    </UserContext.Provider>
  );
}
