import { memo, useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";


import CreatorStepper from "./CreatorStepper";
import { Button } from "../components";
import TikTokImg from "../assets/tiktok.png"
import YutubeImg from "../assets/youtube.png"
import FaceBookImg from "../assets/facebook.png"
import { fetchGet } from "../util/helpers";
import UserContext from "../contexts/UserContext";
function CreatorSocials(props) {
  const navigate = useNavigate();

  const [tiktokDisabled, setTiktokDisabled] = useState(false);
  const [youtubeDisabled, setYoutubeDisabled] = useState(false);
  const [facebookDisabled, setFacebookDisabled] = useState(false);
  const [tokens, setTokens] = useState({});
  const [hasClicked, setHasClicked] = useState(false);
  const { user, refreshUser } = useContext(UserContext);


  const handleTiktokLogin = () => {
    setHasClicked(true);
    fetchGet(`/oauth/tiktok`).then((res) => {
      console.log(res);
      window.open(res.url, "_blank")
    });
  };

  const handleYoutubeLogin = () => {
    setHasClicked(true);
    fetchGet(`/oauth/youtube`).then((res) => {
      console.log(res);
      window.open(res.url, "_blank")
    });
  };

  const handleFacebookLogin = () => {
    setHasClicked(true);
    fetchGet(`/oauth/facebook`).then((res) => {
      console.log(res.url);
      window.open(res.url, "_blank")
    });
  };

  useEffect(() => {
      if (hasClicked) {
        const unsubscribe = setInterval(async () => {
          let result = await fetchGet(`/creator/access_tokens/list`);
          result = result.result;
          setTokens({ ...result });
        }, 5000);
  
        return () => clearInterval(unsubscribe);
      } else {
        fetchGet(`/creator/access_tokens/list`).then((result) => {
          setTokens({ ...result.result });

        })
      }
    
  }, [hasClicked]);

  useEffect(() => {
    const hasTiktok = tokens.tiktok && tokens.tiktok.count > 0;
    setTiktokDisabled(hasTiktok);
    const hasYoutube = tokens.youtube && tokens.youtube.count > 0;
    setYoutubeDisabled(hasYoutube);
    const hasFacebook = tokens.facebook && tokens.facebook.count > 0;
    setFacebookDisabled(hasFacebook);
  }, [tokens]);

  const submit = async () => {
    console.log("Submit", tiktokDisabled || youtubeDisabled || facebookDisabled);
    if (tiktokDisabled || youtubeDisabled || facebookDisabled) {
      console.log("calling setup");
      try {
        await fetchGet("/creator/users/setup");
        await refreshUser(user);
        navigate("/creators")
      } catch (err) {
        console.log(err);
        alert("An error occurred, please try again");
      }
    }
  };
  return <>
    <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

    <div style={{ margin: "0 auto", alignItems: "center", display: "flex", flexDirection: "column", gap: 25 }}>
      <Typography variant="h5" style={{ textAlign: "center" }}>
        Verify Your Identity
      </Typography>
      <Typography variant="h6" style={{ textAlign: "center", color: "gray" }}>
        You will only be able to view campaigns for platforms you are verified in.
      </Typography>
      <Button
        style={{
          textAlign: "center",
          display: "flex",
          width:"25rem"
        }}
        disabled={tiktokDisabled}
        name="tiktok"
        onClick={handleTiktokLogin}
        startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={TikTokImg} height="100%" width="100%" /></div>}
      >Login with TikTok</Button>
      <Button
        style={{
          textAlign: "center",
          display: "flex",
          width:"25rem"
        }}
        name="youtube"
        disabled={youtubeDisabled}
        onClick={handleYoutubeLogin}

        startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={YutubeImg} height="100%" width="100%" /></div>}
      >Login with Youtube</Button>
      <Button
        style={{
          textAlign: "center",
          display: "flex",
          width:"25rem"
        }}
        name="facebook"
        disabled={facebookDisabled}
        onClick={handleFacebookLogin}

        startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={FaceBookImg} height="100%" width="100%" /></div>}
      >Login with Facebook</Button>
      <Typography variant="h6" style={{ textAlign: "center", color: "gray", width: "50%" }}>
        Facebook verification includes Instagram as well. You will need a Facebook Business Account connected to your Instagram to be verified.
      </Typography>
      <CreatorStepper {...props} canContinue={(tiktokDisabled || youtubeDisabled || facebookDisabled)} nextStep={submit} />
    </div>
  </>
}

export default memo(CreatorSocials);
