import { Box, Button, Chip, Divider, IconButton, InputAdornment, Stack, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import { IconCheck, IconChevronDown, IconChevronUp, IconClock, IconEdit, IconFile, IconMail, IconMessage, IconMoodSmile, IconMoodSmileFilled, IconPlus, IconSearch, IconSend, IconUpload, IconUserPlus, IconUsers } from "@tabler/icons-react";
import { getRandomNumber, idGenerator, handleUpload, getDocumentType } from "../util/helpers";
import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPost, transformFirebaseTime } from "../util/helpers";
import UserContext from "../contexts/UserContext";
import NewApplicantTable from "./NewApplicantTable";

export default function ApplicantTab({matches}) {
    const theme = useTheme();
    const {campaignId} = useParams();
    const [nameSearch, setNameSearch] = useState("");
   
    return (
            <Stack direction="column" spacing={2} sx={{padding: "3%"}}>
                        <Typography variant="h3" color={"var(--main-text-color)"}>Applicants</Typography>
                        <Stack direction="column" spacing={2} className="box">
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  {/** TODO: Add filter */}
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <TextField 
                                        placeholder="Search Creators" 
                                        size="small"   
                                        variant="outlined"  
                                        color="info"
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconSearch className="icon-colored" size="18" color={theme.palette.info.main}/>
                                                </InputAdornment>
                                                ),
                                            },
                                            }}
                                            onChange={(e) => setNameSearch(e.target.value)}
                                        />
                                   
                                </Stack>
                            </Stack>
                            <NewApplicantTable campaignId={campaignId} nameSearch={nameSearch} />
            </Stack>
        </Stack>
    )
}