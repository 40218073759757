import { memo } from "react";
import FormInput from "./FormInput";
import Stepper from "./Stepper";
function BasicInfo(props) {
  const { setForm, form } = props;
  console.log("props", props);
  return (
    <div style={{ margin: "0 auto", width: "100%", textAlign: "center" }}>
      <FormInput label="Business Name" name="business_name" setForm={setForm} />
      <br />
      <FormInput
        label="Business Address"
        name="business_address"
        setForm={setForm}
        multiline
      />
      <br />
      <FormInput
        label="Business Phone Number"
        name="business_phone"
        setForm={setForm}
      />
      <br />
      <FormInput
        label="Business Registration Number"
        name="business_registration"
        setForm={setForm}
      />
      <Stepper
        {...props}
        canContinue={
          form.business_name &&
          form.business_address &&
          form.business_registration
        }
      />
    </div>
  );
}

export default memo(BasicInfo);
