import FormInput from "./FormInput";
import Stepper from "./Stepper";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import Typography from "@mui/material/Typography";
import { capitalizeFirstLetter, fetchPost } from "../util/helpers";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import { db } from "../util/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../contexts/LoadingContext";
import Button from "../components/Button";
import { useMediaQuery } from "@mui/material";

// const TEST_PLAN_ID = "P-3EB03071AG1664133MT7XZ5I"
const PLAN_TO_ID = {
  basic: "P-0XW56879CL506673HMUWE3FY",
  advantage: "P-91L76815L38696314MT7XXCA",
};
export default function Payment(props) {
  const { setForm, form } = props;
  const { setIsLoading } = useContext(LoadingContext);
  const { user, refreshUser } = useContext(UserContext);
  const navigate = useNavigate();
  const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];
  const isMobile = useMediaQuery("(max-width:800px)");

  const initialOptions = {
    "client-id":
      "AQKcgP1_hKVMBuXLTvQ2Qm4CxhZhv4ednLc9JxHDXfbkgJ2meE3vjf1pSip4rUlky-17krcqy2FbTJkV",
    "enable-funding": "paylater,venmo",
    vault: true,
  };
  const updateUser = async () => {
    await fetchPost(
      `/brand/${user.userDocId}/payment`,
      { ...form, plan: form.plan },
      setIsLoading,
    );
    await refreshUser(user);
    setTimeout(() => {
      navigate("/campaigns");
    }, 2000);
  };

  return (
    <>
      <Typography
        variant="h3"
        style={{ marginBottom: 10, textAlign: "center" }}
      >
        Verify Your Details
      </Typography>

      <div
        style={{
          margin: "0 auto",
          width: isMobile ? "100%" : 550,
          maxWidth: "100vw",
        }}
      >
        {Object.keys(form)
          .filter((key) => form[key] !== "" && form[key] !== undefined)
          .map((key) => {
            return (
              <div key={key} style={{ marginBottom: 10 }}>
                <Typography variant="h5">
                  {key
                    .split("_")
                    .map((word) => capitalizeFirstLetter(word))
                    .join(" ")}
                </Typography>
                <Typography>{form[key]}</Typography>
              </div>
            );
          })}
        {/* <Button onClick={updateUser} style={{width: '100%'}}>Submit</Button> */}
        <PayPalScriptProvider
          options={initialOptions}
          key={JSON.stringify(form.plan)}
        >
          {FUNDING_SOURCES.map((fundingSource) => {
            return (
              <PayPalButtons
                fundingSource={fundingSource}
                key={fundingSource}
                style={{
                  layout: "vertical",
                  shape: "pill",
                }}
                createSubscription={async (data, actions) => {
                  console.log("plan", form.plan, PLAN_TO_ID[form.plan]);
                  return actions.subscription.create({
                    plan_id: PLAN_TO_ID[form.plan],
                  });
                }}
                onApprove={async (data, actions) => {
                  console.log("data", data, actions);
                  alert("You have successfully subscribed"); // Optional message given to subscriber
                  updateUser(data);
                }}
              />
            );
          })}
        </PayPalScriptProvider>
        <Stepper {...props} />
      </div>
    </>
  );
}
