import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({children}) => {
  const { pathname } = useLocation();

  useEffect(() => {

    //set time to start scroll up once all element rendered on UI, by doing this every page start exact from top while redirecting 
    const timer = setTimeout(() => {
      window.scrollTo({
        top:0,
        behavior:"smooth"
      });
    }, 500);

    return ()=>{
      clearTimeout(timer)
    }
  }, [pathname]);

  return children;
};

export default ScrollToTop;
