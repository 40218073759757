import { memo } from "react";
import FormInput from "./FormInput";
import Stepper from "./Stepper";
function Socials(props) {
  const { setForm, form } = props;
  return (
    <div style={{ margin: "0 auto" }}>
      <FormInput
        label="Facebook"
        placeholder="https://facebook.com/"
        name="socials_facebook"
        setForm={setForm}
      />
      <br />
      <FormInput
        label="Instagram"
        placeholder="https://instagram.com/"
        name="socials_instagram"
        setForm={setForm}
      />
      <br />
      <FormInput
        label="Tiktok"
        placeholder="https://www.tiktok.com/"
        name="socials_tiktok"
        setForm={setForm}
      />
      <Stepper {...props} canContinue={true} />
    </div>
  );
}

export default memo(Socials);
