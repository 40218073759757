import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import { signInWithEmail, signInWithGoogle } from "../util/helpers";
import { IconButton, TextField, Typography } from "@mui/material";
import googleLoginLogo from "../assets/google-signin.png";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { fetchPost } from "../util/helpers";




const AutoInviteLogin = () => {
    const navigate = useNavigate();
    const { authed, refreshUser, user } = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { campaignId } = useParams();
    const [isMatched,setIsMatched] = useState(false);


    const matchUser = async () => {
        try {
            await fetchPost(`/brand/campaigns/${campaignId}/matches`, {
                creatorUserDocId: user.userDocId
            });

            toast.success("You have matched!");
            setIsMatched(true);

        } catch (e) {
            toast.error("Error while matching Campaign");
        }

    }
    useEffect(() => {
        
        if (authed) {
            matchUser()
        }
    }, [authed]);

    useEffect(()=>{
        const timer = setTimeout(() => {
            if(isMatched) navigate("/creators");
        }, 2000);

        return () => clearTimeout(timer);
        
    },[isMatched])
    return <>
        <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <Typography
                sx={{ textAlign: "center" }}
                style={{ fontWeight: 600 }}
                variant="h4"
            >
                Creator Login
            </Typography>
            <br />

            <Typography sx={{ textAlign: "left" }}>Email</Typography>
            <TextField
                type="email"
                style={{ width: 350, marginBottom: 10 }}
                onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <Typography sx={{ textAlign: "left" }}>Password</Typography>
            <TextField
                type="password"
                style={{ width: 350, marginBottom: 10 }}
                onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <Button
                appearance="default"
                marginTop={10}
                onClick={async () => {
                    try {
                        await signInWithEmail(email, password);
                        await refreshUser(user);
                    } catch (err) {
                        alert(err);
                    }
                }}
                style={{ width: 350 }}
            >
                Login with email
            </Button>
            <hr
                style={{
                    width: 350,
                    height: 1,
                    border: 0,
                    borderTop: "1px solid #ccc",
                    padding: 0,
                }}
            />

            <IconButton
                appearance="default"
                marginTop={10}
                disableRipple
                onClick={async () => {
                    try {
                        const result = await signInWithGoogle(email, password);
                        await refreshUser(result, true);
                    } catch (err) {
                        alert(err);
                    }
                }}
                style={{ width: 200, marginLeft: 75 }}
            >
                <img
                    src={googleLoginLogo}
                    style={{ objectFit: "contain" }}
                    width="200px"
                ></img>
            </IconButton>
            <Typography
                variant="body2"
                sx={{ textAlign: "center" }}
                style={{ fontWeight: 600 }}
            >
                Don't have an account?{" "}
                <span
                    style={{ cursor: "pointer", color: "#004bb9" }}
                    onClick={() => navigate(`/match/register/${campaignId}`)}
                >
                    {" "}
                    Sign up now
                </span>
            </Typography>
        </div>
    </>
}

export default AutoInviteLogin;