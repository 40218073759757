import { useEffect, useState } from "react";
import LoadingContext from "./LoadingContext";

export default function LoadingProvider(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  useEffect(() => {
    if (!isLoading) {
      setLoadingText("");
    }
  }, [isLoading]);

  return (
    <LoadingContext.Provider
      value={{ isLoading, setIsLoading, setLoadingText, loadingText }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
}
