import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const PRICING_TIERS = [
  {
    title: "Basic",
    subheader: "Standard Plan",
    price: "45.99",
    description: [
      "1 campaign per month",
      "Standard Analytics and Reporting",
      "Creator Access",
      "Help Support",
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
    id: "basic",
  },
  {
    title: "Advantage",
    subheader: "Most popular",
    price: "199.98",
    description: [
      "4 Campaigns per month",
      "Standard Analytics and Reporting",
      "Creator Access",
      "Help Support",
      "Invoicing",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
    id: "advantage",
  },
  {
    title: "Enterprise & Agency",
    description: [
      "Unlimited Campaigns",
      "Advanced Analytics and Reporting",
      "Creator Access",
      "Help Support",
      "Invoicing",
      "Collaboration Studio",
      "Account Team",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    requireContactUs: true,
    id: "enterprise",
  },
];

function PricingContent() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />

      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 5, pb: 1 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" sx={{ mb: 5 }}>
        <Grid container spacing={5} alignItems="flex-start">
          {PRICING_TIERS.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise & Agency" ? 12 : 6}
              md={4}
            >
              <Card
                sx={{
                  height: 550,
                  position: "relative",
                  boxShadow: "1px 1px 14px 1px #dadada",
                }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                />
                <CardContent>
                  {tier.price ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h4"
                        color="text.primary"
                      >
                        Contact Us
                      </Typography>
                    </Box>
                  )}
                  <ul style={{ textAlign: "left" }}>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        sx={{ textAlign: "left", marginBottom: 1 }}
                      >
                        <Grid container direction="row">
                          <Grid item xs={2}>
                            <CheckIcon
                              fontSize="small"
                              sx={{ color: "#00be00" }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            {line}
                          </Grid>
                        </Grid>
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions
                  style={{ position: "absolute", bottom: 5, width: "100%" }}
                >
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    href={
                      tier.id === "enterprise"
                        ? "mailto:support@giglgroup.com?subject=Enterprise Plan Request"
                        : "/register"
                    }
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* End footer */}
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
