import FormInput from "./FormInput";
import Stepper from "./Stepper";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { PRICING_TIERS } from "../Pricing/Pricing";
import { memo, useContext, useEffect, useState } from "react";
import { fetchPost } from "../util/helpers";
import UserContext from "../contexts/UserContext";
function Plan(props) {
  const { setForm, form } = props;
  const { user } = useContext(UserContext);
  const [plan, setPlan] = useState();

  useEffect(() => {
    if (plan) {
      setForm((prev) => ({ ...prev, plan: plan }));
    }
  }, [plan]);

  return (
    <div>
      <Typography
        variant="h3"
        style={{ marginBottom: 50, textAlign: "center" }}
      >
        Select a Plan
      </Typography>
      <Container
        maxWidth="md"
        component="main"
        sx={{ mb: 5 }}
        style={{ margin: "0 auto", marginBottom: 10, width: "100%" }}
      >
        <Grid container spacing={4} alignItems="flex-start">
          {PRICING_TIERS.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.id}
              xs={12}
              sm={tier.id === "enterprise" ? 12 : 6}
              md={6}
              onClick={() => setPlan(tier.id)}
              sx={{ cursor: "pointer" }}
            >
              <Card
                sx={{
                  height: tier.id === "enterprise" ? 450 : 400,
                  position: "relative",
                  boxShadow: `1px 1px 14px 1px #${
                    plan === tier.id ? "00be00" : "dadada"
                  }`,
                }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                />
                <CardContent>
                  {tier.price ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="a"
                        variant="h4"
                        color="text.primary"
                        style={{ textDecoration: "underline" }}
                        onClick={async () => {
                          await fetchPost(
                            `/brand/${user.userDocId}/enterprise/request`,
                            {
                              ...form,
                            },
                          );
                          window.location.href =
                            "mailto:support@giglgroup.com?subject=Enterprise PlanRequest";
                        }}
                      >
                        Contact Us
                      </Typography>
                    </Box>
                  )}
                  <ul style={{ textAlign: "left", listStyleType: "none" }}>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        sx={{ textAlign: "left", marginBottom: 1 }}
                      >
                        <Grid container direction="row">
                          <Grid item xs={2}>
                            <CheckIcon
                              fontSize="small"
                              sx={{ color: "#00be00" }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            {line}
                          </Grid>
                        </Grid>
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Stepper
        {...props}
        canContinue={form.plan && form.plan !== "enterprise"}
      />
    </div>
  );
}

export default memo(Plan);
