import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchGet, parseFollowers } from "../util/helpers";


const TopCreatorCard = ({ data }) => {

    const [creatorData, setCreatorData] = useState({ ...data });
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(()=>{
        fetchGet(`/brand/external/outreach?username=${creatorData.name}`)
        .then((res) => {
          creatorData.profileUrl = res.profile_pic_url_hd;
          creatorData.posts = res.media_count;
          creatorData.followers = res.follower_count;
          creatorData.fullName = res.full_name;
          setCreatorData(creatorData)
        }).catch((e)=>{
            setIsError(true);
        })
        .finally(() => setLoading(false))
    },[data])

    console.log({creatorData},creatorData.name);
    

    return isError ? <></>: <div className="info-card">
    <div className="creator-img">
        {loading ? <Skeleton variant="rounded" width="100%" height="20vh" animation={"wave"} /> :
            <img src={creatorData.profileUrl} alt="creator" />}

    </div>
    <div className="creator-details">
        <div className="creator-main-info">
            {loading ? <Skeleton /> :
                <div className="text-black">{creatorData.fullName}</div>}

            {loading ? <Skeleton width="50%" /> :
                <div className="info-detail text-light">{creatorData.industry}</div>}



        </div>
        <div className="creator-other">
            <div className="creator-info-section">
                <div className="info-detail text-light">Followers</div>
                {loading ? <Skeleton /> : <div className="info-detail text-black">{creatorData.followers}</div>}


            </div>
            <div className="creator-info-section">
                <div className="info-detail text-light">Posts</div>
                {loading ? <Skeleton /> : <div className="info-detail text-black">{creatorData.posts}</div>}
            </div>
        </div>

    </div>

</div>
}

export default TopCreatorCard