const InformationHeader = ({children, header, headerImg, headerSubtitle })=>{
    return <div className="info-card-header">
    <div className="info-card-title">
      <img src={require(`../assets/${headerImg}`)} alt="title-img"/>
      <div className="header-info">
        <div>{header}</div>
        <div>{headerSubtitle}</div>

      </div>
    </div>
    {children}

    
  </div>
    
}

export default InformationHeader;