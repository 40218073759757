import { Box, Chip, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconBell, IconBrandFacebook, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconMessage, IconSpeakerphone, IconUsers } from "@tabler/icons-react"
import {ReactComponent as SearchIcon} from "../assets/search-icon.svg";
import { Button } from "../components";
import { useTheme } from "@mui/material";
import { useState } from "react";

export default function CampaignTable({campaigns, tabValue}) {
    const theme = useTheme()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const platformToIcon = {
        "youtube": <IconBrandYoutube size="25" className="icon-colored" color="var(--main-text-color)"/>,
        "tiktok": <IconBrandTiktok size="25" className="icon-colored" color="var(--main-text-color)"/>,
        "facebook": <IconBrandFacebook size="25" className="icon-colored" color="var(--main-text-color)"/>,
        "instagram": <IconBrandInstagram size="25" className="icon-colored" color="var(--main-text-color)"/>
        }
    console.log(campaigns)
  return <Stack direction="column" spacing={2} marginTop={2}>
    <Stack direction="column" spacing={2}>
        <TextField  onChange={(e) => setSearch(e.target.value)} placeholder="Search Campaigns" size="small"  style={{background: "var(--Colors-Background-bg-primary, #FFF)",  borderRadius: "var(--radius-md, 8px)", border: "1px solid var(--Colors-Border-border-primary, #D0D5DD)"}}  variant="outlined"  InputProps={{
            startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
            ),
        }}/>
        {campaigns?.filter((campaign) => (campaign.active && tabValue === "active") || (!campaign.active && tabValue === "inactive")).filter((campaign) => campaign.name.toLowerCase().includes(search.toLowerCase())).map((campaign) => {
        return <Box key={campaign.id} className="box" sx={{height: "130px"}}>
        <Stack direction="row" spacing={4}>
            <Stack direction="column" spacing={1} sx={{width: "30%", height: "100%"}} justifyContent="space-between">
                <Typography variant="h4" height="57px">{campaign.name}</Typography>
                <Stack direction="row" spacing={1}>
                    <Chip label={campaign.active ? "Active" : "Inactive"} icon={<Box className={campaign.active ? "active-dot" : "inactive-dot"}/>} size="small"
                    sx={{backgroundColor: campaign.active ? theme.palette.success.light : theme.palette.error.light, color: campaign.active ? theme.palette.success.main : 'white'}}/>
                    <Chip label={campaign.influencers?.length} size="small"
                    icon={<IconUsers className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main}}/>
                    <Chip size="small"
                    icon={<IconMessage className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main, paddingLeft: "4px"}}/>
                    <Chip size="small"
                    icon={<IconBell className="icon-colored" size="12" color={theme.palette.info.main}/>} 
                    sx={{backgroundColor: theme.palette.info.light, color: theme.palette.info.main, paddingLeft: "4px"}}/>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} justifyContent="center">
                <Typography variant="bodyxs" color="var(--body-text-color-600)">Views</Typography>
                <Typography variant="bodym">-</Typography>
            </Stack>
            <Stack direction="column" spacing={1} justifyContent="center">
                <Typography variant="bodyxs" color="var(--body-text-color-600)">Engagement</Typography>
                <Typography variant="bodym">-</Typography>
            </Stack>
            <Stack direction="column" spacing={1} justifyContent="center">
                <Typography variant="bodyxs" color="var(--body-text-color-600)">Engagement Rate</Typography>
                <Typography variant="bodym">-%</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" sx={{width: "15%"}}>
                {campaign.platforms.map((platform) => platformToIcon[platform])}
            </Stack>
            <Button onClick={() => navigate(`/campaigns/${campaign.id}`)} sx={{height: "40px", alignSelf: "center", justifySelf: "end"}} variant="outlined" size="small">View Details</Button>
        </Stack>
    </Box>
        })}
    </Stack>
  </Stack>;
}
