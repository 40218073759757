import { useMediaQuery } from "@mui/material";
import Button from "../components/Button";

export default function CreatorStepper({
  currentStep,
  nextStep,
  previousStep,
  totalSteps,
  canContinue,
  continueText,
}) {
  const isMobile = useMediaQuery("(max-width:800px)");
  return (
    <div style={{ paddingBottom: 20, textAlign: "center" }}>
      <hr style={{ width: "35vw", minWidth: isMobile ? "100%" : 500 }} />
      {currentStep > 1 && (
        <Button
          onClick={previousStep}
          style={{ width: isMobile ? "100%" : 250, marginRight: 10 }}
        >
          Go Back
        </Button>
      )}
      {currentStep < totalSteps || canContinue ? (
        <Button
          onClick={nextStep}
          style={{
            width: isMobile ? "100%" : 250,
            backgroundColor: !canContinue ? "gray" : "black",
            color: "white",
            ...(isMobile && { marginTop: 20 }),
          }}
          disabled={!canContinue}
        >
          {continueText || "Continue"}
        </Button>
      ) : null}
    </div>
  );
}
