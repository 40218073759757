import { default as MuiButton } from "@mui/material/Button";

export default function Button(props) {
  const { gradient, children, style, variant, ...rest } = props;
  if (gradient) {
    return (<MuiButton style={{background: "var(--LG, linear-gradient(87deg, #4D8DFF 2.92%, #C334EB 99.35%))", borderRadius: 100, fontSize: 14, fontWeight: 700, color: "white", ...(style || {})}} {...rest}>{children}</MuiButton>)
  }
  return (
    <MuiButton variant="contained" color="primary"  sx={{height: 30, fontSize: 12, borderRadius: 5}} style={{fontSize: 12, borderRadius: 5, background: "transparent", color: 'black', boxShadow: 'none', border: '1px solid #D0D5DD'}} {...rest}>
    {children}
</MuiButton>
  );
}
