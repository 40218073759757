import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../util/helpers";
const SUCCESS_MSG =
  "Successfully connected your TikTok account! You may close the browser";
const ERROR_MSG = "An error occurred!";
const IN_PROGRESS_MSG = "Connecting your account...";
export default function TiktokOauth(props) {
  const [message, setMessage] = useState(IN_PROGRESS_MSG);
  const [searchParams, setSearchParams] = useSearchParams();
  const alreadyTriggered = useRef(false);

  const addTikTok = async () => {
    if (alreadyTriggered.current) {
      return;
    }
    let message;
    try {
      alreadyTriggered.current = true;
      message = await fetchPost("/oauth/access_token/tiktok", {
        code: searchParams.get("code"),
        scopes: decodeURIComponent(searchParams.get("scopes")),
        userDocId: searchParams.get("state"),
      });
      setMessage(SUCCESS_MSG);
    } catch (err) {
      const additionalMessage = message.message ? message.message : "";
      setMessage(`${ERROR_MSG} ${additionalMessage}`);
      return;
    }
  };
  useEffect(() => {
    const requiredParams =
      searchParams.has("scopes") &&
      searchParams.has("state") &&
      searchParams.has("code");
    if (searchParams.has("error") || !requiredParams) {
      setMessage(
        `${ERROR_MSG} ${
          searchParams.has("error") ? searchParams.get("error_description") : ""
        }`,
      );
    } else if (requiredParams) {
      addTikTok();
    }
  }, [searchParams]);

  return (
    <div style={{ textAlign: "center", verticalAlign: "middle" }}>
      {message}
    </div>
  );
}
