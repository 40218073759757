import { useState } from "react";
import { default as MUIFileUpload } from "react-mui-fileuploader";

export default function FileUpload(props) {
  const handleFileUploadError = (error) => {
    // Do something...
  };

  return (
    <MUIFileUpload
      getBase64={false}
      multiFile={false}
      disabled={false}
      title=""
      header="Drag and drop your files"
      leftLabel="or"
      rightLabel="to select files"
      buttonLabel="click here"
      buttonRemoveLabel="Remove all"
      maxFileSize={10}
      maxUploadFiles={0}
      maxFilesContainerHeight={357}
      errorSizeMessage={"File size too large"}
      onFilesChange={props.handleFilesChange}
      onError={handleFileUploadError}
      BannerProps={{
        elevation: 0,
        variant: "outlined",
        sx: {
          height: 150,
          background:
            "linear-gradient(130deg, white 0%, #fccb90 25%, #d57eeb 75%, #7b79be 100%)",
          color: "white",
        },
      }}
      showPlaceholderImage={false}
      PlaceholderGridProps={{ md: 4, sx: { color: "white" } }}
      LabelsGridProps={{ md: 8, sx: { color: "white", marginTop: 5 } }}
      onContextReady={(context) => {
        // access to component context here
      }}
      ContainerProps={{
        elevation: 0,
        variant: "outlined",
        sx: { p: 1, color: "white" },
      }}
      {...props}
    />
  );
}
