import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import { signInWithEmail, signInWithGoogle } from "../util/helpers";
import { IconButton, TextField, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import googleLoginLogo from "../assets/google-signin.png";
function CreatorLogin(props) {
  const navigate = useNavigate();
  const { authed, refreshUser, user } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (authed) {
      navigate("/creators");
    }
  }, [authed, user, navigate]);
  return (
    <div
      style={{
        position: "absolute",

        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography
        sx={{ textAlign: "center" }}
        style={{ fontWeight: 600 }}
        variant="h4"
      >
        Creator Login
      </Typography>
      <br />

      <Typography sx={{ textAlign: "left" }}>Email</Typography>
      <TextField
        type="email"
        style={{ width: 350, marginBottom: 10 }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <Typography sx={{ textAlign: "left" }}>Password</Typography>
      <TextField
        type="password"
        style={{ width: 350, marginBottom: 10 }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <Button
        appearance="default"
        marginTop={10}
        onClick={async () => {
          try {
            await signInWithEmail(email, password);
            await refreshUser(user);
            navigate("/creators");
          } catch (err) {
            alert(err);
          }
        }}
        style={{ width: 350, margin: "0 auto" }}
      >
        Login with email
      </Button>
      <hr
        style={{
          width: 350,
          height: 1,
          border: 0,
          borderTop: "1px solid #ccc",
          padding: 0,
        }}
      />

      <IconButton
        appearance="default"
        marginTop={10}
        disableRipple
        onClick={async () => {
          try {
            const result = await signInWithGoogle(email, password);
            await refreshUser(result, true);
            navigate("/creators");
          } catch (err) {
            alert(err);
          }
        }}
        style={{ width: 200, marginLeft: 75 }}
      >
        <img
          src={googleLoginLogo}
          style={{ objectFit: "contain" }}
          width="200px"
        ></img>
      </IconButton>
      <Typography
        variant="body2"
        sx={{ textAlign: "center" }}
        style={{ fontWeight: 600 }}
      >
        Don't have an account?{" "}
        <span
          style={{ cursor: "pointer", color: "#004bb9" }}
          onClick={() => navigate("/creatorSignUp")}
        >
          {" "}
          Sign up now
        </span>
      </Typography>
    </div>
  );
}

export default CreatorLogin;
