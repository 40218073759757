import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { capitalizeFirstLetter } from '../util/helpers';
function LineChart({data, dataKey}) {
    const theme = useTheme();
    const [state] = useState({
        series: [{
        name: capitalizeFirstLetter(dataKey),
        data: data[0].map(item => item[dataKey])
        }],
        options: {
        colors: [theme.palette.secondary.main],
        chart: {
            type: 'area',
            height: 350,
            zoom: {
            enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        labels: data[1],
        xaxis: {
            type: 'datetime',
        },
        },
    });

  return (
    <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
  );
}

export default LineChart;
