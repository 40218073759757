import { useEffect, useContext, useState } from "react";
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  isWidgetOpened,
  addUserMessage,
  renderCustomComponent,
  deleteMessages,
  toggleMsgLoader,
  dropMessages,
} from "react-chat-widget";

import "react-chat-widget/lib/styles.css";
import "./Chat.css";
import UserContext from "../contexts/UserContext";
import { fetchGet, fetchPost, transformFirebaseTime } from "../util/helpers";
function Chat(props) {
  const { style, creatorId, campaignId } = props;
  const { user } = useContext(UserContext);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isWidgetOpened()) {
      console.log("closed");
      toggleWidget();
      setIsOpen(true);
    }
    console.log("isWidgetOpened", isWidgetOpened());
  }, []);

  const handleToggle = (chatWindowOpen) => {
    setChatWindowOpen(!chatWindowOpen);
  };

  const sendMessage = async (message) => {
    console.log("sendMessage", message);
    if (message.trim().length === 0 || !creatorId || !campaignId) {
      return;
    }

    deleteMessages(1);

    await fetchPost("/chat/campaigns", {
      message: message,
      sender: user.userDocId,
      campaignId: campaignId,
      recipient: creatorId,
    });
    await handleFetchMessages();
  };
  const handleNewUserMessage = async (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    await sendMessage(newMessage);
  };

  const handleFetchMessages = async () => {
    const messages = await fetchGet(
      `/chat/campaigns/${campaignId}?creatorUserDocId=${creatorId}`,
    );
    setMessages(messages.result);
  };

  useEffect(() => {
    console.log("get data", creatorId, campaignId);
    if (creatorId && campaignId) {
      dropMessages();
      handleFetchMessages();
      const interval = setInterval(async () => {
        handleFetchMessages();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [creatorId, campaignId]);

  useEffect(() => {
    if (isOpen) {
      messages.forEach((message) => {
        if (
          message.sender !== user.userDocId &&
          message.sender !== user.enterpriseId
        ) {
          deleteMessages(
            1,
            transformFirebaseTime(message.submittedAt).getTime(),
          );
          addResponseMessage(
            message.message,
            transformFirebaseTime(message.submittedAt).getTime(),
          );
        } else {
          deleteMessages(
            1,
            transformFirebaseTime(message.submittedAt).getTime(),
          );
          addUserMessage(
            message.message,
            transformFirebaseTime(message.submittedAt).getTime(),
          );
        }
      });
    }
  }, [messages, isOpen]);

  return (
    <div style={style || {}} key={`widget-wrapper-${campaignId}-${creatorId}`}>
      <Widget
        key={`${campaignId}-${creatorId}-${messages.length}`}
        handleNewUserMessage={handleNewUserMessage}
        title="Chat"
        subtitle=""
        showBadge={false}
        showCloseButton={false}
        showTimeStamp={false}
        emojis
      />
    </div>
  );
}

export default Chat;
