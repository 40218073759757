import React from "react";
import "./socials.css";
function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="animated"
      version="1.1"
      viewBox="0 0 500 500"
      {...props}
    >
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 250,
          MsTransformOrigin: 250,
          transformOrigin: 250,
        }}
      >
        <ellipse
          cx="250"
          cy="351.32"
          fill="#F5F5F5"
          rx="231.78"
          ry="133.82"
          style={{
            WebkitTransformOrigin: 250,
            MsTransformOrigin: 250,
            transformOrigin: 250,
          }}
          className="animable"
        ></ellipse>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 249.979,
          MsTransformOrigin: 249.979,
          transformOrigin: 249.979,
        }}
      >
        <path
          d="M408.74 376.44c-9.55 5.52-9.55 14.47 0 20s25.06 5.51 34.62 0 9.56-14.47 0-20-25.06-5.52-34.62 0z"
          style={{
            WebkitTransformOrigin: 426.054,
            MsTransformOrigin: 426.054,
            transformOrigin: 426.054,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <ellipse
          cx="264.36"
          cy="406.92"
          fill="#E0E0E0"
          rx="47.57"
          ry="27.47"
          style={{
            WebkitTransformOrigin: 264.36,
            MsTransformOrigin: 264.36,
            transformOrigin: 264.36,
          }}
          className="animable"
        ></ellipse>
        <ellipse
          cx="336.92"
          cy="345.25"
          fill="#E0E0E0"
          rx="96.11"
          ry="55.49"
          style={{
            WebkitTransformOrigin: 336.92,
            MsTransformOrigin: 336.92,
            transformOrigin: 336.92,
          }}
          className="animable"
        ></ellipse>
        <path
          d="M51.22 409.42l7.59 4.38a9.57 9.57 0 008.66 0l157.88-91.16c2.39-1.38 2.39-3.62 0-5l-7.59-4.38a9.57 9.57 0 00-8.66 0L51.22 404.42c-2.39 1.38-2.39 3.58 0 5z"
          style={{
            WebkitTransformOrigin: 138.285,
            MsTransformOrigin: 138.285,
            transformOrigin: 138.285,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 129.765,
          MsTransformOrigin: 129.765,
          transformOrigin: 129.765,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 129.765,
            MsTransformOrigin: 129.765,
            transformOrigin: 129.765,
          }}
        >
          <g
            className="animable"
            style={{
              WebkitTransformOrigin: 129.765,
              MsTransformOrigin: 129.765,
              transformOrigin: 129.765,
            }}
          >
            <path
              d="M188.45 20L57.36 95.69a14.15 14.15 0 00-7.06 11.72v284.84c0 4.8 3 10.41 6.62 12.52s10 1.88 14.15-.53l130.63-75.41a16.69 16.69 0 007.53-13V32c0-4.81-3-10.41-6.62-12.52s-10-1.89-14.16.52z"
              style={{
                WebkitTransformOrigin: 129.765,
                MsTransformOrigin: 129.765,
                transformOrigin: 129.765,
              }}
              fill="#37474F"
              className="animable"
            ></path>
            <path
              d="M69.27 405.1c-4 1.57-9.18 1.49-12.34-.33-3.65-2.11-6.62-7.72-6.62-12.52V107.41A13.47 13.47 0 0152.2 101l12.15 6.89a14.65 14.65 0 00-3.5 8.77v281.7c0 5.78 3.65 8.52 8.42 6.74z"
              style={{
                WebkitTransformOrigin: 59.79,
                MsTransformOrigin: 59.79,
                transformOrigin: 59.79,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M67.49 105.12a14.75 14.75 0 00-6.64 11.5v281.74c0 6.49 4.59 9.14 10.21 5.9l130.64-75.43a16.69 16.69 0 007.53-13V32c0-4.79-3.38-6.74-7.53-4.35z"
              style={{
                WebkitTransformOrigin: 135.04,
                MsTransformOrigin: 135.04,
                transformOrigin: 135.04,
              }}
              fill="#455A64"
              className="animable"
            ></path>
            <path
              d="M74.3 123.7a15.84 15.84 0 00-7.16 12.4v239.64c0 4.57 3.21 6.42 7.16 4.14l122.88-71a15.86 15.86 0 007.16-12.39V56.88c0-4.54-3.22-6.4-7.16-4.13z"
              style={{
                WebkitTransformOrigin: 135.74,
                MsTransformOrigin: 135.74,
                transformOrigin: 135.74,
              }}
              fill="#37474F"
              className="animable"
            ></path>
            <path
              d="M195.28 290.57V53.85L74.3 123.7a15.84 15.84 0 00-7.16 12.4v236.71l121-69.85a15.88 15.88 0 007.14-12.39z"
              style={{
                WebkitTransformOrigin: 131.21,
                MsTransformOrigin: 131.21,
                transformOrigin: 131.21,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M195.28 290.57V53.85L74.3 123.7a15.84 15.84 0 00-7.16 12.4v236.71l121-69.85a15.88 15.88 0 007.14-12.39z"
              style={{
                WebkitTransformOrigin: 131.21,
                MsTransformOrigin: 131.21,
                transformOrigin: 131.21,
              }}
              className="animable"
              opacity="0.3"
            ></path>
            <path
              d="M125.5 81.48l21.78-12.57c1.3-.75 2.35-.22 2.35 1.19a5 5 0 01-2.35 3.9L125.5 86.58c-1.3.75-2.35.22-2.35-1.19a5 5 0 012.35-3.91z"
              className="animable"
              style={{
                WebkitTransformOrigin: 136.39,
                MsTransformOrigin: 136.39,
                transformOrigin: 136.39,
              }}
            ></path>
            <path
              d="M162.87 59.87c1.22-.7 2.21-.13 2.21 1.28a4.89 4.89 0 01-2.21 3.85c-1.22.7-2.2.13-2.2-1.28a4.88 4.88 0 012.2-3.85z"
              className="animable"
              style={{
                WebkitTransformOrigin: 162.875,
                MsTransformOrigin: 162.875,
                transformOrigin: 162.875,
              }}
            ></path>
            <path
              d="M171.71 54.78c1.22-.71 2.2-.14 2.2 1.27a4.89 4.89 0 01-2.2 3.83c-1.22.7-2.21.13-2.21-1.28a4.86 4.86 0 012.21-3.82z"
              className="animable"
              style={{
                WebkitTransformOrigin: 171.705,
                MsTransformOrigin: 171.705,
                transformOrigin: 171.705,
              }}
            ></path>
            <path
              d="M101.07 95.56c1.22-.71 2.21-.14 2.21 1.27a4.86 4.86 0 01-2.21 3.82c-1.22.71-2.2.14-2.2-1.27a4.85 4.85 0 012.2-3.82z"
              className="animable"
              style={{
                WebkitTransformOrigin: 101.075,
                MsTransformOrigin: 101.075,
                transformOrigin: 101.075,
              }}
            ></path>
            <path
              d="M109.91 90.46c1.22-.7 2.2-.13 2.2 1.28a4.87 4.87 0 01-2.2 3.82c-1.22.7-2.21.13-2.21-1.27a4.9 4.9 0 012.21-3.83z"
              className="animable"
              style={{
                WebkitTransformOrigin: 109.905,
                MsTransformOrigin: 109.905,
                transformOrigin: 109.905,
              }}
            ></path>
          </g>
        </g>
      </g>
      <g
        className="animable animator-active"
        style={{
          WebkitTransformOrigin: 164.697,
          MsTransformOrigin: 164.697,
          transformOrigin: 164.697,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 164.697,
            MsTransformOrigin: 164.697,
            transformOrigin: 164.697,
          }}
        >
          <path
            d="M235.76 74.17a3.62 3.62 0 00-1.58-3.35l-6.11-3.53a3.63 3.63 0 00-3.69.32l-126 72.83a10.07 10.07 0 00-3.92 4.56l141.3 81.58z"
            style={{
              WebkitTransformOrigin: 165.118,
              MsTransformOrigin: 165.118,
              transformOrigin: 165.118,
            }}
            fill="#F0F0F0"
            className="animable"
          ></path>
          <path
            d="M93.61 395.35a3.65 3.65 0 001.57 3.35l6.11 3.53a2.8 2.8 0 001.43.37 4.58 4.58 0 002.26-.68L231 329.07c2-1.17 4.73-3.11 4.73-6.52v-96L94.46 145a8.94 8.94 0 00-.85 3.63z"
            style={{
              WebkitTransformOrigin: 164.662,
              MsTransformOrigin: 164.662,
              transformOrigin: 164.662,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M230.76 71.61l-126 72.83a9.88 9.88 0 00-4.45 7.72v246.71c0 2.84 2 4 4.45 2.57l126-72.84c2.46-1.42 4.45-3.22 4.45-6.05V74.17c0-2.83-1.99-3.99-4.45-2.56z"
            style={{
              WebkitTransformOrigin: 167.76,
              MsTransformOrigin: 167.76,
              transformOrigin: 167.76,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M102.72 402.6a2.82 2.82 0 01-1.43-.37 3.64 3.64 0 01-1.58-3.36V152.16a10.36 10.36 0 014.72-8.19l126.06-72.83a3.24 3.24 0 015.27 3v248.41c0 3.41-2.7 5.35-4.73 6.52L105 401.92a4.58 4.58 0 01-2.28.68zm130-331.06a3.52 3.52 0 00-1.72.54l-126 72.83a9.32 9.32 0 00-4.17 7.25v246.71a2.18 2.18 0 003.62 2.1l126.06-72.85c2.89-1.66 4.17-3.38 4.17-5.57V74.17a2.61 2.61 0 00-1-2.41 1.69 1.69 0 00-.93-.22z"
            style={{
              WebkitTransformOrigin: 167.747,
              MsTransformOrigin: 167.747,
              transformOrigin: 167.747,
            }}
            fill="#E6E6E6"
            className="animable"
          ></path>
          <path
            d="M105.8 156v-1.64a1.07 1.07 0 01.31-.83l.09-.05c.17-.1.31.11.31.46v1.65a1.06 1.06 0 01-.31.82h-.09c-.17.14-.31-.07-.31-.41z"
            style={{
              WebkitTransformOrigin: 106.155,
              MsTransformOrigin: 106.155,
              transformOrigin: 106.155,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M107.22 155.16v-2.83a1 1 0 01.32-.82h.08c.17-.1.31.1.31.46v2.83a1 1 0 01-.32.83h-.09c-.16.1-.3-.11-.3-.47z"
            style={{
              WebkitTransformOrigin: 107.575,
              MsTransformOrigin: 107.575,
              transformOrigin: 107.575,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M108.65 154.34v-4a1.06 1.06 0 01.31-.82l.09-.05c.17-.1.31.11.31.46v4a1 1 0 01-.31.82l-.09.05c-.17.1-.31-.1-.31-.46z"
            style={{
              WebkitTransformOrigin: 109.006,
              MsTransformOrigin: 109.006,
              transformOrigin: 109.006,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M110.07 153.52v-7a1 1 0 01.32-.82l.08-.05c.18-.1.32.11.32.46v7a1.06 1.06 0 01-.31.82h-.08c-.19.15-.33-.06-.33-.41z"
            style={{
              WebkitTransformOrigin: 110.429,
              MsTransformOrigin: 110.429,
              transformOrigin: 110.429,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M113.93 151.77v-1.21l-1.6.92c-.09 0-.13 0-.13-.14V151a1.15 1.15 0 010-.2 1.3 1.3 0 01.06-.19l1.7-4.64a.62.62 0 01.08-.18.27.27 0 01.11-.1l.22-.13q.1-.06.15 0a.37.37 0 010 .22v3.57l.35-.21q.11-.06.15 0a.47.47 0 010 .22v.24a.89.89 0 010 .26.36.36 0 01-.15.15l-.35.21v1a.6.6 0 010 .25.33.33 0 01-.15.16l-.27.16c-.1.01-.1.01-.17-.02zm.06-2.16v-2.55l-1.16 3.22z"
            style={{
              WebkitTransformOrigin: 113.614,
              MsTransformOrigin: 113.614,
              transformOrigin: 113.614,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M115.83 150.05a3.29 3.29 0 01-.47-2.08 6.75 6.75 0 01.47-2.63 3.2 3.2 0 011.32-1.59 1.88 1.88 0 01.64-.24.73.73 0 01.5.07.1.1 0 010 .09.74.74 0 010 .12l-.14.53c0 .07 0 .11-.07.12H118a1 1 0 00-.82.13 2.24 2.24 0 00-.9 1.1 4.94 4.94 0 00-.33 1.94 2.65 2.65 0 00.32 1.57c.21.26.5.29.87.07a2.07 2.07 0 00.4-.28 2 2 0 00.31-.35V147l-.67.38h-.14c-.03 0 0-.1 0-.22v-.23a.62.62 0 010-.26.4.4 0 01.14-.15l1.07-.62h.15a.41.41 0 010 .26v2.37a1.22 1.22 0 010 .21.43.43 0 01-.07.16 3.41 3.41 0 01-1.26 1.24q-.78.46-1.24-.09z"
            style={{
              WebkitTransformOrigin: 116.881,
              MsTransformOrigin: 116.881,
              transformOrigin: 116.881,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M228.08 81.17c-.21-.3-.46-.38-.72-.23L222 84.05a2.74 2.74 0 00-.83 2.18v4a2.33 2.33 0 00.31 1.28.81.81 0 00.29.27.45.45 0 00.43 0l5.39-3.11a2.72 2.72 0 00.83-2.18v-4a2.39 2.39 0 00-.34-1.32zM221.65 91a1.16 1.16 0 01-.12-.59v-4a2.14 2.14 0 01.64-1.66l5.39-3.11a.24.24 0 01.23 0 .32.32 0 01.09.08 1.18 1.18 0 01.12.59v4a2.15 2.15 0 01-.64 1.67L222 91.09a.22.22 0 01-.35-.09z"
            style={{
              WebkitTransformOrigin: 224.795,
              MsTransformOrigin: 224.795,
              transformOrigin: 224.795,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M225.05 83.8v4.78l-2.9 1.68h-.09V86.5a1.65 1.65 0 01.46-1.23z"
            style={{
              WebkitTransformOrigin: 223.554,
              MsTransformOrigin: 223.554,
              transformOrigin: 223.554,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M228.66 82.33c.2-.12.36.46.36 1.29v.75a3 3 0 01-.37 1.74l-.36.25v-3.82z"
            style={{
              WebkitTransformOrigin: 228.661,
              MsTransformOrigin: 228.661,
              transformOrigin: 228.661,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M125 144.27a2.47 2.47 0 01-.93 2c-.51.29-.93-.11-.93-.89a2.47 2.47 0 01.93-2c.48-.3.93.1.93.89z"
            style={{
              WebkitTransformOrigin: 124.07,
              MsTransformOrigin: 124.07,
              transformOrigin: 124.07,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M124 141a5.6 5.6 0 00-2.16 3.23c-.08.29 0 .57.1.65.18.11.44-.12.54-.49a3.94 3.94 0 011.52-2.28 1 1 0 011.52.53c.1.25.36.18.54-.14a1 1 0 00.1-.76A1.4 1.4 0 00124 141z"
            style={{
              WebkitTransformOrigin: 124.001,
              MsTransformOrigin: 124.001,
              transformOrigin: 124.001,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M124 138.75a8.68 8.68 0 00-3.31 4.71c-.1.31-.07.62.08.72.17.11.41-.11.53-.46a7.13 7.13 0 012.7-3.85 1.79 1.79 0 012.7.74c.12.21.36.14.53-.16a.93.93 0 00.08-.8 2.19 2.19 0 00-3.31-.9z"
            style={{
              WebkitTransformOrigin: 123.999,
              MsTransformOrigin: 123.999,
              transformOrigin: 123.999,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M162.58 125.79a.59.59 0 01-.05.22.49.49 0 01-.12.15l-.27.15h-.12a.21.21 0 01-.06-.16v-5.51l-1.05 1.84a.26.26 0 01-.12.13.11.11 0 01-.12 0l-.16-.22a.26.26 0 010-.18.44.44 0 01.07-.22l1.38-2.44a.53.53 0 01.1-.13l.11-.07.27-.15a.08.08 0 01.12 0 .22.22 0 01.05.16z"
            style={{
              WebkitTransformOrigin: 161.552,
              MsTransformOrigin: 161.552,
              transformOrigin: 161.552,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M164.11 120.47a.09.09 0 01.13 0 .23.23 0 010 .16v.67a.63.63 0 010 .22.39.39 0 01-.13.15l-.43.25h-.12a.19.19 0 01-.05-.16v-.67a.44.44 0 01.05-.22.31.31 0 01.12-.15zm0 3.49a.12.12 0 01.13 0 .26.26 0 010 .17v.67a.54.54 0 010 .21.28.28 0 01-.13.15l-.43.26a.08.08 0 01-.12 0 .17.17 0 01-.05-.15v-.67a.48.48 0 01.05-.23.33.33 0 01.12-.14z"
            style={{
              WebkitTransformOrigin: 163.881,
              MsTransformOrigin: 163.881,
              transformOrigin: 163.881,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M167.31 123.33a.1.1 0 01-.13 0 .22.22 0 01-.05-.16v-1.37l-2 1.17a.09.09 0 01-.12 0 .23.23 0 01-.05-.16v-.36a1.62 1.62 0 010-.18 1.72 1.72 0 01.04-.27l2.05-5.32c0-.09.07-.17.11-.24a.37.37 0 01.14-.14l.26-.16a.12.12 0 01.13 0 .25.25 0 01.05.16v4.18l.56-.32a.08.08 0 01.12 0 .21.21 0 010 .15v.36a.54.54 0 010 .22.44.44 0 01-.12.16l-.56.32v1.37a.64.64 0 01-.05.23.39.39 0 01-.13.15zm-.18-2.44v-2.82l-1.41 3.63z"
            style={{
              WebkitTransformOrigin: 166.696,
              MsTransformOrigin: 166.696,
              transformOrigin: 166.696,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M169.56 120.34a2 2 0 00.1.26.35.35 0 00.17.19.51.51 0 00.31 0 1.57 1.57 0 00.5-.21 2.55 2.55 0 00.38-.29 2.29 2.29 0 00.32-.43 2 2 0 00.21-.51 2.06 2.06 0 00.09-.62 1.28 1.28 0 00-.08-.49.41.41 0 00-.21-.25.5.5 0 00-.32 0 1.24 1.24 0 00-.39.16l-.55.32a.09.09 0 01-.12 0 .22.22 0 01-.05-.16V118a.41.41 0 010-.19c0-.07 0-.12.06-.17l1.17-2.62-1.86 1.08a.09.09 0 01-.13 0 .22.22 0 010-.16v-.36a.63.63 0 010-.22.39.39 0 01.13-.15l2.55-1.47a.1.1 0 01.13 0 .27.27 0 010 .17v.36a.44.44 0 010 .19.9.9 0 01-.08.21l-1.15 2.57a1.4 1.4 0 01.59-.18.64.64 0 01.47.14 1 1 0 01.29.47 2.48 2.48 0 01.11.78 3.64 3.64 0 01-.13 1 3.94 3.94 0 01-.34.87 3.85 3.85 0 01-.52.73 2.89 2.89 0 01-.62.51 2 2 0 01-.75.28.71.71 0 01-.52-.12.74.74 0 01-.31-.38 1.82 1.82 0 01-.11-.59.41.41 0 01.05-.18.25.25 0 01.1-.12l.3-.17a.09.09 0 01.11 0 .17.17 0 01.1.06z"
            style={{
              WebkitTransformOrigin: 170.55,
              MsTransformOrigin: 170.55,
              transformOrigin: 170.55,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <ellipse
            cx="134.97"
            cy="199.91"
            fill="#DD26D7"
            rx="42.51"
            ry="24.05"
            style={{
              WebkitTransformOrigin: 134.97,
              MsTransformOrigin: 134.97,
              transformOrigin: 134.97,
            }}
            className="animable"
            transform="rotate(-68.79)"
          ></ellipse>
          <ellipse
            cx="134.97"
            cy="199.91"
            rx="42.51"
            ry="24.05"
            style={{
              WebkitTransformOrigin: 134.97,
              MsTransformOrigin: 134.97,
              transformOrigin: 134.97,
            }}
            className="animable"
            opacity="0.3"
            transform="rotate(-68.79)"
          ></ellipse>
          <g
            className="animable"
            style={{
              WebkitTransformOrigin: 146.923,
              MsTransformOrigin: 146.923,
              transformOrigin: 146.923,
            }}
          >
            <path
              d="M181.1 180.75c-2.59-3.25-3.75-2.55-4.58.95a39.79 39.79 0 01-1.67 5.08 13.22 13.22 0 00-1-2.78 1.62 1.62 0 00-1.89-.9c-.28.15.14 1.2.13 2.49a15.86 15.86 0 00.18 4.49A6.16 6.16 0 01172 193c-1.71 3.38-8 12.91-8 12.91-1.34-.72-5.63-2.84-11.36-5.35-4.93-2.17-4.34-2.14-7.25-2.55l-1.29 9.42c5.56 2.1 10 4.16 19 7.06 2.81.91 5.06 1 6.39-1.23s5.23-9.49 8-15c1.56-3.1 2.9-6.29 3.65-7.94 2.64-5.76.86-8.41-.04-9.57z"
              style={{
                WebkitTransformOrigin: 163.313,
                MsTransformOrigin: 163.313,
                transformOrigin: 163.313,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M130.28 239.59c-.57.18-1.15.34-1.71.46a16.09 16.09 0 01-4.92.39 12.41 12.41 0 01-2.21-.34 16.93 16.93 0 01.86-2.44 12.56 12.56 0 01.75-1.52 12.87 12.87 0 01.73-1.14z"
              style={{
                WebkitTransformOrigin: 125.86,
                MsTransformOrigin: 125.86,
                transformOrigin: 125.86,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M149.56 216l.19 7.15c-.22.34-.43.67-.66 1a46.62 46.62 0 01-12.9 12.64c-.8.5-1.59.94-2.37 1.34a25.6 25.6 0 01-3.52 1.44c-.57.18-1.15.34-1.71.46-2.64-.53-4.77-1.45-5.87-2.79.11-.38.22-.76.35-1.12a25.3 25.3 0 012.43-5c1.38-2.31 2.68-4 2.69-4.71l-3.56-12.27-.41-1.44a33.67 33.67 0 000-13.54l7.92-1.74.72-.16 7.5-.1h.28a35.85 35.85 0 014.78.8c.89.31 7.32 6.61 6.91 12.64a7.75 7.75 0 01-2.77 5.4z"
              style={{
                WebkitTransformOrigin: 137.534,
                MsTransformOrigin: 137.534,
                transformOrigin: 137.534,
              }}
              fill="#455A64"
              className="animable"
            ></path>
            <path
              d="M125.73 240.46l-5.65-16c1.77-3.58 5-10.25 5-10.25.4-.57.83-1.29 1.17-1.87 2.43-4.11.48-11-2.09-13.21-5.13 1-7.23 9.85-12.12 21.92-1.38 3.41-.52 4.48.44 6.78.8 2 2.82 6.36 4.73 10.49a14.12 14.12 0 008.52 2.14z"
              style={{
                WebkitTransformOrigin: 119.34,
                MsTransformOrigin: 119.34,
                transformOrigin: 119.34,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M151.48 178.61a2.4 2.4 0 00-.54-1.72c-.51-.63-.72-.68-.51-1.52.24-1 1-2.16.18-3.79-.58-1.17-1.41-2.61-5.36-4.39-4.53-2-10-1.05-12.88.18-4.25 1.81-6 3.93-5.62 8a5.17 5.17 0 00-2.8 6.24 6.33 6.33 0 01.42 2 5.38 5.38 0 01-.92 2.08 6.29 6.29 0 00-.62 5.12 4.36 4.36 0 004 3 2 2 0 01-.78-2.46 7.07 7.07 0 002.69 2.91 6.58 6.58 0 003.7.72c.51-.08.39-.8.69-1.18.51-.63 1.06-1.22 1.59-1.83 1.4-1.62 2.81-3.23 4.21-4.84l6.78-7.81s.21-.2.21-.25l.85 1.74s1.37 1.9 2.82 1.91a1.34 1.34 0 001.53-1.13.65.65 0 01-1.12-.59c.1-.66 1.36-1 1.48-2.39z"
              style={{
                WebkitTransformOrigin: 137.017,
                MsTransformOrigin: 137.017,
                transformOrigin: 137.017,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M146.38 180.38c1.44-.09 4.12 2.62 5 5.5.77 2.62.77 6.25-1.72 7.23a4.1 4.1 0 00.16-2.61c0 1.05-1.17 2.79-3.11 3.82a9.62 9.62 0 01-6.43 1c-4.28-.79 6.1-14.94 6.1-14.94z"
              style={{
                WebkitTransformOrigin: 145.539,
                MsTransformOrigin: 145.539,
                transformOrigin: 145.539,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M144.09 172.9c1.88.72 3.76 3.46 4.09 11.36.28 6.7-1.7 8.48-2.71 9s-3.05.39-5 .17l.18 3.85s4 6.41 2.28 9.64c-6.22-1.82-10.79-9.47-10.79-9.47l-.54-8.78a2.8 2.8 0 01-3.13 0c-1.82-1.22-2.41-3.81-1.24-5.22a2.64 2.64 0 014.26-.14s4.55-.75 6.85-2.38c3.86-2.77 4.91-4.65 5.75-8.03z"
              style={{
                WebkitTransformOrigin: 137.416,
                MsTransformOrigin: 137.416,
                transformOrigin: 137.416,
              }}
              fill="#FFA8A7"
              className="animable"
            ></path>
            <path
              d="M131.42 182.21l.19 3.72a1.75 1.75 0 001.71-1.88 1.93 1.93 0 00-1.9-1.84z"
              style={{
                WebkitTransformOrigin: 132.372,
                MsTransformOrigin: 132.372,
                transformOrigin: 132.372,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M144.68 182.14a.89.89 0 101.78 0 .89.89 0 10-1.78 0z"
              style={{
                WebkitTransformOrigin: 145.57,
                MsTransformOrigin: 145.57,
                transformOrigin: 145.57,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M137.61 182.85a1 1 0 101.91 0 1 1 0 00-1-1 1 1 0 00-.91 1z"
              style={{
                WebkitTransformOrigin: 138.565,
                MsTransformOrigin: 138.565,
                transformOrigin: 138.565,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              d="M139.92 188.26l3.31.62a1.63 1.63 0 01-1.9 1.46 1.84 1.84 0 01-1.41-2.08z"
              style={{
                WebkitTransformOrigin: 141.564,
                MsTransformOrigin: 141.564,
                transformOrigin: 141.564,
              }}
              fill="#B16668"
              className="animable"
            ></path>
            <path
              d="M142 190.32a1.6 1.6 0 00-1.77-1.38 2.44 2.44 0 00-.26.06 1.77 1.77 0 001.37 1.34 1.33 1.33 0 00.66-.02z"
              style={{
                WebkitTransformOrigin: 140.985,
                MsTransformOrigin: 140.985,
                transformOrigin: 140.985,
              }}
              fill="#F28F8F"
              className="animable"
            ></path>
            <path
              d="M147 178.88l-2.44-.32a1.18 1.18 0 011.37-1.07 1.28 1.28 0 011.07 1.39z"
              style={{
                WebkitTransformOrigin: 145.783,
                MsTransformOrigin: 145.783,
                transformOrigin: 145.783,
              }}
              fill="#263238"
              className="animable"
            ></path>
            <path
              style={{
                WebkitTransformOrigin: 143.445,
                MsTransformOrigin: 143.445,
                transformOrigin: 143.445,
              }}
              fill="#F28F8F"
              d="M141.75 181.38L142.31 186.91 145.14 185.83 141.75 181.38z"
              className="animable"
            ></path>
            <path
              d="M140.44 193.45c-2.09-.15-6.42-1.14-7.19-2.85a4.55 4.55 0 001.63 2.14c1.34 1 5.62 2 5.62 2z"
              style={{
                WebkitTransformOrigin: 136.875,
                MsTransformOrigin: 136.875,
                transformOrigin: 136.875,
              }}
              fill="#F28F8F"
              className="animable"
            ></path>
            <path
              d="M141.52 183.05a2.94 2.94 0 11-3.07-2.8 2.94 2.94 0 013.07 2.8z"
              style={{
                WebkitTransformOrigin: 138.583,
                MsTransformOrigin: 138.583,
                transformOrigin: 138.583,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M138.58 186.3a3.12 3.12 0 01-.14-6.23 3.14 3.14 0 013.25 3 3.12 3.12 0 01-3 3.26zm0-5.88h-.12a2.77 2.77 0 00-1.74 4.81 2.77 2.77 0 004.63-2.17 2.78 2.78 0 00-2.77-2.64z"
              style={{
                WebkitTransformOrigin: 138.623,
                MsTransformOrigin: 138.623,
                transformOrigin: 138.623,
              }}
              fill="#455A64"
              className="animable"
            ></path>
            <path
              d="M149.4 182.19a2.94 2.94 0 11-3.06-2.81 2.93 2.93 0 013.06 2.81z"
              style={{
                WebkitTransformOrigin: 146.463,
                MsTransformOrigin: 146.463,
                transformOrigin: 146.463,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M146.46 185.44a3.12 3.12 0 01-3.1-3 3.11 3.11 0 013-3.25 3.11 3.11 0 013.25 3 3.11 3.11 0 01-3 3.25zm0-5.88h-.13a2.72 2.72 0 00-1.92.9 2.77 2.77 0 102.05-.9z"
              style={{
                WebkitTransformOrigin: 146.485,
                MsTransformOrigin: 146.485,
                transformOrigin: 146.485,
              }}
              fill="#455A64"
              className="animable"
            ></path>
            <path
              d="M141.53 183.42a.18.18 0 01-.18-.17 1.22 1.22 0 01.33-.89 1.24 1.24 0 01.85-.4 1.21 1.21 0 01.89.32 1.29 1.29 0 01.4.86.18.18 0 01-.17.18.17.17 0 01-.17-.17.86.86 0 00-.29-.61.83.83 0 00-.63-.23.89.89 0 00-.85.92.19.19 0 01-.16.19z"
              style={{
                WebkitTransformOrigin: 142.584,
                MsTransformOrigin: 142.584,
                transformOrigin: 142.584,
              }}
              fill="#455A64"
              className="animable"
            ></path>
            <path
              d="M135.66 183.15l-4.56-.18s-.26-.18-.57-.36l5.12.19a.18.18 0 010 .35z"
              style={{
                WebkitTransformOrigin: 133.159,
                MsTransformOrigin: 133.159,
                transformOrigin: 133.159,
              }}
              fill="#455A64"
              className="animable"
            ></path>
          </g>
          <path
            d="M163.53 165.54a.24.24 0 010-.18.62.62 0 01.07-.23l1.47-2.62a1.74 1.74 0 01.11-.18.26.26 0 01.12-.13l.53-.31a.1.1 0 01.13 0 .26.26 0 01.05.17v6.71a.59.59 0 01-.05.22.36.36 0 01-.13.16l-.55.32a.1.1 0 01-.13 0 .26.26 0 01-.05-.17v-5.13l-1 1.71a.27.27 0 01-.13.13.11.11 0 01-.13 0z"
            style={{
              WebkitTransformOrigin: 164.761,
              MsTransformOrigin: 164.761,
              transformOrigin: 164.761,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M166.87 167.36a1 1 0 01.13-.36.53.53 0 01.21-.25l.58-.34a.09.09 0 01.11 0 .18.18 0 010 .14v.07l-.45 2a2.13 2.13 0 01-.09.27.45.45 0 01-.2.23l-.3.18a.09.09 0 01-.11 0 .17.17 0 010-.13z"
            style={{
              WebkitTransformOrigin: 167.326,
              MsTransformOrigin: 167.326,
              transformOrigin: 167.326,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M172.15 160.93V162.79A9.07 9.07 0 01172 164a5.11 5.11 0 01-.31 1.07 3.65 3.65 0 01-.56.91 2.84 2.84 0 01-.83.7 1.63 1.63 0 01-.84.27.78.78 0 01-.55-.28 1.73 1.73 0 01-.31-.71 6.39 6.39 0 01-.11-1v-.89-.91a9.07 9.07 0 01.11-1.19 5.3 5.3 0 01.31-1.09 3.68 3.68 0 01.55-.92 3 3 0 01.84-.71 1.62 1.62 0 01.83-.27.75.75 0 01.56.27 1.78 1.78 0 01.31.72 6.16 6.16 0 01.15.96zm-2.71 3.36a2.14 2.14 0 00.23 1c.13.19.36.2.67 0a1.51 1.51 0 00.67-.8 4.2 4.2 0 00.23-1.29v-.88-.87a2.12 2.12 0 00-.23-1c-.13-.2-.36-.2-.67 0a1.51 1.51 0 00-.67.8 4.2 4.2 0 00-.23 1.29v.88c0 .32-.01.58 0 .87z"
            style={{
              WebkitTransformOrigin: 170.32,
              MsTransformOrigin: 170.32,
              transformOrigin: 170.32,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M176.55 158.39V160.26a7.49 7.49 0 01-.12 1.18 4.14 4.14 0 01-.31 1.07 3.35 3.35 0 01-.55.91 3 3 0 01-.83.7 1.65 1.65 0 01-.84.27.78.78 0 01-.55-.28 1.73 1.73 0 01-.31-.71 7.61 7.61 0 01-.12-1v-.89-.91a11.41 11.41 0 01.12-1.19 5.3 5.3 0 01.31-1.09 3.68 3.68 0 01.55-.92 2.91 2.91 0 01.84-.71 1.6 1.6 0 01.83-.27.72.72 0 01.55.27 1.54 1.54 0 01.31.72 5.24 5.24 0 01.12.98zm-2.72 3.36a2.12 2.12 0 00.24 1c.13.19.35.2.67 0a1.51 1.51 0 00.67-.8 4.2 4.2 0 00.23-1.29v-.88-.86a2.14 2.14 0 00-.23-1c-.13-.19-.36-.2-.67 0a1.47 1.47 0 00-.67.8 4.18 4.18 0 00-.24 1.29v.88c0 .32-.01.61 0 .86z"
            style={{
              WebkitTransformOrigin: 174.735,
              MsTransformOrigin: 174.735,
              transformOrigin: 174.735,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M177.27 160.37a3.63 3.63 0 01.14-1 4.31 4.31 0 01.42-1 1.45 1.45 0 01-.4-1.18 3.74 3.74 0 01.13-.94 4.06 4.06 0 01.36-.88 3.41 3.41 0 01.53-.74 3.1 3.1 0 01.68-.54 1.7 1.7 0 01.67-.24.81.81 0 01.54.12 1 1 0 01.35.47 2.06 2.06 0 01.13.8 3.47 3.47 0 01-.1.85 3.78 3.78 0 01-.3.79.93.93 0 01.42.47 2.12 2.12 0 01.15.87 3.71 3.71 0 01-.13 1 4.17 4.17 0 01-1 1.73 3.18 3.18 0 01-.77.61 1.86 1.86 0 01-.77.28.94.94 0 01-.59-.12 1 1 0 01-.37-.5 2.3 2.3 0 01-.09-.85zm.91-.52a.66.66 0 00.08.37.37.37 0 00.21.18.6.6 0 00.3 0 1.11 1.11 0 00.36-.15 1.67 1.67 0 00.35-.26 2.11 2.11 0 00.31-.36 2.74 2.74 0 00.21-.42 1.5 1.5 0 00.08-.47.75.75 0 00-.08-.37.42.42 0 00-.21-.17.64.64 0 00-.31 0 1.42 1.42 0 00-.35.14 2.11 2.11 0 00-.36.27 2.6 2.6 0 00-.3.36 1.65 1.65 0 00-.21.42 1.21 1.21 0 00-.08.46zm.17-3.18a.73.73 0 00.06.32.33.33 0 00.17.16.42.42 0 00.25 0 1.38 1.38 0 00.3-.11 1.85 1.85 0 00.29-.22 1.54 1.54 0 00.25-.31 2 2 0 00.18-.36 1.34 1.34 0 00.06-.4.67.67 0 00-.06-.32.34.34 0 00-.18-.16.42.42 0 00-.25 0 1.15 1.15 0 00-.29.11 1.6 1.6 0 00-.3.23 1.53 1.53 0 00-.25.3 1.49 1.49 0 00-.17.36 1.29 1.29 0 00-.06.4z"
            style={{
              WebkitTransformOrigin: 179.127,
              MsTransformOrigin: 179.127,
              transformOrigin: 179.127,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M168.71 170.55a1 1 0 01.36-.13.36.36 0 01.29.06.48.48 0 01.2.27 1.45 1.45 0 01.08.52 2.43 2.43 0 01-.08.61 2.08 2.08 0 01-.2.51 1.77 1.77 0 01-.29.41 1.59 1.59 0 01-.36.28l-.7.41v1.44a.28.28 0 010 .13c0 .05 0 .08-.08.09l-.16.09h-.07a.13.13 0 010-.1v-3.81a.28.28 0 010-.13.17.17 0 01.07-.09zM168 173l.68-.39a1.11 1.11 0 00.43-.43 1.33 1.33 0 00.15-.65c0-.25-.05-.41-.15-.46s-.24 0-.43.07l-.68.4z"
            style={{
              WebkitTransformOrigin: 168.665,
              MsTransformOrigin: 168.665,
              transformOrigin: 168.665,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M170.84 170.32a.85.85 0 01.37-.11.41.41 0 01.28.11.82.82 0 01.18.31 2.18 2.18 0 01.08.49v.55a3.65 3.65 0 01-.08.59 4 4 0 01-.18.52 2 2 0 01-.28.42 1.36 1.36 0 01-.37.31.76.76 0 01-.37.12.39.39 0 01-.27-.11.62.62 0 01-.18-.31 1.65 1.65 0 01-.09-.49v-.12-.15-.28a2.9 2.9 0 01.09-.59 2.28 2.28 0 01.18-.52 2.09 2.09 0 01.27-.43 1.56 1.56 0 01.37-.31zm.56 1.52a1.85 1.85 0 000-.25 1.77 1.77 0 000-.24 1.17 1.17 0 00-.05-.3.42.42 0 00-.11-.21.25.25 0 00-.16-.08.49.49 0 00-.24.07.74.74 0 00-.23.2 1.31 1.31 0 00-.17.28 1.6 1.6 0 00-.11.33 3.48 3.48 0 00-.05.36v.49a2.08 2.08 0 00.05.3.52.52 0 00.11.21.27.27 0 00.17.08.49.49 0 00.23-.07 1.16 1.16 0 00.24-.2 1.56 1.56 0 00.16-.28 1.17 1.17 0 00.11-.33 2 2 0 00.05-.36z"
            style={{
              WebkitTransformOrigin: 170.84,
              MsTransformOrigin: 170.84,
              transformOrigin: 170.84,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M173.37 171.14a.24.24 0 000-.16.17.17 0 00-.12-.06h-.2l-.29.07a.42.42 0 01-.44-.08.81.81 0 01-.12-.48 1.47 1.47 0 010-.36 2.35 2.35 0 01.14-.37 2.19 2.19 0 01.23-.33 1.59 1.59 0 01.33-.25.83.83 0 01.33-.13.47.47 0 01.24 0 .29.29 0 01.14.13.42.42 0 01.06.19.31.31 0 010 .13.24.24 0 01-.08.09l-.12.07h-.06a.09.09 0 01-.06 0 .2.2 0 00-.15-.09.69.69 0 00-.27.09.9.9 0 00-.28.27.55.55 0 00-.11.34.32.32 0 000 .16.14.14 0 00.1.06.6.6 0 00.18 0l.28-.06a.64.64 0 01.3 0 .31.31 0 01.19.09.42.42 0 01.1.2 1.12 1.12 0 010 .27 1.81 1.81 0 01-.05.38 2.05 2.05 0 01-.16.38 2 2 0 01-.25.34 1.27 1.27 0 01-.34.27.73.73 0 01-.33.13.9.9 0 01-.25 0 .36.36 0 01-.16-.14.33.33 0 01-.06-.19.35.35 0 010-.13.22.22 0 01.07-.09l.14-.08h.11a.22.22 0 00.17.11.64.64 0 00.28-.09l.17-.13.15-.17a1.62 1.62 0 00.1-.18.68.68 0 00.09-.2z"
            style={{
              WebkitTransformOrigin: 172.891,
              MsTransformOrigin: 172.891,
              transformOrigin: 172.891,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M174.7 170.19a1.12 1.12 0 000 .2.39.39 0 000 .14.12.12 0 00.1.06.38.38 0 00.17-.06l.2-.11h.07a.12.12 0 010 .09v.19a.28.28 0 010 .13s0 .08-.07.09l-.24.14c-.23.13-.39.14-.49 0a1.13 1.13 0 01-.15-.69v-1.53l-.28.17a.06.06 0 01-.07 0 .12.12 0 010-.09v-.19a.31.31 0 010-.13.22.22 0 01.07-.09l.28-.16v-1a.35.35 0 010-.13.24.24 0 01.08-.09l.14-.08a.06.06 0 01.07 0 .12.12 0 010 .09v1l.49-.28a.06.06 0 01.07 0 .12.12 0 010 .09v.19a.31.31 0 010 .13.17.17 0 01-.07.09l-.49.28z"
            style={{
              WebkitTransformOrigin: 174.59,
              MsTransformOrigin: 174.59,
              transformOrigin: 174.59,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M176.85 169.14a.27.27 0 000-.17.17.17 0 00-.12-.05.72.72 0 00-.2 0l-.29.07c-.21.05-.36 0-.44-.08a.81.81 0 01-.13-.48 1.56 1.56 0 01.05-.36 1.58 1.58 0 01.14-.36 1.5 1.5 0 01.23-.33 1.4 1.4 0 01.33-.26.87.87 0 01.33-.13.42.42 0 01.23 0 .32.32 0 01.15.13.41.41 0 010 .19.15.15 0 010 .13c0 .05 0 .08-.07.09l-.13.08h-.06a.19.19 0 00-.15-.08.43.43 0 00-.27.09.8.8 0 00-.29.26.62.62 0 00-.11.34.3.3 0 000 .16.16.16 0 00.11.07.69.69 0 00.18 0l.28-.06a.6.6 0 01.29 0 .41.41 0 01.2.09.42.42 0 01.1.2 1.12 1.12 0 010 .27 1.31 1.31 0 01-.06.38 1.37 1.37 0 01-.15.38 2.11 2.11 0 01-.26.35 1.76 1.76 0 01-.33.27.93.93 0 01-.34.12.45.45 0 01-.24 0 .35.35 0 01-.16-.13.36.36 0 01-.06-.2.21.21 0 010-.13.24.24 0 01.08-.09l.14-.08a.07.07 0 01.06 0 .23.23 0 00.17.1.55.55 0 00.28-.09l.17-.13a1.07 1.07 0 00.14-.16.87.87 0 00.1-.19.42.42 0 00.1-.21z"
            style={{
              WebkitTransformOrigin: 176.409,
              MsTransformOrigin: 176.409,
              transformOrigin: 176.409,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M188.61 149a.48.48 0 010-.23.36.36 0 01.13-.16l2.53-1.46a.09.09 0 01.13 0 .2.2 0 01.06.16v.74a.49.49 0 01-.06.23.31.31 0 01-.13.16l-1.89 1.09-.07 1.37a1.87 1.87 0 01.36-.34 2.33 2.33 0 01.29-.19 1.65 1.65 0 01.7-.25.82.82 0 01.56.14 1.06 1.06 0 01.37.54 2.76 2.76 0 01.14.95 5 5 0 01-.14 1.21 4.08 4.08 0 01-.38 1 3.47 3.47 0 01-.56.77 3.41 3.41 0 01-.69.54 2.05 2.05 0 01-.8.3.81.81 0 01-.55-.14.89.89 0 01-.33-.46 2.13 2.13 0 01-.13-.7.46.46 0 01.05-.23.36.36 0 01.13-.16l.55-.31a.09.09 0 01.12 0s.06.07.08.13a.69.69 0 00.12.32.39.39 0 00.46.09 1.35 1.35 0 00.3-.14 1.59 1.59 0 00.62-.68 2.13 2.13 0 00.24-1 .75.75 0 00-.24-.64c-.16-.12-.36-.1-.62 0a1.47 1.47 0 00-.32.23 1.74 1.74 0 00-.19.22l-.14.19a.47.47 0 01-.15.13l-.62.36a.09.09 0 01-.13 0 .21.21 0 01-.06-.17z"
            style={{
              WebkitTransformOrigin: 189.94,
              MsTransformOrigin: 189.94,
              transformOrigin: 189.94,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M194.54 148.49a2.24 2.24 0 00.26-.66 2.58 2.58 0 00.06-.51 1 1 0 00-.17-.63c-.11-.13-.3-.12-.56 0a1.21 1.21 0 00-.54.6 3 3 0 00-.25.85.57.57 0 01-.08.25.35.35 0 01-.12.14l-.53.31a.12.12 0 01-.14 0 .26.26 0 01-.05-.18 4.79 4.79 0 01.16-1 4.72 4.72 0 01.36-.93 4.12 4.12 0 01.53-.8 2.4 2.4 0 01.65-.56 1.17 1.17 0 01.74-.21.74.74 0 01.51.25 1.3 1.3 0 01.3.57 2.68 2.68 0 01.1.76v.51a3.55 3.55 0 01-.09.52 5.81 5.81 0 01-.22.61c-.1.23-.22.48-.38.77l-1.25 2.47 1.88-1.08a.09.09 0 01.13 0 .23.23 0 01.05.16v.74a.64.64 0 01-.05.23.36.36 0 01-.13.16l-3.15 1.82a.12.12 0 01-.13 0 .22.22 0 010-.17v-.63a1.07 1.07 0 01.06-.35l.12-.25z"
            style={{
              WebkitTransformOrigin: 194.152,
              MsTransformOrigin: 194.152,
              transformOrigin: 194.152,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M200.19 144.74V146.61a8.91 8.91 0 01-.11 1.18 5.11 5.11 0 01-.31 1.07 3.65 3.65 0 01-.56.91 2.84 2.84 0 01-.83.7 1.63 1.63 0 01-.84.27.74.74 0 01-.55-.28 1.73 1.73 0 01-.31-.71 6.39 6.39 0 01-.11-1v-.89-.91a9.23 9.23 0 01.11-1.19 5.3 5.3 0 01.31-1.09 3.87 3.87 0 01.55-.92 3 3 0 01.84-.71 1.62 1.62 0 01.83-.27.75.75 0 01.56.27 1.78 1.78 0 01.31.72 6.16 6.16 0 01.11.98zm-2.71 3.36a2.14 2.14 0 00.23 1c.13.19.35.2.67 0a1.51 1.51 0 00.67-.8 4.2 4.2 0 00.23-1.29v-.88-.86a2.14 2.14 0 00-.23-1c-.13-.2-.36-.2-.67 0a1.47 1.47 0 00-.67.8 4.2 4.2 0 00-.23 1.29v.88c0 .32-.01.6 0 .86z"
            style={{
              WebkitTransformOrigin: 198.38,
              MsTransformOrigin: 198.38,
              transformOrigin: 198.38,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M204.69 146.27a.16.16 0 010 .07.59.59 0 010 .23.36.36 0 01-.13.16l-.64.37a.2.2 0 01-.22 0l-.09-.08-1.61-1.77V148a.46.46 0 01-.06.23.36.36 0 01-.13.16l-.54.32a.12.12 0 01-.13 0 .22.22 0 01-.06-.17v-6.71a.53.53 0 01.06-.23.32.32 0 01.13-.15l.54-.32a.1.1 0 01.13 0 .22.22 0 01.06.17v2.44l1.47-3.34a2 2 0 01.12-.25.69.69 0 01.18-.16l.62-.36a.12.12 0 01.13 0 .26.26 0 01.05.17.45.45 0 010 .18l-1.83 4.02z"
            style={{
              WebkitTransformOrigin: 202.89,
              MsTransformOrigin: 202.89,
              transformOrigin: 202.89,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M189.82 158.36a.06.06 0 01.08 0 .12.12 0 010 .09v.21a.28.28 0 010 .13.15.15 0 01-.08.09l-1.26.73V161l1.19-.69a.06.06 0 01.07 0 .12.12 0 010 .09v.21a.28.28 0 010 .13s0 .08-.07.09l-1.19.69v1.55a.35.35 0 010 .13.22.22 0 01-.07.09l-.16.09a.06.06 0 01-.07 0 .12.12 0 010-.09v-3.82a.31.31 0 010-.13.22.22 0 01.07-.09z"
            style={{
              WebkitTransformOrigin: 189.08,
              MsTransformOrigin: 189.08,
              transformOrigin: 189.08,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M191.14 158.61a.76.76 0 01.37-.12.37.37 0 01.27.11.71.71 0 01.19.31 2.18 2.18 0 01.08.49v.6a2.8 2.8 0 01-.09.59 2.13 2.13 0 01-.18.52 1.73 1.73 0 01-.27.43 1.56 1.56 0 01-.37.31.73.73 0 01-.37.11.41.41 0 01-.28-.11.82.82 0 01-.18-.31 2.09 2.09 0 01-.08-.49.45.45 0 000-.11v-.31a.62.62 0 000-.13 3 3 0 01.26-1.11 2 2 0 01.28-.42 1.36 1.36 0 01.37-.36zm.56 1.52v-.25-.25a1.25 1.25 0 000-.3.52.52 0 00-.11-.21.25.25 0 00-.17-.08.49.49 0 00-.23.07.9.9 0 00-.23.2 1.17 1.17 0 00-.17.28 1.6 1.6 0 00-.11.33 2 2 0 000 .36 1.85 1.85 0 000 .25 1.63 1.63 0 000 .24 1.17 1.17 0 000 .3.52.52 0 00.11.21.25.25 0 00.17.08.49.49 0 00.23-.07.74.74 0 00.23-.2 1 1 0 00.17-.28 1.73 1.73 0 00.11-.32 2.23 2.23 0 000-.36z"
            style={{
              WebkitTransformOrigin: 191.14,
              MsTransformOrigin: 191.14,
              transformOrigin: 191.14,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M192.89 160.57a.61.61 0 010 .13.36.36 0 01-.08.09l-.14.08h-.07a.17.17 0 010-.1v-3.87a.35.35 0 010-.14s0-.07.07-.08l.14-.09a.08.08 0 01.08 0 .19.19 0 010 .09z"
            style={{
              WebkitTransformOrigin: 192.744,
              MsTransformOrigin: 192.744,
              transformOrigin: 192.744,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M193.81 160a.35.35 0 010 .13.19.19 0 01-.07.08l-.15.09a.06.06 0 01-.07 0 .12.12 0 010-.09v-3.88a.31.31 0 010-.13.35.35 0 01.07-.09l.15-.08h.07a.15.15 0 010 .1z"
            style={{
              WebkitTransformOrigin: 193.665,
              MsTransformOrigin: 193.665,
              transformOrigin: 193.665,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M195.2 156.26a.76.76 0 01.37-.12.46.46 0 01.28.11.77.77 0 01.18.31 1.73 1.73 0 01.08.49.53.53 0 010 .13v.3a.53.53 0 010 .13 3.53 3.53 0 01-.08.58 2.65 2.65 0 01-.18.52 2.12 2.12 0 01-.28.43 1.36 1.36 0 01-.37.31.63.63 0 01-.37.11.4.4 0 01-.27-.11.64.64 0 01-.18-.3 1.71 1.71 0 01-.09-.49v-.56a2.8 2.8 0 01.09-.58 2.13 2.13 0 01.18-.52 2.62 2.62 0 01.27-.43 1.53 1.53 0 01.37-.31zm.56 1.52a3.13 3.13 0 000-.5.94.94 0 000-.3.47.47 0 00-.1-.2.25.25 0 00-.17-.08.41.41 0 00-.24.07.87.87 0 00-.23.2 1.24 1.24 0 00-.17.27 1.6 1.6 0 00-.11.33c0 .12 0 .24-.05.36v.5a2.34 2.34 0 00.05.3.48.48 0 00.11.2.23.23 0 00.17.08.38.38 0 00.23-.07.91.91 0 00.24-.2 1.78 1.78 0 00.17-.27 1.8 1.8 0 00.1-.33 1.49 1.49 0 000-.36z"
            style={{
              WebkitTransformOrigin: 195.202,
              MsTransformOrigin: 195.202,
              transformOrigin: 195.202,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M198.42 156.58l.43-2.23a.76.76 0 010-.11.18.18 0 01.09-.1l.13-.08h.06a.1.1 0 010 .08l-.58 3.06v.14a.18.18 0 01-.08.09l-.12.07h-.07a.36.36 0 01-.05-.09l-.45-1.7-.46 2.23v.14c0 .04 0 .08-.08.09l-.12.07h-.07a.19.19 0 010-.1l-.59-2.37a.5.5 0 010-.12.16.16 0 01.07-.07l.13-.08a.06.06 0 01.08 0 .18.18 0 01.05.07l.43 1.72.46-2.24a.61.61 0 010-.11.39.39 0 01.08-.1H197.93v.08z"
            style={{
              WebkitTransformOrigin: 197.797,
              MsTransformOrigin: 197.797,
              transformOrigin: 197.797,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M199.48 155.09a3.6 3.6 0 01.08-.56 3.82 3.82 0 01.18-.51 2.34 2.34 0 01.27-.44 1.43 1.43 0 01.35-.3.71.71 0 01.37-.11.36.36 0 01.27.14.85.85 0 01.18.37 2.49 2.49 0 01.06.59v.16a.35.35 0 010 .13.22.22 0 01-.07.09l-1.32.76a.74.74 0 00.16.53.33.33 0 00.38 0 1.07 1.07 0 00.31-.28 1.52 1.52 0 00.17-.3.64.64 0 01.06-.1.13.13 0 01.07 0l.11-.07a.06.06 0 01.08 0 .13.13 0 010 .1 1.35 1.35 0 01-.07.27 3 3 0 01-.15.32 1.64 1.64 0 01-.26.33 1.6 1.6 0 01-.35.28.57.57 0 01-.35.1.43.43 0 01-.27-.13 1 1 0 01-.18-.32 2.18 2.18 0 01-.08-.49 2.44 2.44 0 010-.56zm.34-.18l1.08-.62a.64.64 0 00-.15-.49c-.1-.09-.23-.08-.39 0a.6.6 0 00-.21.18 1.56 1.56 0 00-.17.25 1.21 1.21 0 00-.11.32 1.41 1.41 0 00-.05.35z"
            style={{
              WebkitTransformOrigin: 200.355,
              MsTransformOrigin: 200.355,
              transformOrigin: 200.355,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M202.72 152h.07a.13.13 0 010 .1v.19a.35.35 0 010 .13.22.22 0 01-.07.09l-.22.12a1 1 0 00-.44.94v1.72a.28.28 0 010 .13.15.15 0 01-.08.09l-.14.09a.06.06 0 01-.07 0 .12.12 0 010-.09v-2.76a.35.35 0 010-.13.22.22 0 01.07-.09l.14-.08a.06.06 0 01.08 0 .12.12 0 010 .09v.18a1.34 1.34 0 01.18-.36 1 1 0 01.28-.25z"
            style={{
              WebkitTransformOrigin: 202.281,
              MsTransformOrigin: 202.281,
              transformOrigin: 202.281,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M204.29 153.29a.24.24 0 000-.16s-.06 0-.12-.06a.79.79 0 00-.2 0 2 2 0 00-.29.07.4.4 0 01-.44-.09.76.76 0 01-.12-.47 1.47 1.47 0 010-.36 1.66 1.66 0 01.14-.37 1.5 1.5 0 01.23-.33 2.1 2.1 0 01.33-.26 1 1 0 01.33-.12.47.47 0 01.24 0 .29.29 0 01.14.13.32.32 0 01.05.19.21.21 0 010 .13.36.36 0 01-.08.09l-.12.07h-.06a.09.09 0 01-.06 0 .2.2 0 00-.15-.09.46.46 0 00-.27.09.81.81 0 00-.28.27.55.55 0 00-.11.34.32.32 0 000 .16.14.14 0 00.1.06.6.6 0 00.18 0l.28-.07a1.1 1.1 0 01.3 0 .36.36 0 01.29.29 1.27 1.27 0 010 .28 1.64 1.64 0 010 .37 1.61 1.61 0 01-.16.38 1.72 1.72 0 01-.25.35 1.27 1.27 0 01-.34.27.74.74 0 01-.33.12.49.49 0 01-.25 0 .34.34 0 01-.16-.13.33.33 0 01-.06-.19.21.21 0 010-.13.22.22 0 01.07-.09l.14-.08h.11a.22.22 0 00.17.11.56.56 0 00.28-.1.88.88 0 00.17-.12 1.15 1.15 0 00.14-.17.87.87 0 00.11-.18.68.68 0 00.05-.2z"
            style={{
              WebkitTransformOrigin: 203.825,
              MsTransformOrigin: 203.825,
              transformOrigin: 203.825,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M216.56 139.85a.1.1 0 01-.13 0 .22.22 0 01-.05-.17v-1.25l-2.09 1.2a.09.09 0 01-.13 0 .2.2 0 01-.06-.16v-.74a.87.87 0 01.05-.28c0-.09.06-.19.1-.29l2.06-5.16a.83.83 0 01.28-.42l.51-.3a.12.12 0 01.13 0 .22.22 0 01.06.17v4.15l.58-.33h.12a.2.2 0 01.06.16v.74a.49.49 0 01-.06.23.3.3 0 01-.12.16l-.58.33v1.26a.53.53 0 01-.06.23.32.32 0 01-.13.15zm-.18-2.73v-2.22l-1.16 2.88z"
            style={{
              WebkitTransformOrigin: 216.075,
              MsTransformOrigin: 216.075,
              transformOrigin: 216.075,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M218.93 131.49a.49.49 0 01.06-.23.3.3 0 01.12-.16l2.54-1.46a.09.09 0 01.13 0 .23.23 0 01.05.16v.74a.64.64 0 01-.05.23.36.36 0 01-.13.16l-1.9 1.09-.07 1.37a1.87 1.87 0 01.36-.34l.29-.19a1.74 1.74 0 01.71-.25.83.83 0 01.56.14 1.13 1.13 0 01.37.54 2.79 2.79 0 01.13.95 4.7 4.7 0 01-.14 1.22 4.62 4.62 0 01-.37 1 3 3 0 01-.57.77 2.94 2.94 0 01-.69.54 2 2 0 01-.79.3.79.79 0 01-.56-.14 1 1 0 01-.33-.46 2.43 2.43 0 01-.12-.7.37.37 0 01.05-.23.36.36 0 01.13-.16l.54-.31a.1.1 0 01.13 0 .2.2 0 01.07.13.69.69 0 00.13.32.3.3 0 00.2.12.41.41 0 00.26 0 1.53 1.53 0 00.29-.14 1.48 1.48 0 00.62-.68 2.13 2.13 0 00.24-1 .75.75 0 00-.24-.64c-.15-.12-.36-.1-.62 0a1.77 1.77 0 00-.32.23c-.07.08-.14.15-.19.22l-.13.19a.42.42 0 01-.16.13l-.62.36a.09.09 0 01-.13 0 .23.23 0 01-.05-.16z"
            style={{
              WebkitTransformOrigin: 220.315,
              MsTransformOrigin: 220.315,
              transformOrigin: 220.315,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M226.39 129.61v1.88a7.74 7.74 0 01-.12 1.19 4.72 4.72 0 01-.31 1.07 3.51 3.51 0 01-.55.91 3.12 3.12 0 01-.83.69 1.68 1.68 0 01-.84.27.73.73 0 01-.55-.27 1.72 1.72 0 01-.31-.72 7.28 7.28 0 01-.12-1v-.89-.91a11.61 11.61 0 01.12-1.2 5.2 5.2 0 01.31-1.08 3.93 3.93 0 01.55-.93 2.93 2.93 0 01.84-.71 1.67 1.67 0 01.83-.26.72.72 0 01.55.27 1.61 1.61 0 01.31.71 5.42 5.42 0 01.12.98zm-2.72 3.39a2.1 2.1 0 00.24 1c.13.2.35.21.67 0a1.54 1.54 0 00.67-.79 4.21 4.21 0 00.23-1.3v-.88-.86a2.06 2.06 0 00-.23-1c-.14-.2-.36-.21-.67 0a1.51 1.51 0 00-.67.8 4.18 4.18 0 00-.24 1.29v.88c0 .31-.01.58 0 .86z"
            style={{
              WebkitTransformOrigin: 224.575,
              MsTransformOrigin: 224.575,
              transformOrigin: 224.575,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M213.78 144.53a.06.06 0 01.08 0 .13.13 0 010 .1v.21a.31.31 0 010 .13.24.24 0 01-.08.09l-1.26.73v1.36l1.19-.68h.07a.13.13 0 010 .1v.21a.31.31 0 010 .13.22.22 0 01-.07.09l-1.19.68v1.55a.28.28 0 010 .13.17.17 0 01-.07.09l-.16.09h-.07a.13.13 0 010-.1v-3.81a.28.28 0 010-.13c0-.05 0-.08.07-.09z"
            style={{
              WebkitTransformOrigin: 213.04,
              MsTransformOrigin: 213.04,
              transformOrigin: 213.04,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M215.1 144.77a.65.65 0 01.37-.11.37.37 0 01.27.11.71.71 0 01.19.31 2.18 2.18 0 01.08.48v.56a2.9 2.9 0 01-.09.59 2.21 2.21 0 01-.18.51 1.73 1.73 0 01-.27.43 1.36 1.36 0 01-.37.31.76.76 0 01-.37.12.46.46 0 01-.28-.11.91.91 0 01-.18-.31 2.18 2.18 0 01-.08-.49v-.12a.76.76 0 010-.15.86.86 0 010-.16v-.13a3.85 3.85 0 01.08-.58 3.52 3.52 0 01.18-.52 2.12 2.12 0 01.28-.43 1.36 1.36 0 01.37-.31zm.56 1.52v-.25-.24a1.34 1.34 0 000-.31.55.55 0 00-.11-.2.21.21 0 00-.17-.08.4.4 0 00-.23.07.74.74 0 00-.23.2.88.88 0 00-.17.28 1.51 1.51 0 00-.11.32 2.23 2.23 0 00-.05.36 2.09 2.09 0 000 .26 1.77 1.77 0 000 .24 1.34 1.34 0 00.05.3.42.42 0 00.11.2.21.21 0 00.17.08.33.33 0 00.23-.07.63.63 0 00.23-.2 1 1 0 00.17-.27 1.6 1.6 0 00.11-.33 2.23 2.23 0 000-.36z"
            style={{
              WebkitTransformOrigin: 215.098,
              MsTransformOrigin: 215.098,
              transformOrigin: 215.098,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M216.85 146.74a.35.35 0 010 .13s0 .07-.08.08l-.14.09a.06.06 0 01-.07 0 .12.12 0 010-.09v-3.88a.31.31 0 010-.13.22.22 0 01.07-.09l.14-.08h.08a.15.15 0 010 .1z"
            style={{
              WebkitTransformOrigin: 216.705,
              MsTransformOrigin: 216.705,
              transformOrigin: 216.705,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M217.77 146.2a.28.28 0 010 .13.22.22 0 01-.07.09l-.14.08a.06.06 0 01-.08 0 .13.13 0 010-.1v-3.87a.28.28 0 010-.13.18.18 0 01.08-.09l.14-.08h.07a.13.13 0 010 .1z"
            style={{
              WebkitTransformOrigin: 217.625,
              MsTransformOrigin: 217.625,
              transformOrigin: 217.625,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M219.16 142.43a.76.76 0 01.37-.12.41.41 0 01.28.11.62.62 0 01.18.31 1.73 1.73 0 01.08.49.41.41 0 010 .12v.31a.41.41 0 010 .12 3.65 3.65 0 01-.08.59 2.18 2.18 0 01-.46.95 1.32 1.32 0 01-.37.3.73.73 0 01-.37.12.4.4 0 01-.27-.11.62.62 0 01-.18-.31 1.65 1.65 0 01-.09-.49v-.55a3 3 0 01.09-.59 2.28 2.28 0 01.18-.52 2.52 2.52 0 01.27-.42 1.53 1.53 0 01.37-.31zm.56 1.51a1.7 1.7 0 000-.24 1.85 1.85 0 000-.25.9.9 0 00-.05-.3.51.51 0 00-.1-.21.3.3 0 00-.17-.08.49.49 0 00-.24.07 1.1 1.1 0 00-.23.2 1.51 1.51 0 00-.28.61 3.48 3.48 0 00-.05.36v.49a1.86 1.86 0 00.05.3.52.52 0 00.11.21.27.27 0 00.17.08.46.46 0 00.23-.07.91.91 0 00.24-.2 1.89 1.89 0 00.17-.28 1.59 1.59 0 00.1-.33 1.36 1.36 0 00.05-.36z"
            style={{
              WebkitTransformOrigin: 219.162,
              MsTransformOrigin: 219.162,
              transformOrigin: 219.162,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M222.38 142.74l.43-2.22v-.12a.28.28 0 01.09-.1l.13-.07h.06a.12.12 0 010 .09v.05l-.59 3.06v.14a.18.18 0 01-.08.09l-.12.07h-.07a.36.36 0 010-.09l-.45-1.7-.46 2.23v.14a.18.18 0 01-.08.09l-.12.07h-.07a.3.3 0 010-.1l-.59-2.38a.5.5 0 010-.12.19.19 0 01.07-.08l.13-.07a.06.06 0 01.08 0 .13.13 0 01.05.07l.43 1.72.46-2.24a.7.7 0 010-.12.2.2 0 01.09-.09h.16a.25.25 0 010 .07z"
            style={{
              WebkitTransformOrigin: 221.778,
              MsTransformOrigin: 221.778,
              transformOrigin: 221.778,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M224 139.08a.61.61 0 010 .13.36.36 0 01-.08.09l-.22.13a.06.06 0 01-.07 0 .12.12 0 010-.09V139a.31.31 0 010-.13.22.22 0 01.07-.09l.22-.13a.08.08 0 01.08 0 .19.19 0 010 .09zm0 3.56a.31.31 0 010 .13.24.24 0 01-.08.09l-.14.08h-.07a.13.13 0 010-.1v-2.75a.31.31 0 010-.13.22.22 0 01.07-.09l.14-.08a.06.06 0 01.08 0 .13.13 0 010 .1z"
            style={{
              WebkitTransformOrigin: 223.816,
              MsTransformOrigin: 223.816,
              transformOrigin: 223.816,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M226.29 141.29a.38.38 0 010 .13.22.22 0 01-.07.09l-.14.08a.06.06 0 01-.07 0 .12.12 0 010-.09v-1.56a.85.85 0 00-.15-.6c-.09-.1-.22-.09-.39 0a1.09 1.09 0 00-.39.45 1.76 1.76 0 00-.15.77v1.56a.35.35 0 010 .13.22.22 0 01-.07.09l-.14.08a.06.06 0 01-.08 0 .12.12 0 010-.09v-2.75a.28.28 0 010-.13.15.15 0 01.08-.09l.14-.09a.06.06 0 01.07 0 .12.12 0 010 .09v.19a1.78 1.78 0 01.22-.4 1.07 1.07 0 01.38-.35.65.65 0 01.33-.1.36.36 0 01.26.11.65.65 0 01.17.32 1.87 1.87 0 01.07.52z"
            style={{
              WebkitTransformOrigin: 225.496,
              MsTransformOrigin: 225.496,
              transformOrigin: 225.496,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M226.75 139.45a2.59 2.59 0 01.08-.63 2.22 2.22 0 01.18-.55 2.25 2.25 0 01.27-.43 1 1 0 01.34-.29.47.47 0 01.38-.1.41.41 0 01.21.15v-.18a.31.31 0 010-.13.24.24 0 01.08-.09l.14-.08h.07a.15.15 0 010 .1v2.87a2.51 2.51 0 01-.23 1.11 1.58 1.58 0 01-.67.75 1.05 1.05 0 01-.4.15.54.54 0 01-.2-.1.38.38 0 01-.15-.2.55.55 0 01-.06-.26.35.35 0 010-.13.35.35 0 01.07-.09l.12-.06a.06.06 0 01.07 0 .17.17 0 01.06.09.41.41 0 000 .08.27.27 0 00.08.07.33.33 0 00.15 0 .88.88 0 00.23-.09 1.3 1.3 0 00.23-.18.71.71 0 00.19-.23 1.07 1.07 0 00.13-.31 1.61 1.61 0 000-.39v-.3a1.64 1.64 0 01-.22.41 1.19 1.19 0 01-.37.33.6.6 0 01-.34.1.37.37 0 01-.27-.12.75.75 0 01-.18-.33 1.71 1.71 0 01-.08-.54 1.15 1.15 0 010-.19 1.21 1.21 0 01.09-.21zm1.46-.87a.8.8 0 00-.16-.55.31.31 0 00-.39 0 1 1 0 00-.4.48 2 2 0 00-.16.77 1.82 1.82 0 000 .33.93.93 0 00.16.58c.09.11.23.11.4 0a1 1 0 00.39-.45 1.87 1.87 0 00.16-.74v-.22-.2z"
            style={{
              WebkitTransformOrigin: 227.582,
              MsTransformOrigin: 227.582,
              transformOrigin: 227.582,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M112.42 171.29a.7.7 0 01-.49-.19l-3-2.89a.7.7 0 01-.15-.8l3-6.36a.68.68 0 01.92-.33.69.69 0 01.34.92l-2.79 5.91 2.64 2.54a.7.7 0 010 1 .7.7 0 01-.47.2z"
            style={{
              WebkitTransformOrigin: 110.91,
              MsTransformOrigin: 110.91,
              transformOrigin: 110.91,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M149.81 146.06v.37a3.53 3.53 0 000 .36 1.6 1.6 0 00.09.5.54.54 0 00.19.26.34.34 0 00.24.06.51.51 0 00.28-.09 1.26 1.26 0 00.28-.2 1.07 1.07 0 00.2-.23c0-.09.1-.17.14-.26s.07-.19.11-.29l.09-.24a.4.4 0 01.13-.17l.51-.29a.12.12 0 01.14 0 .26.26 0 010 .17 3.44 3.44 0 01-.1.59 5.05 5.05 0 01-.3.77 4 4 0 01-.51.77 2.66 2.66 0 01-.74.63 1.17 1.17 0 01-.67.19.79.79 0 01-.51-.22 1.35 1.35 0 01-.35-.56 3.24 3.24 0 01-.15-.82v-.41-.43a4.4 4.4 0 01.15-1 5 5 0 01.34-1 4.24 4.24 0 01.52-.82 2.44 2.44 0 01.67-.58 1.38 1.38 0 01.74-.23.83.83 0 01.51.19 1.11 1.11 0 01.3.43 1.81 1.81 0 01.1.47.59.59 0 010 .22.38.38 0 01-.14.16l-.51.3a.09.09 0 01-.13 0l-.09-.13a.74.74 0 00-.11-.16.33.33 0 00-.14-.1.43.43 0 00-.2 0 1.1 1.1 0 00-.28.13 1.35 1.35 0 00-.28.22 1.8 1.8 0 00-.24.35 2.09 2.09 0 00-.19.48 2.7 2.7 0 00-.09.61z"
            style={{
              WebkitTransformOrigin: 150.552,
              MsTransformOrigin: 150.552,
              transformOrigin: 150.552,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M156.28 145.11a.5.5 0 01-.05.23.33.33 0 01-.13.15l-.51.3a.09.09 0 01-.13 0 .19.19 0 01-.05-.16V143a1.63 1.63 0 00-.19-.89c-.13-.18-.32-.19-.58 0a1.41 1.41 0 00-.57.7 3 3 0 00-.2 1.12v2.63a.46.46 0 01-.06.23.3.3 0 01-.12.16l-.52.29h-.12a.22.22 0 01-.05-.24v-6.8a.49.49 0 01.06-.23.3.3 0 01.12-.16l.52-.3a.1.1 0 01.12 0 .22.22 0 01.06.17v2.21a4.23 4.23 0 01.41-.68 1.77 1.77 0 01.56-.5 1.09 1.09 0 01.69-.2.63.63 0 01.44.27 1.65 1.65 0 01.24.64 4.8 4.8 0 01.07.89z"
            style={{
              WebkitTransformOrigin: 154.637,
              MsTransformOrigin: 154.637,
              transformOrigin: 154.637,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M158.82 139.77a1.36 1.36 0 00-.58.61 2.4 2.4 0 00-.18 1v2.7a.46.46 0 01-.06.22.31.31 0 01-.13.16l-.51.3a.1.1 0 01-.13 0 .26.26 0 010-.17v-4.84a.68.68 0 010-.23.39.39 0 01.13-.15l.51-.3a.1.1 0 01.13 0 .21.21 0 01.06.17v.23a3.42 3.42 0 01.37-.6 1.87 1.87 0 01.52-.43l.31-.18a.09.09 0 01.12 0 .22.22 0 01.06.17v.68a.46.46 0 01-.06.23.3.3 0 01-.12.16z"
            style={{
              WebkitTransformOrigin: 158.328,
              MsTransformOrigin: 158.328,
              transformOrigin: 158.328,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M160.73 135.45a.1.1 0 01.13 0 .25.25 0 010 .17v.81a.68.68 0 010 .23.39.39 0 01-.13.15l-.58.34a.12.12 0 01-.13 0 .22.22 0 01-.02-.15v-.81a.49.49 0 01.06-.23.28.28 0 01.13-.15zm.14 7a.5.5 0 01-.05.23.32.32 0 01-.13.15l-.51.3a.09.09 0 01-.13 0 .26.26 0 01-.05-.13v-4.83a.59.59 0 01.05-.23.36.36 0 01.13-.16l.51-.3a.12.12 0 01.13 0 .22.22 0 01.05.17z"
            style={{
              WebkitTransformOrigin: 160.435,
              MsTransformOrigin: 160.435,
              transformOrigin: 160.435,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M163.71 139.55a.24.24 0 000-.16.25.25 0 00-.17 0 1.1 1.1 0 00-.31.06l-.5.12a1 1 0 01-.49 0 .49.49 0 01-.32-.18.9.9 0 01-.16-.37 3 3 0 01-.05-.55 2.37 2.37 0 01.1-.67 2.91 2.91 0 01.27-.69 3 3 0 01.43-.63 2.48 2.48 0 01.59-.49 1.54 1.54 0 01.59-.22.78.78 0 01.44 0 .63.63 0 01.29.27 1.13 1.13 0 01.11.43.37.37 0 01-.05.22.36.36 0 01-.13.16l-.52.3q-.1.06-.15 0l-.11-.06a.29.29 0 00-.17 0 .74.74 0 00-.3.12 1.69 1.69 0 00-.36.3.64.64 0 00-.15.42.37.37 0 000 .17.18.18 0 00.15.06 1.3 1.3 0 00.29 0 4.14 4.14 0 00.48-.12.85.85 0 01.85.13 1.38 1.38 0 01.24.9 2.37 2.37 0 01-.1.67 3.24 3.24 0 01-.29.7 4.11 4.11 0 01-.47.65 2.57 2.57 0 01-.64.5 1.39 1.39 0 01-.63.23.76.76 0 01-.46-.07.65.65 0 01-.29-.3 1.06 1.06 0 01-.11-.44.49.49 0 01.06-.23.31.31 0 01.13-.16l.52-.3a.12.12 0 01.15 0l.11.1a.26.26 0 00.18.06.73.73 0 00.34-.13 1.28 1.28 0 00.21-.14.86.86 0 00.2-.18 1.37 1.37 0 00.15-.21.4.4 0 00.05-.27z"
            style={{
              WebkitTransformOrigin: 163.107,
              MsTransformOrigin: 163.107,
              transformOrigin: 163.107,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M166.49 137.54a.7.7 0 00.11.45c.08.09.22.07.42-.05l.41-.23a.09.09 0 01.13 0 .23.23 0 01.05.16v.69a.68.68 0 01-.05.23.39.39 0 01-.13.15l-.5.29c-.42.25-.75.27-1 .09s-.34-.6-.34-1.25v-2.23l-.45.25a.09.09 0 01-.13 0 .22.22 0 010-.16v-.69a.68.68 0 010-.23.39.39 0 01.13-.15l.45-.26v-1.69a.48.48 0 010-.23.36.36 0 01.13-.16l.51-.29a.09.09 0 01.13 0 .19.19 0 01.05.16v1.69l.87-.5a.1.1 0 01.13 0 .22.22 0 01.06.17v.68a.49.49 0 01-.06.23.31.31 0 01-.13.16l-.87.5z"
            style={{
              WebkitTransformOrigin: 166.303,
              MsTransformOrigin: 166.303,
              transformOrigin: 166.303,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M169 130.67a.1.1 0 01.13 0 .22.22 0 01.05.17v.81a.5.5 0 01-.05.23.39.39 0 01-.13.15l-.58.34a.1.1 0 01-.13 0 .26.26 0 010-.17v-.81a.68.68 0 010-.23.39.39 0 01.13-.15zm.15 7a.64.64 0 01-.05.23.33.33 0 01-.13.15l-.51.3a.09.09 0 01-.13 0 .2.2 0 01-.06-.16v-4.84a.46.46 0 01.06-.23.31.31 0 01.13-.16l.51-.29a.09.09 0 01.13 0 .22.22 0 01.05.16z"
            style={{
              WebkitTransformOrigin: 168.726,
              MsTransformOrigin: 168.726,
              transformOrigin: 168.726,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M173.37 135.24a.48.48 0 01-.05.23.36.36 0 01-.13.16l-.51.29a.09.09 0 01-.13 0 .19.19 0 01-.05-.16v-2.64a1.63 1.63 0 00-.19-.89c-.13-.17-.32-.19-.58 0a1.41 1.41 0 00-.57.7A3 3 0 00171 134v2.64a.46.46 0 01-.06.22.3.3 0 01-.12.16l-.52.3a.1.1 0 01-.12 0 .22.22 0 01-.06-.17v-4.85a.53.53 0 01.06-.23.31.31 0 01.12-.15l.52-.3h.12a.22.22 0 01.06.17v.21a4.23 4.23 0 01.41-.68 1.9 1.9 0 01.56-.5 1.15 1.15 0 01.69-.2.64.64 0 01.44.28 1.41 1.41 0 01.24.63 4.87 4.87 0 01.07.89z"
            style={{
              WebkitTransformOrigin: 171.765,
              MsTransformOrigin: 171.765,
              transformOrigin: 171.765,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M174.09 132.41a5.81 5.81 0 01.12-1.17 6.17 6.17 0 01.33-1.08 3.93 3.93 0 01.52-.9 2.32 2.32 0 01.69-.61 1 1 0 01.68-.18.79.79 0 01.52.26 1.6 1.6 0 01.34.65 3.43 3.43 0 01.12 1v.46a.46.46 0 01-.06.23.31.31 0 01-.13.16l-2.25 1.3a.81.81 0 00.07.38.41.41 0 00.17.21.34.34 0 00.25 0 .72.72 0 00.29-.1 1.41 1.41 0 00.35-.27 1.91 1.91 0 00.22-.3.9.9 0 01.12-.18.47.47 0 01.13-.11l.54-.31a.1.1 0 01.13 0 .19.19 0 010 .17 1.72 1.72 0 01-.1.44 3.79 3.79 0 01-.3.61 4.58 4.58 0 01-.48.66 2.89 2.89 0 01-.66.54 1.31 1.31 0 01-.69.19.75.75 0 01-.52-.28 1.78 1.78 0 01-.33-.69 4.21 4.21 0 01-.07-1.08zm1.66-2.52a1.34 1.34 0 00-.34.29 1.69 1.69 0 00-.23.36 2 2 0 00-.14.39 2 2 0 00-.07.37l1.53-.88c0-.1 0-.2-.05-.3a.55.55 0 00-.13-.24.29.29 0 00-.22-.1.63.63 0 00-.35.11z"
            style={{
              WebkitTransformOrigin: 175.746,
              MsTransformOrigin: 175.746,
              transformOrigin: 175.746,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M178.2 133.36a.09.09 0 01-.13 0 .21.21 0 01-.06-.17v-.68a.49.49 0 01.06-.23.31.31 0 01.13-.16l3.94-2.27a.1.1 0 01.13 0 .22.22 0 010 .17v.69a.5.5 0 010 .23.39.39 0 01-.13.15z"
            style={{
              WebkitTransformOrigin: 180.148,
              MsTransformOrigin: 180.148,
              transformOrigin: 180.148,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M185 128.9a1.2 1.2 0 01-.37.15.75.75 0 01-.29 0 .93.93 0 01-.22-.08l-.14-.12v2.21a.68.68 0 01-.05.23.39.39 0 01-.13.15l-.51.3a.1.1 0 01-.13 0s-.05-.09-.05-.17v-6.81a.5.5 0 01.05-.23.39.39 0 01.13-.15l.51-.3a.09.09 0 01.13 0 .25.25 0 01.05.17v.23c0-.08.09-.17.14-.28a2 2 0 01.22-.32 2.21 2.21 0 01.29-.33 2.11 2.11 0 01.37-.28.93.93 0 01.61-.15.75.75 0 01.47.28 1.54 1.54 0 01.3.6 3.52 3.52 0 01.13.84v.73a5.6 5.6 0 01-.13 1 4.88 4.88 0 01-.3 1 4 4 0 01-.47.81 2 2 0 01-.61.52zm.63-2.87a5.8 5.8 0 000-.59q0-.64-.27-.81a.48.48 0 00-.56 0 1.43 1.43 0 00-.56.62 2.59 2.59 0 00-.26 1v.79a1 1 0 00.26.7.46.46 0 00.56 0 1.49 1.49 0 00.56-.62 2.79 2.79 0 00.29-1.12z"
            style={{
              WebkitTransformOrigin: 184.81,
              MsTransformOrigin: 184.81,
              transformOrigin: 184.81,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M216.07 105.67c.57-.33 1-.06 1 .6a2.31 2.31 0 01-1 1.8c-.58.33-1 .07-1-.6a2.28 2.28 0 011-1.8z"
            style={{
              WebkitTransformOrigin: 216.07,
              MsTransformOrigin: 216.07,
              transformOrigin: 216.07,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M219.09 103.92c.57-.33 1-.06 1 .61a2.31 2.31 0 01-1 1.8c-.58.33-1 .06-1-.6a2.3 2.3 0 011-1.81z"
            style={{
              WebkitTransformOrigin: 219.09,
              MsTransformOrigin: 219.09,
              transformOrigin: 219.09,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M222.11 102.18c.57-.33 1-.06 1 .6a2.31 2.31 0 01-1 1.8c-.58.34-1 .07-1-.6a2.28 2.28 0 011-1.8z"
            style={{
              WebkitTransformOrigin: 222.11,
              MsTransformOrigin: 222.11,
              transformOrigin: 222.11,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M196 176l25.86-15c.72-.42 1.3-.08 1.3.75v.25a2.86 2.86 0 01-1.3 2.25L196 179.19c-.72.41-1.3.08-1.3-.75v-.24a2.87 2.87 0 011.3-2.2z"
            style={{
              WebkitTransformOrigin: 208.93,
              MsTransformOrigin: 208.93,
              transformOrigin: 208.93,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M191.32 184.81l35.25-20.38c.72-.41 1.3-.08 1.3.75v.24a2.87 2.87 0 01-1.3 2.25l-35.25 20.38c-.72.41-1.3.07-1.3-.75v-.24a2.86 2.86 0 011.3-2.25z"
            style={{
              WebkitTransformOrigin: 208.945,
              MsTransformOrigin: 208.945,
              transformOrigin: 208.945,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M196 188.2l25.83-14.91c.72-.42 1.3-.08 1.3.75v.23a2.86 2.86 0 01-1.3 2.25L196 191.44c-.72.41-1.3.08-1.3-.75v-.24a2.87 2.87 0 011.3-2.25z"
            style={{
              WebkitTransformOrigin: 208.915,
              MsTransformOrigin: 208.915,
              transformOrigin: 208.915,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M191.32 197.06l35.25-20.38c.72-.41 1.3-.08 1.3.75v.24a2.89 2.89 0 01-1.3 2.25l-35.25 20.37c-.72.42-1.3.08-1.3-.75v-.23a2.86 2.86 0 011.3-2.25z"
            style={{
              WebkitTransformOrigin: 208.945,
              MsTransformOrigin: 208.945,
              transformOrigin: 208.945,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M210.94 217.24a1.84 1.84 0 01-.94-.24 2.31 2.31 0 01-1-2.12v-8.07a6.2 6.2 0 012.85-4.93l7.93-4.58a2.07 2.07 0 013.34 1.93v8.07a6.22 6.22 0 01-2.84 4.94l-7.93 4.57a2.79 2.79 0 01-1.41.43zm10.25-19.36a1.85 1.85 0 00-.91.29l-7.93 4.57a5.3 5.3 0 00-2.35 4.07v8.07a1.1 1.1 0 001.85 1.07l7.93-4.58a5.27 5.27 0 002.34-4.07v-8.07a1.38 1.38 0 00-.51-1.25.82.82 0 00-.42-.1z"
            style={{
              WebkitTransformOrigin: 216.067,
              MsTransformOrigin: 216.067,
              transformOrigin: 216.067,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M216 209.43a.35.35 0 01-.24-.1l-2.25-2.17a.34.34 0 010-.49.34.34 0 01.48 0l1.91 1.84 2.05-4.33a.34.34 0 01.46-.17.35.35 0 01.16.46l-2.25 4.77a.35.35 0 01-.25.19z"
            style={{
              WebkitTransformOrigin: 216.004,
              MsTransformOrigin: 216.004,
              transformOrigin: 216.004,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M163.66 244.54a1.75 1.75 0 01-.93-.25 2.28 2.28 0 01-1-2.11v-8.07a6.22 6.22 0 012.85-4.94l31.36-18.1a2.07 2.07 0 013.35 1.93v8.07a6.19 6.19 0 01-2.85 4.93l-31.36 18.11a2.9 2.9 0 01-1.42.43zm1.41-14.5a5.26 5.26 0 00-2.35 4.07v8.07a1.1 1.1 0 001.85 1.07l31.36-18.11a5.3 5.3 0 002.35-4.07V213a1.1 1.1 0 00-1.85-1.07z"
            style={{
              WebkitTransformOrigin: 180.518,
              MsTransformOrigin: 180.518,
              transformOrigin: 180.518,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M172.82 231.6l.86-2.81a1.13 1.13 0 00.07-.15.32.32 0 01.13-.15l.31-.18a.08.08 0 01.09 0 .16.16 0 010 .11V233a.5.5 0 010 .16.3.3 0 01-.09.1l-.37.21a.07.07 0 01-.09 0 .18.18 0 010-.11v-2.74l-.59 1.92-.07.15a.29.29 0 01-.1.12l-.24.13h-.1a.46.46 0 01-.07-.07l-.59-1.24v2.74a.4.4 0 010 .15.26.26 0 01-.09.11l-.37.21a.06.06 0 01-.08 0 .16.16 0 010-.12v-4.52a.28.28 0 010-.15.24.24 0 01.08-.11l.31-.18h.14a.81.81 0 00.06.08z"
            style={{
              WebkitTransformOrigin: 172.854,
              MsTransformOrigin: 172.854,
              transformOrigin: 172.854,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M174.86 231a4.13 4.13 0 01.08-.78 3.44 3.44 0 01.22-.73 3.25 3.25 0 01.35-.61 1.8 1.8 0 01.47-.41.71.71 0 01.46-.12.5.5 0 01.35.18 1 1 0 01.22.44 2 2 0 01.08.63v.32a.4.4 0 010 .15.35.35 0 01-.09.11l-1.52.87a.6.6 0 00.05.26.23.23 0 00.11.14.25.25 0 00.17 0 .6.6 0 00.2-.07 1.54 1.54 0 00.23-.18l.15-.2.08-.13a.71.71 0 01.09-.07l.36-.21h.09a.12.12 0 010 .11 1.92 1.92 0 01-.07.29 2.62 2.62 0 01-.2.42 3.15 3.15 0 01-.33.44 1.92 1.92 0 01-.44.36.82.82 0 01-.47.13.52.52 0 01-.35-.18 1.13 1.13 0 01-.22-.48 3 3 0 01-.07-.68zm1.12-1.69a.71.71 0 00-.23.19l-.16.24a1.43 1.43 0 00-.09.27 1.23 1.23 0 00-.05.25l1-.6v-.2a.35.35 0 00-.09-.16.21.21 0 00-.15-.07.42.42 0 00-.21.08z"
            style={{
              WebkitTransformOrigin: 175.978,
              MsTransformOrigin: 175.978,
              transformOrigin: 175.978,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M178.88 229.46a.18.18 0 000-.11.15.15 0 00-.12 0 .68.68 0 00-.21 0l-.33.08a1 1 0 01-.34 0 .39.39 0 01-.21-.13.53.53 0 01-.11-.25 2.05 2.05 0 010-.37 1.86 1.86 0 01.06-.45 2.72 2.72 0 01.19-.47 2 2 0 01.29-.42 1.57 1.57 0 01.4-.33 1.07 1.07 0 01.39-.15.48.48 0 01.3 0 .41.41 0 01.19.18.66.66 0 01.08.29.4.4 0 010 .15.35.35 0 01-.09.11l-.35.2h-.29a.48.48 0 00-.2.08.68.68 0 00-.24.2.41.41 0 00-.11.29.16.16 0 000 .11H178.47l.32-.08a.55.55 0 01.57.08.93.93 0 01.17.61 1.5 1.5 0 01-.07.45 2.14 2.14 0 01-.2.47 1.9 1.9 0 01-.32.44 1.74 1.74 0 01-.42.34 1.06 1.06 0 01-.43.15.52.52 0 01-.31 0 .43.43 0 01-.19-.21.7.7 0 01-.08-.3.31.31 0 010-.15.2.2 0 01.09-.11l.35-.2h.1l.08.07a.15.15 0 00.12 0 .48.48 0 00.23-.08l.14-.1.13-.12a1.34 1.34 0 00.11-.14.53.53 0 00.02-.13z"
            style={{
              WebkitTransformOrigin: 178.517,
              MsTransformOrigin: 178.517,
              transformOrigin: 178.517,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M181.24 228.1a.11.11 0 00-.14-.14h-.21l-.34.08a.77.77 0 01-.33 0 .37.37 0 01-.22-.04.63.63 0 01-.12-.26 2 2 0 010-.37 1.5 1.5 0 01.07-.45 2 2 0 01.18-.46 2.49 2.49 0 01.29-.43 1.52 1.52 0 01.4-.32 1.15 1.15 0 01.4-.16.67.67 0 01.29 0 .44.44 0 01.2.18.69.69 0 01.07.29.21.21 0 010 .15.26.26 0 01-.09.11l-.35.2h-.29a.4.4 0 00-.2.08.91.91 0 00-.24.2.41.41 0 00-.11.28.21.21 0 000 .12.13.13 0 00.1 0h.19l.32-.08a.55.55 0 01.58.09.91.91 0 01.16.61 1.5 1.5 0 01-.07.45 2.57 2.57 0 01-.19.47 2.67 2.67 0 01-.32.43 1.74 1.74 0 01-.43.34 1 1 0 01-.42.16.5.5 0 01-.31-.05.43.43 0 01-.2-.2.6.6 0 01-.07-.3.27.27 0 010-.15.26.26 0 01.09-.11l.35-.2h.1l.08.06a.22.22 0 00.12 0 .52.52 0 00.23-.09 1.1 1.1 0 00.14-.09.86.86 0 00.14-.13.71.71 0 00.1-.14.28.28 0 00.05-.13z"
            style={{
              WebkitTransformOrigin: 180.842,
              MsTransformOrigin: 180.842,
              transformOrigin: 180.842,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M182.32 225.77a2.18 2.18 0 01.08-.36 2.6 2.6 0 01.19-.42 2.34 2.34 0 01.29-.41 1.86 1.86 0 01.41-.33 1.07 1.07 0 01.42-.15.45.45 0 01.32.09.66.66 0 01.21.34 2 2 0 01.07.59v2.06a.28.28 0 010 .15.2.2 0 01-.08.11l-.35.2a.05.05 0 01-.08 0 .12.12 0 010-.11v-.23a2.15 2.15 0 01-.28.51 1.44 1.44 0 01-.45.4.7.7 0 01-.35.12.37.37 0 01-.26-.07.53.53 0 01-.16-.26 1.16 1.16 0 01-.06-.4 1.81 1.81 0 01.22-.89 2.67 2.67 0 01.6-.71l.74-.62c0-.2 0-.31-.12-.34a.37.37 0 00-.31.06.65.65 0 00-.18.15 1.76 1.76 0 00-.12.2.54.54 0 01-.07.12l-.08.07-.42.24a.06.06 0 01-.08 0s-.11-.06-.1-.11zm.76 1.54a1 1 0 00.27-.23 1.37 1.37 0 00.2-.29 1.44 1.44 0 00.13-.32 1.2 1.2 0 000-.31v-.07l-.62.52a1.43 1.43 0 00-.27.3.61.61 0 00-.08.3c0 .1 0 .15.1.16a.38.38 0 00.27-.06z"
            style={{
              WebkitTransformOrigin: 183.28,
              MsTransformOrigin: 183.28,
              transformOrigin: 183.28,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M186 227.12a.83.83 0 00.19-.15 1.14 1.14 0 00.18-.25 1.64 1.64 0 00.14-.33 1.58 1.58 0 000-.38v-.26a1.82 1.82 0 01-.1.19 1.13 1.13 0 01-.14.22 1.86 1.86 0 01-.19.22 1.2 1.2 0 01-.26.18.61.61 0 01-.41.11.54.54 0 01-.31-.19 1 1 0 01-.21-.4 2.46 2.46 0 01-.08-.58v-.5a3.83 3.83 0 01.08-.66 3.3 3.3 0 01.21-.65 2.82 2.82 0 01.31-.54 1.61 1.61 0 01.41-.38 1.24 1.24 0 01.26-.1.64.64 0 01.19 0 .26.26 0 01.14.06.27.27 0 01.1.08v-.16a.32.32 0 010-.16.21.21 0 01.08-.1l.35-.2a.07.07 0 01.09 0 .18.18 0 010 .11v3.29a3.1 3.1 0 01-.31 1.45 2 2 0 01-.84.92 1.27 1.27 0 01-.43.16.65.65 0 01-.35-.06.6.6 0 01-.23-.22.68.68 0 01-.09-.35.24.24 0 010-.16.3.3 0 01.09-.1l.31-.18a.08.08 0 01.11 0l.07.08a.27.27 0 00.17.1.56.56 0 00.47-.11zm-.55-2.4a2.54 2.54 0 000 .39c0 .3.08.48.18.55a.33.33 0 00.38 0 1.12 1.12 0 00.38-.42 1.67 1.67 0 00.17-.69 2.1 2.1 0 000-.27 1.93 1.93 0 000-.26.62.62 0 00-.17-.47.32.32 0 00-.38 0 1 1 0 00-.38.41 2 2 0 00-.23.76z"
            style={{
              WebkitTransformOrigin: 185.902,
              MsTransformOrigin: 185.902,
              transformOrigin: 185.902,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M187.61 223.64a4.13 4.13 0 01.08-.78 3.44 3.44 0 01.22-.73 2.78 2.78 0 01.35-.61 1.76 1.76 0 01.46-.41.74.74 0 01.47-.12.52.52 0 01.35.18 1.07 1.07 0 01.22.44 2 2 0 01.08.63v.32a.4.4 0 010 .15.35.35 0 01-.09.11l-1.52.87a.83.83 0 000 .26.28.28 0 00.12.14.25.25 0 00.17 0 .49.49 0 00.19-.07 1.25 1.25 0 00.24-.18 2.08 2.08 0 00.15-.2 1.05 1.05 0 01.08-.13.22.22 0 01.09-.07l.36-.21h.09a.12.12 0 010 .11 1.92 1.92 0 01-.07.29 2.55 2.55 0 01-.53.86 2 2 0 01-.45.36.79.79 0 01-.46.13.48.48 0 01-.35-.19 1 1 0 01-.22-.47 3 3 0 01-.03-.68zm1.11-1.69a.79.79 0 00-.22.19 1.67 1.67 0 00-.16.24c0 .09-.07.18-.1.27s0 .17 0 .25l1-.6v-.2a.35.35 0 00-.09-.16.21.21 0 00-.15-.07.48.48 0 00-.28.13z"
            style={{
              WebkitTransformOrigin: 188.725,
              MsTransformOrigin: 188.725,
              transformOrigin: 188.725,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M117.54 256.9l31.36-18.1c1.43-.83 2.6-.16 2.6 1.5v8.07a5.76 5.76 0 01-2.6 4.5L117.54 271c-1.44.82-2.6.15-2.6-1.5v-8.1a5.74 5.74 0 012.6-4.5z"
            style={{
              WebkitTransformOrigin: 133.22,
              MsTransformOrigin: 133.22,
              transformOrigin: 133.22,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M121.15 258.11h.12c.03 0 0 .08 0 .16v1a.41.41 0 010 .21.31.31 0 01-.12.15l-1.91 1.1v1.35l1.78-1a.1.1 0 01.13 0 .22.22 0 010 .16v1a.54.54 0 010 .21.39.39 0 01-.13.15l-1.78 1v2.12a.47.47 0 010 .22.24.24 0 01-.12.14l-.83.49a.1.1 0 01-.12 0 .19.19 0 01-.05-.16v-6.34a.42.42 0 01.05-.22.31.31 0 01.12-.15z"
            style={{
              WebkitTransformOrigin: 119.701,
              MsTransformOrigin: 119.701,
              transformOrigin: 119.701,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M125.21 259.72v.75a4.73 4.73 0 01-.14 1 4.85 4.85 0 01-.35.92 4 4 0 01-.54.8 2.78 2.78 0 01-.71.58 1.41 1.41 0 01-.7.23.83.83 0 01-.54-.17 1.26 1.26 0 01-.35-.52 2.44 2.44 0 01-.14-.81v-.37-.38a4 4 0 01.13-1 4.72 4.72 0 01.36-.93 4 4 0 01.54-.8 2.75 2.75 0 01.7-.59 1.47 1.47 0 01.71-.23.84.84 0 01.54.18 1.11 1.11 0 01.35.52 2.44 2.44 0 01.14.82zm-2.32 2a.68.68 0 00.17.49c.11.08.24.07.41 0a1.15 1.15 0 00.42-.45 1.67 1.67 0 00.17-.69v-.34a3.11 3.11 0 000-.32.66.66 0 00-.17-.49.37.37 0 00-.42 0 1.13 1.13 0 00-.41.45 1.66 1.66 0 00-.17.68v.35a2.88 2.88 0 000 .36z"
            style={{
              WebkitTransformOrigin: 123.475,
              MsTransformOrigin: 123.475,
              transformOrigin: 123.475,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M127 261.35a.54.54 0 010 .21.32.32 0 01-.12.15l-.81.47a.11.11 0 01-.12 0 .23.23 0 010-.16v-6.44a.63.63 0 010-.22.35.35 0 01.12-.14l.81-.47a.09.09 0 01.12 0 .22.22 0 010 .16z"
            style={{
              WebkitTransformOrigin: 126.475,
              MsTransformOrigin: 126.475,
              transformOrigin: 126.475,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M128.91 260.24a.59.59 0 010 .22.49.49 0 01-.12.15l-.81.46a.09.09 0 01-.12 0 .23.23 0 010-.16v-6.44a.63.63 0 010-.22.35.35 0 01.12-.14l.81-.47a.11.11 0 01.12 0 .23.23 0 010 .16z"
            style={{
              WebkitTransformOrigin: 128.385,
              MsTransformOrigin: 128.385,
              transformOrigin: 128.385,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M133 255.21v.76a4 4 0 01-.14 1 5 5 0 01-.35.93 3.94 3.94 0 01-.54.79 2.78 2.78 0 01-.7.59 1.59 1.59 0 01-.71.23.84.84 0 01-.54-.18 1.19 1.19 0 01-.35-.52 2.61 2.61 0 01-.14-.8v-.37-.39a4.1 4.1 0 01.13-1 4.88 4.88 0 01.36-.92 4.08 4.08 0 01.54-.81 2.83 2.83 0 01.71-.59 1.41 1.41 0 01.7-.22.84.84 0 01.54.18 1.11 1.11 0 01.35.52 2.34 2.34 0 01.14.8zm-2.32 2a.63.63 0 00.17.48.38.38 0 00.42 0 1.13 1.13 0 00.41-.45 1.71 1.71 0 00.17-.69v-.34a3.25 3.25 0 000-.33.63.63 0 00-.17-.48c-.11-.08-.24-.07-.41 0a1.05 1.05 0 00-.42.44 1.75 1.75 0 00-.17.69v.34a3.22 3.22 0 00.01.39z"
            style={{
              WebkitTransformOrigin: 131.265,
              MsTransformOrigin: 131.265,
              transformOrigin: 131.265,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M136.22 251.18a.12.12 0 01.15 0 .47.47 0 01.07.12l.47 2 .47-2.51a.89.89 0 01.07-.19.31.31 0 01.15-.17l.79-.45a.08.08 0 01.11 0 .2.2 0 010 .14.28.28 0 010 .09l-1 5.13a.6.6 0 01-.07.2.39.39 0 01-.14.17l-.73.42h-.14a.54.54 0 01-.07-.13l-.45-1.8-.45 2.32a1.72 1.72 0 01-.07.21.36.36 0 01-.14.16l-.73.42h-.14a.24.24 0 01-.07-.13l-1-4v-.08a.43.43 0 010-.19.25.25 0 01.11-.13l.79-.46h.15a.37.37 0 01.07.12l.47 2 .47-2.5c0-.06 0-.13.07-.2a.39.39 0 01.15-.16z"
            style={{
              WebkitTransformOrigin: 135.901,
              MsTransformOrigin: 135.901,
              transformOrigin: 135.901,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M140.23 248.06a.41.41 0 010 .21.31.31 0 01-.12.15l-.8.47a.1.1 0 01-.12 0 .21.21 0 01-.06-.16v-1a.43.43 0 01.06-.22.31.31 0 01.12-.15l.8-.46h.12a.17.17 0 010 .15zm0 5.64a.42.42 0 010 .22.31.31 0 01-.12.15l-.8.46h-.12a.18.18 0 01-.06-.15v-4.58a.49.49 0 01.06-.22.24.24 0 01.12-.14l.8-.47a.09.09 0 01.12 0v.16z"
            style={{
              WebkitTransformOrigin: 139.688,
              MsTransformOrigin: 139.688,
              transformOrigin: 139.688,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M144.4 251.29a.42.42 0 01-.05.22.31.31 0 01-.12.15l-.8.46h-.12a.21.21 0 01-.05-.16v-2.49a1 1 0 00-.14-.6c-.09-.12-.23-.12-.42 0a1 1 0 00-.42.5 2.18 2.18 0 00-.13.75v2.48a.54.54 0 01-.05.22.31.31 0 01-.12.15l-.8.46a.1.1 0 01-.13 0 .25.25 0 01-.05-.16v-4.58a.54.54 0 01.05-.21.28.28 0 01.13-.15l.77-.45a.09.09 0 01.12 0 .23.23 0 01.05.16v.22c0-.08.08-.18.13-.28a2.38 2.38 0 01.18-.3 3.15 3.15 0 01.25-.28 2.3 2.3 0 01.34-.25 1.38 1.38 0 01.56-.19.55.55 0 01.44.16 1.15 1.15 0 01.28.53 3.51 3.51 0 01.1.94z"
            style={{
              WebkitTransformOrigin: 142.701,
              MsTransformOrigin: 142.701,
              transformOrigin: 142.701,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M146.72 250.91a1.31 1.31 0 00.43-.42 1.1 1.1 0 00.18-.62v-.69l-.12.29a3 3 0 01-.17.28 1.94 1.94 0 01-.24.27 1.66 1.66 0 01-.36.26 1.23 1.23 0 01-.54.18.58.58 0 01-.44-.15 1 1 0 01-.31-.51 2.66 2.66 0 01-.13-.89v-.35-.35a4.31 4.31 0 01.13-1.05 4 4 0 01.31-.87 2.81 2.81 0 01.44-.66 2.23 2.23 0 01.54-.45 1.3 1.3 0 01.37-.15.58.58 0 01.26 0 .51.51 0 01.18.09.6.6 0 01.11.12V245a.63.63 0 010-.22.39.39 0 01.13-.15l.74-.42a.08.08 0 01.12 0 .21.21 0 010 .15v4.79a4.42 4.42 0 01-.13 1 4 4 0 01-.36.9 3.47 3.47 0 01-.55.74 3.64 3.64 0 01-.7.54 1.68 1.68 0 01-.82.28.75.75 0 01-.49-.18.89.89 0 01-.25-.42 2.12 2.12 0 01-.08-.46.47.47 0 01.05-.22.39.39 0 01.13-.15l.82-.48c.09 0 .16 0 .2.08a.29.29 0 00.14.15c.16.07.26.07.41-.02zm-.56-3.26a2.45 2.45 0 000 .25 2 2 0 000 .23.76.76 0 00.15.48c.1.1.23.09.42 0a1 1 0 00.4-.46 1.94 1.94 0 00.17-.6 3.21 3.21 0 000-.62.59.59 0 00-.17-.4c-.09-.09-.22-.09-.4 0a1 1 0 00-.41.46 1.6 1.6 0 00-.16.66z"
            style={{
              WebkitTransformOrigin: 146.667,
              MsTransformOrigin: 146.667,
              transformOrigin: 146.667,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M145.07 280.74a4.52 4.52 0 01-2.27-.59c-1.63-.94-2.53-2.89-2.53-5.47 0-5.13 3.61-11.39 8-14 2.25-1.29 4.38-1.49 6-.54s2.53 2.88 2.53 5.47c0 5.13-3.61 11.39-8.05 14a7.55 7.55 0 01-3.68 1.13zm7-20.19a6.59 6.59 0 00-3.24 1 16.75 16.75 0 00-7.58 13.12c0 2.23.73 3.88 2.05 4.64s3.11.57 5-.55a16.77 16.77 0 007.58-13.12c0-2.23-.73-3.88-2.05-4.64a3.5 3.5 0 00-1.78-.45z"
            style={{
              WebkitTransformOrigin: 148.535,
              MsTransformOrigin: 148.535,
              transformOrigin: 148.535,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M148.93 263c3.29-1.8 5.79-.08 5.58 3.86s-3 8.58-6.31 10.39-5.79.07-5.58-3.86 3.03-8.55 6.31-10.39z"
            style={{
              WebkitTransformOrigin: 148.565,
              MsTransformOrigin: 148.565,
              transformOrigin: 148.565,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M148.5 265.75c.84-.49 1.53-.1 1.53.88a3.39 3.39 0 01-1.53 2.65c-.85.49-1.53.09-1.53-.88a3.35 3.35 0 011.53-2.65z"
            style={{
              WebkitTransformOrigin: 148.5,
              MsTransformOrigin: 148.5,
              transformOrigin: 148.5,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M151.51 271.33c0-1.6-1.12-2.31-2.51-1.51l-.87.5a5.55 5.55 0 00-2.51 4.36v.43s.59 1.22 2.95-.17a6.6 6.6 0 002.94-3.23z"
            style={{
              WebkitTransformOrigin: 148.565,
              MsTransformOrigin: 148.565,
              transformOrigin: 148.565,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M166.76 268.16a4.52 4.52 0 01-2.28-.58c-1.63-.95-2.53-2.89-2.53-5.48 0-5.13 3.62-11.39 8.06-14 2.25-1.3 4.38-1.49 6-.55s2.53 2.89 2.53 5.48c0 5.12-3.62 11.38-8.06 13.95a7.63 7.63 0 01-3.72 1.18zm7-20.18a6.6 6.6 0 00-3.25 1 16.76 16.76 0 00-7.57 13.12c0 2.23.72 3.88 2 4.64s3.12.57 5-.54a16.78 16.78 0 007.58-13.12c0-2.24-.73-3.88-2.05-4.65a3.62 3.62 0 00-1.73-.43z"
            style={{
              WebkitTransformOrigin: 170.245,
              MsTransformOrigin: 170.245,
              transformOrigin: 170.245,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M170.62 250.46c3.28-1.8 5.78-.07 5.58 3.86s-3 8.59-6.32 10.39-5.78.08-5.58-3.86 3.03-8.58 6.32-10.39z"
            style={{
              WebkitTransformOrigin: 170.25,
              MsTransformOrigin: 170.25,
              transformOrigin: 170.25,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M170.18 253.17c.85-.49 1.53-.09 1.53.88a3.35 3.35 0 01-1.53 2.65c-.84.49-1.53.1-1.53-.88a3.41 3.41 0 011.53-2.65z"
            style={{
              WebkitTransformOrigin: 170.18,
              MsTransformOrigin: 170.18,
              transformOrigin: 170.18,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M173.2 258.76c0-1.6-1.13-2.32-2.52-1.51l-.86.5a5.55 5.55 0 00-2.52 4.35v.43s.6 1.22 2.95-.17a6.55 6.55 0 002.95-3.23z"
            style={{
              WebkitTransformOrigin: 170.25,
              MsTransformOrigin: 170.25,
              transformOrigin: 170.25,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M188.44 255.59a4.52 4.52 0 01-2.27-.59c-1.63-.94-2.53-2.89-2.53-5.47 0-5.13 3.61-11.39 8.06-14 2.24-1.29 4.37-1.49 6-.54s2.53 2.88 2.53 5.47c0 5.13-3.61 11.39-8 13.95a7.55 7.55 0 01-3.79 1.18zm7-20.19a6.59 6.59 0 00-3.24 1 16.75 16.75 0 00-7.58 13.12c0 2.23.73 3.87 2.05 4.64s3.11.57 5-.55a16.78 16.78 0 007.57-13.12c0-2.23-.73-3.88-2.05-4.64a3.5 3.5 0 00-1.77-.45z"
            style={{
              WebkitTransformOrigin: 191.935,
              MsTransformOrigin: 191.935,
              transformOrigin: 191.935,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M192.3 237.89c3.29-1.81 5.79-.08 5.58 3.86s-3 8.58-6.31 10.39-5.79.07-5.58-3.86 3.01-8.59 6.31-10.39z"
            style={{
              WebkitTransformOrigin: 191.935,
              MsTransformOrigin: 191.935,
              transformOrigin: 191.935,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M191.87 240.6c.84-.49 1.53-.1 1.53.88a3.41 3.41 0 01-1.53 2.65c-.85.49-1.53.09-1.53-.88a3.35 3.35 0 011.53-2.65z"
            style={{
              WebkitTransformOrigin: 191.87,
              MsTransformOrigin: 191.87,
              transformOrigin: 191.87,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M194.88 246.18c0-1.6-1.12-2.31-2.51-1.51l-.87.5a5.55 5.55 0 00-2.51 4.36v.47s.59 1.22 2.95-.18a6.56 6.56 0 002.94-3.22z"
            style={{
              WebkitTransformOrigin: 191.935,
              MsTransformOrigin: 191.935,
              transformOrigin: 191.935,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M210.13 243a4.43 4.43 0 01-2.28-.59c-1.63-.94-2.53-2.88-2.53-5.47 0-5.13 3.62-11.39 8.06-13.95 2.25-1.3 4.38-1.49 6-.55s2.53 2.89 2.53 5.48c0 5.12-3.62 11.38-8.06 13.95a7.63 7.63 0 01-3.72 1.13zm7-20.18a6.6 6.6 0 00-3.25 1 16.76 16.76 0 00-7.59 13.18c0 2.23.72 3.88 2 4.64s3.12.57 5-.55a16.75 16.75 0 007.71-13.16c0-2.24-.73-3.88-2.05-4.65a3.62 3.62 0 00-1.84-.45z"
            style={{
              WebkitTransformOrigin: 213.615,
              MsTransformOrigin: 213.615,
              transformOrigin: 213.615,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M214 225.31c3.28-1.8 5.78-.07 5.58 3.86s-3 8.59-6.32 10.39-5.78.08-5.58-3.86 3.02-8.58 6.32-10.39z"
            style={{
              WebkitTransformOrigin: 213.63,
              MsTransformOrigin: 213.63,
              transformOrigin: 213.63,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M213.55 228c.85-.49 1.53-.09 1.53.88a3.35 3.35 0 01-1.53 2.65c-.84.49-1.53.1-1.53-.88a3.39 3.39 0 011.53-2.65z"
            style={{
              WebkitTransformOrigin: 213.55,
              MsTransformOrigin: 213.55,
              transformOrigin: 213.55,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M216.57 233.61c0-1.61-1.13-2.32-2.51-1.52l-.87.51a5.55 5.55 0 00-2.52 4.35v.43s.6 1.22 3-.17a6.55 6.55 0 002.95-3.23z"
            style={{
              WebkitTransformOrigin: 213.645,
              MsTransformOrigin: 213.645,
              transformOrigin: 213.645,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M123.2 293.32a4.49 4.49 0 01-2.27-.59c-1.63-.94-2.53-2.89-2.53-5.48 0-5.13 3.61-11.39 8-13.95 2.25-1.3 4.38-1.49 6-.55s2.53 2.89 2.53 5.48c0 5.13-3.61 11.39-8.06 13.95a7.59 7.59 0 01-3.67 1.14zm7-20.19a6.63 6.63 0 00-3.25 1 16.78 16.78 0 00-7.57 13.12c0 2.23.73 3.88 2 4.64s3.11.57 5-.54a16.77 16.77 0 007.62-13.12c0-2.23-.73-3.88-2.05-4.64a3.52 3.52 0 00-1.77-.46z"
            style={{
              WebkitTransformOrigin: 126.665,
              MsTransformOrigin: 126.665,
              transformOrigin: 126.665,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M127.06 275.62c3.29-1.81 5.79-.08 5.58 3.85s-3 8.59-6.31 10.39-5.79.08-5.58-3.85 3.03-8.59 6.31-10.39z"
            style={{
              WebkitTransformOrigin: 126.695,
              MsTransformOrigin: 126.695,
              transformOrigin: 126.695,
            }}
            fill="#EBEBEB"
            className="animable"
          ></path>
          <path
            d="M126.63 278.32c.84-.49 1.53-.09 1.53.89a3.41 3.41 0 01-1.53 2.65c-.85.48-1.53.09-1.53-.89a3.38 3.38 0 011.53-2.65z"
            style={{
              WebkitTransformOrigin: 126.63,
              MsTransformOrigin: 126.63,
              transformOrigin: 126.63,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M129.64 283.91c0-1.6-1.12-2.32-2.51-1.51l-.87.5a5.54 5.54 0 00-2.51 4.35v.43s.59 1.22 2.94-.17a6.55 6.55 0 002.95-3.23z"
            style={{
              WebkitTransformOrigin: 126.695,
              MsTransformOrigin: 126.695,
              transformOrigin: 126.695,
            }}
            fill="#FFF"
            className="animable"
          ></path>
          <path
            d="M119.7 298.55l14.09-8.13c.59-.34 1.08-.1 1.08.55a2.35 2.35 0 01-1.08 1.8l-14.09 8.13c-.6.34-1.08.1-1.08-.55a2.32 2.32 0 011.08-1.8z"
            style={{
              WebkitTransformOrigin: 126.745,
              MsTransformOrigin: 126.745,
              transformOrigin: 126.745,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M141.52 286l14.09-8.13c.59-.34 1.08-.1 1.08.55a2.35 2.35 0 01-1.08 1.8l-14.09 8.13c-.59.34-1.08.1-1.08-.55a2.35 2.35 0 011.08-1.8z"
            style={{
              WebkitTransformOrigin: 148.565,
              MsTransformOrigin: 148.565,
              transformOrigin: 148.565,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M163.21 273.4l14.08-8.13c.6-.34 1.08-.1 1.08.55a2.32 2.32 0 01-1.08 1.8l-14.08 8.13c-.6.35-1.08.1-1.08-.55a2.34 2.34 0 011.08-1.8z"
            style={{
              WebkitTransformOrigin: 170.25,
              MsTransformOrigin: 170.25,
              transformOrigin: 170.25,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M184.89 260.91l14.11-8.13c.59-.35 1.08-.1 1.08.55a2.35 2.35 0 01-1.08 1.8l-14.09 8.13c-.59.34-1.08.1-1.08-.55a2.35 2.35 0 011.06-1.8z"
            style={{
              WebkitTransformOrigin: 191.955,
              MsTransformOrigin: 191.955,
              transformOrigin: 191.955,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M206.58 248.38l14.08-8.13c.6-.35 1.08-.1 1.08.54a2.31 2.31 0 01-1.08 1.8l-14.08 8.13c-.6.35-1.08.1-1.08-.55a2.3 2.3 0 011.08-1.79z"
            style={{
              WebkitTransformOrigin: 213.62,
              MsTransformOrigin: 213.62,
              transformOrigin: 213.62,
            }}
            fill="#E0E0E0"
            className="animable"
          ></path>
          <path
            d="M113.48 325.21a.41.41 0 01-.19 0 .37.37 0 01-.19-.33v-14.12a.36.36 0 01.19-.32l12.18-7a.42.42 0 01.38 0 .37.37 0 01.19.33v14.03a.36.36 0 01-.19.32l-12.19 7a.37.37 0 01-.18.09zm.37-14.23v13.2l11.44-6.6v-13.2z"
            style={{
              WebkitTransformOrigin: 119.57,
              MsTransformOrigin: 119.57,
              transformOrigin: 119.57,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M117.54 322.86a.37.37 0 01-.38-.37v-14.07a.38.38 0 11.75 0v14.07a.37.37 0 01-.37.37z"
            style={{
              WebkitTransformOrigin: 117.535,
              MsTransformOrigin: 117.535,
              transformOrigin: 117.535,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M121.6 320.52a.37.37 0 01-.37-.38v-14.07a.38.38 0 01.38-.37.37.37 0 01.37.37v14.07a.38.38 0 01-.38.38z"
            style={{
              WebkitTransformOrigin: 121.605,
              MsTransformOrigin: 121.605,
              transformOrigin: 121.605,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M113.48 315.83a.39.39 0 01-.33-.19.37.37 0 01.14-.51l12.18-7a.39.39 0 01.52.14.37.37 0 01-.14.51l-12.19 7a.41.41 0 01-.18.05z"
            style={{
              WebkitTransformOrigin: 119.57,
              MsTransformOrigin: 119.57,
              transformOrigin: 119.57,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M113.48 320.47a.38.38 0 01-.33-.18.39.39 0 01.14-.52l12.18-7a.38.38 0 11.38.65l-12.19 7a.31.31 0 01-.18.05z"
            style={{
              WebkitTransformOrigin: 119.594,
              MsTransformOrigin: 119.594,
              transformOrigin: 119.594,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M163.08 296.25a1.73 1.73 0 01-.87-.22 2.18 2.18 0 01-.94-2v-5.34a6.07 6.07 0 012.78-4.82l7-4a2 2 0 013.16 1.83v5.3a6.07 6.07 0 01-2.78 4.82l-7 4a2.74 2.74 0 01-1.35.43zm9.31-16.08a2 2 0 00-1 .3l-7 4a5.34 5.34 0 00-2.41 4.17V294a1.22 1.22 0 002 1.18l7-4a5.34 5.34 0 002.41-4.17v-5.34a1.48 1.48 0 00-.57-1.36 1 1 0 00-.43-.14z"
            style={{
              WebkitTransformOrigin: 167.741,
              MsTransformOrigin: 167.741,
              transformOrigin: 167.741,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M167.57 282.64a.4.4 0 01-.27-.12l-1.66-1.78a.37.37 0 010-.53.38.38 0 01.53 0l1.23 1.31.86-2.74a.38.38 0 01.72.23l-1.06 3.34a.39.39 0 01-.28.26z"
            style={{
              WebkitTransformOrigin: 167.258,
              MsTransformOrigin: 167.258,
              transformOrigin: 167.258,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M164.06 291.71a.32.32 0 01-.19 0 .37.37 0 01-.14-.51l2.61-4.7a.35.35 0 01.25-.19.37.37 0 01.3.07l2.21 1.62 2-3.81a.38.38 0 11.67.35l-2.19 4.19a.35.35 0 01-.24.19.38.38 0 01-.31-.06l-2.22-1.65-2.41 4.34a.36.36 0 01-.34.16z"
            style={{
              WebkitTransformOrigin: 167.756,
              MsTransformOrigin: 167.756,
              transformOrigin: 167.756,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M210.94 268.63a1.74 1.74 0 01-.87-.23 2.17 2.17 0 01-.94-2v-5.16a5.66 5.66 0 012.38-4.6l2.08-1.2 1.32-3.6a.35.35 0 01.33-.24.39.39 0 01.36.2l1 1.91 2.31-1.33a1.95 1.95 0 013.16 1.82v5.17a6.07 6.07 0 01-2.78 4.82l-7 4a2.76 2.76 0 01-1.35.44zm4.38-15.74l-1.07 2.93a.35.35 0 01-.17.19l-2.19 1.27a5 5 0 00-2 4v5.16a1.47 1.47 0 00.57 1.36 1.48 1.48 0 001.46-.18l7-4a5.32 5.32 0 002.41-4.17v-5.17a1.49 1.49 0 00-.57-1.36 1.47 1.47 0 00-1.46.19l-2.65 1.53a.39.39 0 01-.3 0 .38.38 0 01-.22-.19z"
            style={{
              WebkitTransformOrigin: 215.608,
              MsTransformOrigin: 215.608,
              transformOrigin: 215.608,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M213 267.73a.38.38 0 01-.37-.38v-1.5a3.76 3.76 0 011.92-2.83l2.11-1.22a1.78 1.78 0 011.76-.2 1.12 1.12 0 01.54 1v1.5a.38.38 0 11-.75 0v-1.5a.42.42 0 00-.16-.38c-.15-.09-.51-.09-1 .2l-2.05 1.25a3.08 3.08 0 00-1.54 2.18v1.5a.38.38 0 01-.46.38z"
            style={{
              WebkitTransformOrigin: 215.797,
              MsTransformOrigin: 215.797,
              transformOrigin: 215.797,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M215 261.76a1.3 1.3 0 01-.64-.17 1.58 1.58 0 01-.69-1.44 4.17 4.17 0 011.91-3.31 1.42 1.42 0 012.29 1.32 4.17 4.17 0 01-1.91 3.31 2 2 0 01-.96.29zm1.54-4.45a1.21 1.21 0 00-.58.18 3.46 3.46 0 00-1.54 2.66.88.88 0 00.32.79.9.9 0 00.84-.12 3.46 3.46 0 001.54-2.66.89.89 0 00-.32-.79.53.53 0 00-.29-.06z"
            style={{
              WebkitTransformOrigin: 215.775,
              MsTransformOrigin: 215.775,
              transformOrigin: 215.775,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M121.6 379.43v5.86l-2.6 1.5v-2.25c0-1-.66-1.41-1.47-.94a3.31 3.31 0 00-1.47 2.63v2.26l-2.59 1.5v-5.87l4.06-7z"
            style={{
              WebkitTransformOrigin: 117.535,
              MsTransformOrigin: 117.535,
              transformOrigin: 117.535,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M140.49 371.26a2.09 2.09 0 01-1.07-.28 2.7 2.7 0 01-1.18-2.48 7.44 7.44 0 013.42-5.92 2.42 2.42 0 013.92 2.26 7.46 7.46 0 01-3.42 5.92 3.4 3.4 0 01-1.67.5zm2.83-8.18a2.41 2.41 0 00-1.16.36 6.58 6.58 0 00-2.92 5.06 1.45 1.45 0 002.42 1.39 6.55 6.55 0 002.92-5.05 1.77 1.77 0 00-.68-1.62 1.21 1.21 0 00-.58-.14z"
            style={{
              WebkitTransformOrigin: 141.92,
              MsTransformOrigin: 141.92,
              transformOrigin: 141.92,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M146.31 370.38a.5.5 0 01-.26-.08l-2.42-1.51a.49.49 0 01-.16-.69.51.51 0 01.69-.16l2.42 1.51a.51.51 0 01.16.69.52.52 0 01-.43.24z"
            style={{
              WebkitTransformOrigin: 145.101,
              MsTransformOrigin: 145.101,
              transformOrigin: 145.101,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M165.11 359.14a1.87 1.87 0 01-.93-.24 2.31 2.31 0 01-1-2.12v-3.38a6.2 6.2 0 012.85-4.93l2.93-1.69a2.08 2.08 0 013.35 1.93v3.38a6.21 6.21 0 01-2.85 4.93l-2.93 1.7a2.88 2.88 0 01-1.42.42zm5.25-11.78a1.86 1.86 0 00-.91.28l-2.93 1.69a5.3 5.3 0 00-2.35 4.07v3.38a1.1 1.1 0 001.85 1.07l2.93-1.69a5.3 5.3 0 002.35-4.07v-3.38a1.39 1.39 0 00-.51-1.25.88.88 0 00-.43-.1z"
            style={{
              WebkitTransformOrigin: 167.751,
              MsTransformOrigin: 167.751,
              transformOrigin: 167.751,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M167.73 355.59a.5.5 0 01-.5-.5v-4.69a.51.51 0 01.5-.5.5.5 0 01.5.5v4.69a.5.5 0 01-.5.5z"
            style={{
              WebkitTransformOrigin: 167.73,
              MsTransformOrigin: 167.73,
              transformOrigin: 167.73,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M165.7 354.42a.5.5 0 01-.25-.93l4.07-2.35a.5.5 0 01.68.18.51.51 0 01-.18.69l-4.02 2.34a.45.45 0 01-.3.07z"
            style={{
              WebkitTransformOrigin: 167.736,
              MsTransformOrigin: 167.736,
              transformOrigin: 167.736,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M193.83 342.92a.46.46 0 01-.23-.06l-1.36-.73-.39-.2c-1.11-.51-2.73-1.26-2.5-3.91a5.27 5.27 0 012.65-4.22 1.72 1.72 0 011.67.1 5.07 5.07 0 011.51-1.94 2 2 0 012-.38 2.1 2.1 0 011.1 1.83c.21 2.24-1.15 4.45-2.25 6.22-.19.31-.33.53-.45.75l-1.36 2.29a.5.5 0 01-.39.25zm-1.06-8.29a1.08 1.08 0 00-.39.09 4.39 4.39 0 00-2 3.39c-.16 1.95.84 2.41 1.89 2.9.2.09.35.16.49.24l.93.5 1.11-1.88.42-.69c1.06-1.72 2.32-3.77 2.14-5.67 0-.52-.23-.89-.51-1a1.1 1.1 0 00-1 .27 4.44 4.44 0 00-1.46 2.24.5.5 0 01-.44.36.52.52 0 01-.49-.29.66.66 0 00-.69-.46z"
            style={{
              WebkitTransformOrigin: 193.815,
              MsTransformOrigin: 193.815,
              transformOrigin: 193.815,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M215 330.68a.5.5 0 01-.5-.5v-.88a6.22 6.22 0 012.84-4.93l2.93-1.69a2.07 2.07 0 013.35 1.93v.88a.5.5 0 01-1 0v-.88a1.1 1.1 0 00-1.85-1.06l-2.93 1.69a5.27 5.27 0 00-2.34 4.06v.88a.5.5 0 01-.5.5z"
            style={{
              WebkitTransformOrigin: 219.073,
              MsTransformOrigin: 219.073,
              transformOrigin: 219.073,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M218.18 323.26a1.62 1.62 0 01-.78-.2 1.92 1.92 0 01-.84-1.75 5 5 0 012.28-3.95 1.73 1.73 0 012.78 1.61 5 5 0 01-2.28 3.95 2.33 2.33 0 01-1.16.34zM220 318a1.48 1.48 0 00-.65.21 4 4 0 00-1.78 3.09 1 1 0 00.34.88 1 1 0 00.94-.14 4 4 0 001.78-3.08 1 1 0 00-.34-.89.65.65 0 00-.29-.07zm-.9-.22z"
            style={{
              WebkitTransformOrigin: 219.096,
              MsTransformOrigin: 219.096,
              transformOrigin: 219.096,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 130.61,
              MsTransformOrigin: 130.61,
              transformOrigin: 130.61,
            }}
            fill="#DD26D7"
            d="M113.48 334.21L147.74 314.43 147.74 351.95 113.48 371.74 113.48 334.21z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 130.61,
              MsTransformOrigin: 130.61,
              transformOrigin: 130.61,
            }}
            fill="#FFF"
            d="M113.48 334.21L147.74 314.43 147.74 351.95 113.48 371.74 113.48 334.21z"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 130.61,
              MsTransformOrigin: 130.61,
              transformOrigin: 130.61,
            }}
            fill="#DD26D7"
            d="M113.48 371.74L125.1 338.9 136.13 351.57 134.08 347.78 138.35 338.61 147.74 351.95 113.48 371.74z"
            className="animable"
          ></path>
          <path
            d="M135.77 329.58c1.13-.65 2-.13 2 1.17a4.55 4.55 0 01-2.1 3.55c-1.16.67-2.07.15-2-1.17a4.66 4.66 0 012.1-3.55z"
            style={{
              WebkitTransformOrigin: 135.718,
              MsTransformOrigin: 135.718,
              transformOrigin: 135.718,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 168.315,
              MsTransformOrigin: 168.315,
              transformOrigin: 168.315,
            }}
            fill="#DD26D7"
            d="M151.18 312.45L185.44 292.66 185.45 330.19 151.18 349.97 151.18 312.45z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 168.315,
              MsTransformOrigin: 168.315,
              transformOrigin: 168.315,
            }}
            fill="#FFF"
            d="M151.18 312.45L185.44 292.66 185.45 330.19 151.18 349.97 151.18 312.45z"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 168.31,
              MsTransformOrigin: 168.31,
              transformOrigin: 168.31,
            }}
            fill="#DD26D7"
            d="M151.18 349.97L162.8 317.14 173.84 329.81 171.78 326.01 176.06 316.85 185.44 330.19 151.18 349.97z"
            className="animable"
          ></path>
          <path
            d="M173.48 307.81c1.12-.65 2-.13 2 1.17a4.58 4.58 0 01-2.1 3.56c-1.16.66-2.07.14-2-1.18a4.7 4.7 0 012.1-3.55z"
            style={{
              WebkitTransformOrigin: 173.428,
              MsTransformOrigin: 173.428,
              transformOrigin: 173.428,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 206.02,
              MsTransformOrigin: 206.02,
              transformOrigin: 206.02,
            }}
            fill="#DD26D7"
            d="M188.89 290.68L223.15 270.89 223.15 308.42 188.89 328.2 188.89 290.68z"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 206.02,
              MsTransformOrigin: 206.02,
              transformOrigin: 206.02,
            }}
            fill="#FFF"
            d="M188.89 290.68L223.15 270.89 223.15 308.42 188.89 328.2 188.89 290.68z"
            className="animable"
            opacity="0.7"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 206.02,
              MsTransformOrigin: 206.02,
              transformOrigin: 206.02,
            }}
            fill="#DD26D7"
            d="M188.89 328.2L200.51 295.37 211.54 308.04 209.49 304.24 213.76 295.08 223.15 308.42 188.89 328.2z"
            className="animable"
          ></path>
          <path
            d="M211.18 286c1.12-.65 2-.13 2 1.17a4.55 4.55 0 01-2.09 3.56c-1.16.67-2.07.14-2-1.18a4.68 4.68 0 012.09-3.55z"
            style={{
              WebkitTransformOrigin: 211.133,
              MsTransformOrigin: 211.133,
              transformOrigin: 211.133,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 348.282,
          MsTransformOrigin: 348.282,
          transformOrigin: 348.282,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 348.282,
            MsTransformOrigin: 348.282,
            transformOrigin: 348.282,
          }}
        >
          <path
            d="M397.6 217.15c16.18 9.34 41.92 27.91 41.92 67.78s-26.8 71.47-62.89 84.72c-19.3 7.08-49.9 9.8-73.21-2.13-20.27-10.37-33.92-21-41.36-40.4-12.46-32.47 0-57.5 18.65-70.95s26.23-18.52 42.11-33.56c8.07-7.63 20.12-12.76 32.85-14.18a71.15 71.15 0 0141.93 8.72z"
            style={{
              WebkitTransformOrigin: 348.282,
              MsTransformOrigin: 348.282,
              transformOrigin: 348.282,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M397.6 217.15c16.18 9.34 41.92 27.91 41.92 67.78s-26.8 71.47-62.89 84.72c-19.3 7.08-49.9 9.8-73.21-2.13-20.27-10.37-33.92-21-41.36-40.4-12.46-32.47 0-57.5 18.65-70.95s26.23-18.52 42.11-33.56c8.07-7.63 20.12-12.76 32.85-14.18a71.15 71.15 0 0141.93 8.72z"
            style={{
              WebkitTransformOrigin: 348.282,
              MsTransformOrigin: 348.282,
              transformOrigin: 348.282,
            }}
            fill="#FFF"
            className="animable"
            opacity="0.6"
          ></path>
          <path
            d="M393.4 350.28c32-26 34.27-67.43 26.38-92.68-8.27-26.44-33.26-46.31-64.13-49.17a62.24 62.24 0 016.73-.37c27.82 4.46 50.72 24.18 58.53 49.18 8 25.57 5.65 67.57-26.77 94-13.85 11.29-34.81 21.09-51.37 24.47-2 0-4 0-6-.06 20-2.87 42.94-14.21 56.63-25.37z"
            style={{
              WebkitTransformOrigin: 380.777,
              MsTransformOrigin: 380.777,
              transformOrigin: 380.777,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M393.4 350.28c32-26 34.27-67.43 26.38-92.68-8.27-26.44-33.26-46.31-64.13-49.17a62.24 62.24 0 016.73-.37c27.82 4.46 50.72 24.18 58.53 49.18 8 25.57 5.65 67.57-26.77 94-13.85 11.29-34.81 21.09-51.37 24.47-2 0-4 0-6-.06 20-2.87 42.94-14.21 56.63-25.37z"
            style={{
              WebkitTransformOrigin: 380.777,
              MsTransformOrigin: 380.777,
              transformOrigin: 380.777,
            }}
            className="animable"
            opacity="0.05"
          ></path>
          <path
            d="M298.07 279.74c12-6.64 14.34-13.91 24.75-41s29.84-18.51 29.84-18.51 36.4 10.69 42 27.64c4.55 13.75-1.27 31.35-5.85 41.88a51 51 0 01-8 13.1c13.72-2.7 24.65 6.56 24.65 6.56s-11.57-5.5-30.57 2.12c-11.25 4.52-20 13.44-32 16.94-9.28 2.72-18.69 5.47-26.34 11.66a35.29 35.29 0 00-12.82 22.56 13.35 13.35 0 01-1 4.45c-12.09-6.23-21.79-12.62-29.19-21-.22-2.7-.06-5.47-.13-8.17-.26-10-.39-19.95.14-29.9 1.22-22.82 12.51-21.69 24.52-28.33z"
            style={{
              WebkitTransformOrigin: 339.322,
              MsTransformOrigin: 339.322,
              transformOrigin: 339.322,
            }}
            className="animable"
            opacity="0.1"
          ></path>
        </g>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 310.939,
          MsTransformOrigin: 310.939,
          transformOrigin: 310.939,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 310.939,
            MsTransformOrigin: 310.939,
            transformOrigin: 310.939,
          }}
        >
          <path
            d="M338.06 200.66c-5.53-.52-11.9.16-15.25 4.32-4.44 5.53-13.65 28.12-16.66 39.41-.82 3.09 13.44 10.38 13.44 10.38l9.41-20.23z"
            style={{
              WebkitTransformOrigin: 322.088,
              MsTransformOrigin: 322.088,
              transformOrigin: 322.088,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M337.13 200.43c-6.24-.5-10.62.58-13.54 3.25s-6.18 9.59-7.86 13.59-5.4 13.93-5.4 13.93 4.3 6 16.22 7.92c3.71-11.48 10.58-38.69 10.58-38.69z"
            style={{
              WebkitTransformOrigin: 323.73,
              MsTransformOrigin: 323.73,
              transformOrigin: 323.73,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M276.65 346.26s4.81 15.85 4.57 21.9c-.25 6.31 1.08 8.53-.62 15.26-.37 1.47-1.43 4.38-1.73 5.57a1.78 1.78 0 001.4 2.37c2.67.82 7.67-3.88 10.34-9.25a164.08 164.08 0 007.21-19.11 8.59 8.59 0 00-.45-4.48 13.27 13.27 0 00-1-2.39c-.65-2.81-.6-8.05-1-11.71z"
            style={{
              WebkitTransformOrigin: 287.293,
              MsTransformOrigin: 287.293,
              transformOrigin: 287.293,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M302.62 369.19a6.61 6.61 0 01.54 2.13c-.05 1.26-3.26 5.12-4.51 6.52a10.07 10.07 0 00-2.7 7c-.21 4-2.34 7.72-5.23 10.78a27.7 27.7 0 01-14.53 7.7c-6.8 1.42-8.82-2.11-8.82-2.11s-1.76-3.38-.4-4.57 35.65-27.45 35.65-27.45z"
            style={{
              WebkitTransformOrigin: 284.807,
              MsTransformOrigin: 284.807,
              transformOrigin: 284.807,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M296.33 356.11c.2 0 .56 0 1 .66.18.26 1.11 3.34 2 5s3.21 4.86 3.31 7.42c-2 4.76-6.9 7.75-8 12.31s-1.69 9.93-9.33 14.94-15 4.91-17.5 3.17-2-4.54 2.18-9.56c4.41-5.33 8.18-17.43 8.18-17.43a41.71 41.71 0 001.17-5.18 35.26 35.26 0 000-5.24 3.84 3.84 0 01.55-2.32 3.93 3.93 0 012.08-1.17 17 17 0 013.77-.9 5.85 5.85 0 013.72.8 28 28 0 002.22 1.72 3.4 3.4 0 003.55-.3 2.89 2.89 0 001.24-3.12 6.52 6.52 0 00-.14-.8z"
            style={{
              WebkitTransformOrigin: 284.467,
              MsTransformOrigin: 284.467,
              transformOrigin: 284.467,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M289.85 392.28s-2.24-5.85-9.79-6.53-10.53 2.94-11.89 5.09c-1.62 2.56-3.49 7.31-.51 9 3.23 1.82 7.17 1.72 13.59-.79s8.6-6.77 8.6-6.77z"
            style={{
              WebkitTransformOrigin: 277.967,
              MsTransformOrigin: 277.967,
              transformOrigin: 277.967,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M290.58 369.41a.8.8 0 01-.69-.07c-1.11-.7-7.16-2.57-11.07.44-.43-.42-.27-1.09.41-2 4.46-2.28 10.19-.55 11.53.3a.72.72 0 01.2 1 .8.8 0 01-.38.33z"
            style={{
              WebkitTransformOrigin: 284.831,
              MsTransformOrigin: 284.831,
              transformOrigin: 284.831,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M277.64 374.11a2.35 2.35 0 01.62-2c4.36-1.87 9.69-.24 11 .57a.73.73 0 01.2 1 .79.79 0 01-.39.29.77.77 0 01-.69-.07c-1.1-.68-6.84-2.44-10.74.21z"
            style={{
              WebkitTransformOrigin: 283.594,
              MsTransformOrigin: 283.594,
              transformOrigin: 283.594,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M273.61 384a4.3 4.3 0 011.22-2.65c4.43-2.14 10-.44 11.35.4a.72.72 0 01.2 1 .81.81 0 01-1.07.23c-1.18-.69-7.85-2.72-11.7 1.02z"
            style={{
              WebkitTransformOrigin: 280.056,
              MsTransformOrigin: 280.056,
              transformOrigin: 280.056,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M287.52 378.54a.78.78 0 01-.68-.07c-1.1-.69-7-2.51-10.94.34a2.65 2.65 0 01.84-2.17c4.36-1.87 9.68-.24 11 .57a.72.72 0 01.2 1 .83.83 0 01-.42.33z"
            style={{
              WebkitTransformOrigin: 281.976,
              MsTransformOrigin: 281.976,
              transformOrigin: 281.976,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M268.2 364.39s-4.82 18.35-8.92 25.26-6.19 10.23-11.43 15.26c-1.14 1.1-3.69 3.1-4.62 4a1.87 1.87 0 00-.07 2.89c1.91 2.21 10.79 3.51 16.13.23s10.64-10.85 13.9-13.59c1.23-1 2.39-2.09 3.51-3.23s.91-2.24 1.12-3.56c.1-.64.17-2.4.17-2.4 1-3.08 3.28-7.83 4.55-10.79z"
            style={{
              WebkitTransformOrigin: 262.526,
              MsTransformOrigin: 262.526,
              transformOrigin: 262.526,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M278.45 403.47a6.87 6.87 0 01-.44 2.25c-.61 1.17-5.37 3.39-7.18 4.16a10.51 10.51 0 00-5.66 5.36c-2 3.72-5.66 6.26-9.77 7.87a28.88 28.88 0 01-17.18.79c-7.07-1.69-7.41-5.93-7.41-5.93s-.15-4 1.66-4.51 45.98-9.99 45.98-9.99z"
            style={{
              WebkitTransformOrigin: 254.628,
              MsTransformOrigin: 254.628,
              transformOrigin: 254.628,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M278.34 388.28c.18.1.54.23.67 1.08.05.32-.45 3.65-.37 5.6s.86 6-.19 8.51c-4 3.61-10 4.24-13.09 8.06s-6 8.64-15.5 10-16.4-2.06-18-4.82.14-5.18 6.32-8.07c6.56-3.08 15.53-12.84 15.53-12.84a44.09 44.09 0 003.43-4.38 37.44 37.44 0 002.33-5 4 4 0 011.55-2 4.16 4.16 0 012.5-.17 17.78 17.78 0 014 .83 6.1 6.1 0 013.17 2.42c.5.84.74 1.83 1.32 2.62a3.54 3.54 0 003.5 1.3 3 3 0 002.57-2.4l.26-.74z"
            style={{
              WebkitTransformOrigin: 255.156,
              MsTransformOrigin: 255.156,
              transformOrigin: 255.156,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M256.05 419.61s.5-6.54-6.35-10.55-11.27-1.93-13.52-.5c-2.68 1.7-6.57 5.36-4.5 8.29 2.24 3.17 6 4.83 13.21 5.32s11.16-2.56 11.16-2.56z"
            style={{
              WebkitTransformOrigin: 243.577,
              MsTransformOrigin: 243.577,
              transformOrigin: 243.577,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M267 398.29a.8.8 0 01-.62-.37c-.74-1.17-5.64-5.63-10.67-4.53-.23-.59.23-1.16 1.28-1.71 5.23-.17 9.89 4 10.78 5.43a.76.76 0 01-.28 1.07.84.84 0 01-.49.11z"
            style={{
              WebkitTransformOrigin: 261.769,
              MsTransformOrigin: 261.769,
              transformOrigin: 261.769,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M252.61 397a2.45 2.45 0 011.5-1.64c5 .18 9.27 4.1 10.13 5.44a.76.76 0 01-.27 1.07.89.89 0 01-.5.11.86.86 0 01-.62-.38c-.72-1.17-5.36-5.41-10.24-4.6z"
            style={{
              WebkitTransformOrigin: 258.484,
              MsTransformOrigin: 258.484,
              transformOrigin: 258.484,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M244.37 404.54a4.51 4.51 0 012.34-2c5.14 0 9.68 4.05 10.56 5.44a.75.75 0 01-.27 1.06.84.84 0 01-1.12-.26c-.78-1.18-6.19-6.08-11.51-4.24z"
            style={{
              WebkitTransformOrigin: 250.879,
              MsTransformOrigin: 250.879,
              transformOrigin: 250.879,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M260 405.56a.8.8 0 01-.62-.37c-.73-1.15-5.53-5.52-10.51-4.56a2.79 2.79 0 011.77-1.68c5 .18 9.27 4.1 10.12 5.44a.74.74 0 01-.27 1.06.71.71 0 01-.49.11z"
            style={{
              WebkitTransformOrigin: 254.876,
              MsTransformOrigin: 254.876,
              transformOrigin: 254.876,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
          <path
            d="M319.13 262.11C311 267 283 279.89 271.22 288.89c-3.39 2.6-4.77 6.31-4.77 14.67 0 11.34 5.29 50.48 5.29 50.48 1 2.28 3.54-1.63 3.54-1.63l47.11-49.6z"
            style={{
              WebkitTransformOrigin: 294.42,
              MsTransformOrigin: 294.42,
              transformOrigin: 294.42,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M319.13 262.11C311 267 283 279.89 271.22 288.89c-3.39 2.6-4.77 6.31-4.77 14.67 0 11.34 5.29 50.48 5.29 50.48 1 2.28 3.54-1.63 3.54-1.63l47.11-49.6z"
            style={{
              WebkitTransformOrigin: 294.42,
              MsTransformOrigin: 294.42,
              transformOrigin: 294.42,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M274.68 342.28c10.5-7.25 46.61-54 46.61-54-4-4-5-16.8-5-16.8-2.15 4.69-1 12.17.05 15-2.68 1.92-17.77 12.14-28.22 19-2.62 1.71-5.34 3.86-6.58 8.18-3.01 10.23-6.9 26.47-6.86 28.62z"
            style={{
              WebkitTransformOrigin: 297.985,
              MsTransformOrigin: 297.985,
              transformOrigin: 297.985,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M319.13 262.11l53.68 17.33c-3.58 9.71-4.74 14.74-8 19.73s-7.46 10.09-22.21 15.73c-14.21 5.43-31.45 10.78-34.91 12.06-2.62 1-4.27 5.85-6 12.23-1.82 6.69-2.42 12-10.47 25.66-7.89 13.38-9.52 16.27-9.52 16.27s-10.29.12-16.51-6.46c0 0 13.68-47.09 16.45-55.53 1.54-4.72 1.8-7.26 8.28-12 5.55-4.07 30.07-19.29 30.07-19.29s-7.99-13.07-.86-25.73z"
            style={{
              WebkitTransformOrigin: 319,
              MsTransformOrigin: 319,
              transformOrigin: 319,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M319.13 262.11l53.68 17.33c-3.58 9.71-4.74 14.74-8 19.73s-7.46 10.09-22.21 15.73c-14.21 5.43-31.45 10.78-34.91 12.06-2.62 1-4.27 5.85-6 12.23-1.82 6.69-2.42 12-10.47 25.66-7.89 13.38-9.52 16.27-9.52 16.27s-10.29.12-16.51-6.46c0 0 13.68-47.09 16.45-55.53 1.54-4.72 1.8-7.26 8.28-12 5.55-4.07 30.07-19.29 30.07-19.29s-7.99-13.07-.86-25.73z"
            style={{
              WebkitTransformOrigin: 319,
              MsTransformOrigin: 319,
              transformOrigin: 319,
            }}
            className="animable"
            opacity="0.3"
          ></path>
          <path
            d="M380.31 212.52c.46 4.81 2.4 45.63-9 71.47-15.29 7-52.7-3.62-55.58-22 0 0 5.13-7.52 7.07-13 .56-9.3 2.1-24.25 2.79-29.7 1.38-10.82 6.23-17.93 11.54-18.79l17.48.53 9.75 2.12s15.64 6.37 15.95 9.37z"
            style={{
              WebkitTransformOrigin: 348.227,
              MsTransformOrigin: 348.227,
              transformOrigin: 348.227,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M367.89 155.93a8.71 8.71 0 016.46 5c1.28 2.83.12 8.39-1.74 15.71s-3.6 11.74-4.68 13.76a5.59 5.59 0 01-3.27 2.57l.28-8.7.36-6.07s-5.49 1.61-6.76-7.26c-.8-5.58 0-7.36 0-7.36z"
            style={{
              WebkitTransformOrigin: 366.537,
              MsTransformOrigin: 366.537,
              transformOrigin: 366.537,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M361.12 165.36A16.26 16.26 0 11345 148.92a16.25 16.25 0 0116.12 16.44z"
            style={{
              WebkitTransformOrigin: 344.861,
              MsTransformOrigin: 344.861,
              transformOrigin: 344.861,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M363.28 177.48c1.64.51 2.46-2.36 3.7-3.63s5.17-2.43 7.82 1.34-.51 10.13-3.52 11.5c-4.41 2-6.34-2.44-6.34-2.44l-.61 19.68s-1.18 3.67-8.73 6.26c-5.88 2-14.46-1.5-9-7.05l.08-6a25.06 25.06 0 01-8.51.08c-2.9-.58-5.15-2.89-6.44-7.47-2.54-8.95-1.79-20.7 1.73-31.29 4.51-3.43 15.67-1 25 5.07.22 12.18 2.97 13.38 4.82 13.95z"
            style={{
              WebkitTransformOrigin: 353.011,
              MsTransformOrigin: 353.011,
              transformOrigin: 353.011,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M349.6 185.51l-7.8 1.77a3.84 3.84 0 004.65 3.09 4.21 4.21 0 003.15-4.86z"
            style={{
              WebkitTransformOrigin: 345.735,
              MsTransformOrigin: 345.735,
              transformOrigin: 345.735,
            }}
            fill="#B16668"
            className="animable"
          ></path>
          <path
            d="M349.21 188.16a3.4 3.4 0 00-1.8-.82 3.35 3.35 0 00-3.71 2.66 3.81 3.81 0 002.75.42 4.17 4.17 0 002.76-2.26z"
            style={{
              WebkitTransformOrigin: 346.455,
              MsTransformOrigin: 346.455,
              transformOrigin: 346.455,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M330.22 147.7a.74.74 0 01.59-.44 1 1 0 01.54.24 26.8 26.8 0 003.54 2.43 12.82 12.82 0 010-4.86c.18-1 .48-3.26 1.7-3.5.73-.14 1.35.47 1.89 1 4.85 4.61 11.25 2.55 17.13 2.78 4.92.19 10.85 2.24 13 7 1 2.19 1.16 4.5-.86 6-1.73 1.28-7.21 4.59-9.25 5.18-5.4 1.54-19.46 3.34-26.64-5.85a14.43 14.43 0 01-2.3-6 7.26 7.26 0 01.62-4z"
            style={{
              WebkitTransformOrigin: 349.414,
              MsTransformOrigin: 349.414,
              transformOrigin: 349.414,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M346.71 197.17s7.43-1.8 10.41-3.69a14.13 14.13 0 004.73-4.92s-.79 4.17-2.89 6.35c-3.68 3.81-12.27 4.64-12.27 4.64z"
            style={{
              WebkitTransformOrigin: 354.27,
              MsTransformOrigin: 354.27,
              transformOrigin: 354.27,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M347.21 175.84A1.79 1.79 0 10349 174a1.82 1.82 0 00-1.79 1.84z"
            style={{
              WebkitTransformOrigin: 349,
              MsTransformOrigin: 349,
              transformOrigin: 349,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M349 168.17l3.88 2a2.29 2.29 0 00-1-3 2.13 2.13 0 00-2.88 1z"
            style={{
              WebkitTransformOrigin: 351.049,
              MsTransformOrigin: 351.049,
              transformOrigin: 351.049,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M332.64 168.73l4.24-1.2a2.11 2.11 0 00-2.65-1.55 2.3 2.3 0 00-1.59 2.75z"
            style={{
              WebkitTransformOrigin: 334.728,
              MsTransformOrigin: 334.728,
              transformOrigin: 334.728,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M333.71 174.54a1.79 1.79 0 101.81-1.84 1.82 1.82 0 00-1.81 1.84z"
            style={{
              WebkitTransformOrigin: 335.5,
              MsTransformOrigin: 335.5,
              transformOrigin: 335.5,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            style={{
              WebkitTransformOrigin: 339.33,
              MsTransformOrigin: 339.33,
              transformOrigin: 339.33,
            }}
            fill="#F28F8F"
            d="M342.06 183.21L336.18 181.23 342.48 171.48 342.06 183.21z"
            className="animable"
          ></path>
          <path
            d="M298.26 233.89l1.26-1a1.36 1.36 0 011.24-.06l12.77 4.32a4.8 4.8 0 012.76 2.9l7.71 27.58a1.23 1.23 0 01-.26 1.37l-1.3 1a1.35 1.35 0 01-1.25.07l-12.77-4.32a4.8 4.8 0 01-2.76-2.9c-1.51-5.41-6.19-22.12-7.71-27.53-.2-.7-.05-1.2.31-1.43z"
            style={{
              WebkitTransformOrigin: 310.983,
              MsTransformOrigin: 310.983,
              transformOrigin: 310.983,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M313.62 239a4.7 4.7 0 011.38 2.08l7.72 27.52a1.29 1.29 0 01-.21 1.35l1.22-.93a1.24 1.24 0 00.26-1.37l-7.71-27.53a4.6 4.6 0 00-1.28-2z"
            style={{
              WebkitTransformOrigin: 318.856,
              MsTransformOrigin: 318.856,
              transformOrigin: 318.856,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M322.71 268.6L315 241.06a4.78 4.78 0 00-2.75-2.9l-12.77-4.32c-1.19-.4-1.88.25-1.54 1.46 1.52 5.41 6.2 22.12 7.71 27.53a4.8 4.8 0 002.76 2.9l12.77 4.32c1.18.4 1.87-.25 1.53-1.45z"
            style={{
              WebkitTransformOrigin: 310.325,
              MsTransformOrigin: 310.325,
              transformOrigin: 310.325,
            }}
            fill="#37474F"
            className="animable"
          ></path>
          <path
            d="M300.44 244.21c-2.25-.68-3.65 1.72-3.52 5.94s2.54 12.07 9.07 10.17c2.54-.74 4.4-4.94 1.77-6.84a43.15 43.15 0 01-6.13-5z"
            style={{
              WebkitTransformOrigin: 302.973,
              MsTransformOrigin: 302.973,
              transformOrigin: 302.973,
            }}
            fill="#F28F8F"
            className="animable"
          ></path>
          <path
            d="M380.33 270.74c2.7-5.4 7.43-18.57 9.17-31.69 2.34-17.76 1.26-24.87-11.39-29-9.39 8.1-6.82 28.11-6.82 28.11-1.7 7.42-4.52 20.06-6.42 24.75-12-.13-33.8-7.18-36.22-8.51-1.25-.69-2.62-2.71-3.42-3.78a9.51 9.51 0 00-3.24-3c-1.66-.9-3-1.44-4.22-2.1l1.6 5.74c-.13.33-.89.4-1.24.51a20.78 20.78 0 01-7.91.89c-5.57-.48-10.74-4.58-9.72.37.41 2 1.18 5.68 4.1 8.21s13.76 3.76 16.8 5.22c0 0 27.76 12.13 39.18 13.65 12.23 1.57 14.49 1.16 19.75-9.37z"
            style={{
              WebkitTransformOrigin: 345.429,
              MsTransformOrigin: 345.429,
              transformOrigin: 345.429,
            }}
            fill="#FFA8A7"
            className="animable"
          ></path>
          <path
            d="M377 209.3c6.13 1.87 11.35 3.46 13.52 12.29s-2.76 30.07-2.76 30.07-11.57 2.32-19.38-2.74l2-10.78c-.5-12.56.88-22.88 6.62-28.84z"
            style={{
              WebkitTransformOrigin: 379.726,
              MsTransformOrigin: 379.726,
              transformOrigin: 379.726,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 424.795,
          MsTransformOrigin: 424.795,
          transformOrigin: 424.795,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 424.795,
            MsTransformOrigin: 424.795,
            transformOrigin: 424.795,
          }}
        >
          <path
            d="M410 386.11c-8.61-8.79-13.67-35-5.27-42h42.73c8.39 7 3.34 33.16-5.26 42l-.24.24-.3.29c-.24.24-.5.47-.77.7l-.21.16c-.21.16-.42.33-.65.49s-.74.5-1.11.71c-7.09 4.09-18.57 4.09-25.65 0a12.46 12.46 0 01-1.12-.72c-.22-.15-.41-.3-.61-.46l-.25-.19c-.27-.22-.52-.45-.75-.67s-.23-.22-.34-.34z"
            style={{
              WebkitTransformOrigin: 426.093,
              MsTransformOrigin: 426.093,
              transformOrigin: 426.093,
            }}
            fill="#37474F"
            className="animable"
          ></path>
          <path
            d="M443.71 341.34c9.75 5.63 9.75 14.76 0 20.38s-25.56 5.63-35.31 0-9.75-14.75 0-20.38 25.6-5.63 35.31 0z"
            style={{
              WebkitTransformOrigin: 426.055,
              MsTransformOrigin: 426.055,
              transformOrigin: 426.055,
            }}
            fill="#455A64"
            className="animable"
          ></path>
          <path
            d="M439 344.05c7.16 4.13 7.16 10.83 0 15s-18.77 4.13-25.93 0-7.16-10.84 0-15 18.79-4.14 25.93 0z"
            style={{
              WebkitTransformOrigin: 426.035,
              MsTransformOrigin: 426.035,
              transformOrigin: 426.035,
            }}
            fill="#263238"
            className="animable"
          ></path>
          <path
            d="M413.09 351.15c7.16-4.13 18.77-4.13 25.93 0a12 12 0 014.29 3.93A11.92 11.92 0 01439 359c-7.16 4.13-18.77 4.13-25.93 0a11.83 11.83 0 01-4.29-3.94 11.9 11.9 0 014.31-3.91z"
            style={{
              WebkitTransformOrigin: 426.045,
              MsTransformOrigin: 426.045,
              transformOrigin: 426.045,
            }}
            fill="#F5F5F5"
            className="animable"
          ></path>
          <g
            className="animable"
            style={{
              WebkitTransformOrigin: 424.795,
              MsTransformOrigin: 424.795,
              transformOrigin: 424.795,
            }}
          >
            <path
              d="M418.77 333.68c1.27-1.91 3.16-3.38 4.31-5.37 2.42-4.18-.48-8.26.73-12.51a6.92 6.92 0 013.83-4.11 20.94 20.94 0 015.59-1.46 8.85 8.85 0 002.95-.84c1.1-.62 1.44-1.71 2.13-2.67a18.56 18.56 0 012.71-2.89c2-1.8 4.53-3.28 7.32-3.16 5.81.26 5.65 5.26 4 9.05s-2.12 5 1.51 9 3.64 8.81-.69 11.54-7.15 3.09-5.44 8.33c1.53 4.69 2.48 8.69-1.85 13.7-2.8 3.24-6.63 5.55-11.41 5.35-8.67-.36-15.72-7.38-17.16-15.73a11.52 11.52 0 011.3-7.9z"
              style={{
                WebkitTransformOrigin: 436.792,
                MsTransformOrigin: 436.792,
                transformOrigin: 436.792,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M418.77 333.68c1.27-1.91 3.16-3.38 4.31-5.37 2.42-4.18-.48-8.26.73-12.51a6.92 6.92 0 013.83-4.11 20.94 20.94 0 015.59-1.46 8.85 8.85 0 002.95-.84c1.1-.62 1.44-1.71 2.13-2.67a18.56 18.56 0 012.71-2.89c2-1.8 4.53-3.28 7.32-3.16 5.81.26 5.65 5.26 4 9.05s-2.12 5 1.51 9 3.64 8.81-.69 11.54-7.15 3.09-5.44 8.33c1.53 4.69 2.48 8.69-1.85 13.7-2.8 3.24-6.63 5.55-11.41 5.35-8.67-.36-15.72-7.38-17.16-15.73a11.52 11.52 0 011.3-7.9z"
              style={{
                WebkitTransformOrigin: 436.792,
                MsTransformOrigin: 436.792,
                transformOrigin: 436.792,
              }}
              className="animable"
              opacity="0.3"
            ></path>
            <path
              d="M448.39 323.34a.43.43 0 00-.52-.31c-.36.09-8.23 2.16-13.56 5.13 4.57-11.67 10.58-18.19 10.68-18.3a.44.44 0 000-.61.43.43 0 00-.6 0 60.42 60.42 0 00-9.32 15 33.75 33.75 0 00-4.38-7.13.43.43 0 10-.66.56 32.83 32.83 0 014.56 7.64c-.49 1.14-1 2.34-1.45 3.6a.43.43 0 00-.11.3 74.84 74.84 0 00-4.61 26.69.42.42 0 00.43.43.27.27 0 00.13 0 .43.43 0 00.29-.41 74 74 0 014.58-26.43c5.17-3.19 14.16-5.56 14.25-5.59a.43.43 0 00.29-.57z"
              style={{
                WebkitTransformOrigin: 438.417,
                MsTransformOrigin: 438.417,
                transformOrigin: 438.417,
              }}
              fill="#FFF"
              className="animable"
            ></path>
            <path
              d="M426.77 358.45c-.24.15-2.24.85-2.52.93-3.2.92-7.36.43-11.13-.13-2.48-.37-5.82-.89-7.66-2.74a3.28 3.28 0 01-.89-3.53c.57-1.26 2.21-2 2.34-3.37-2.7-.9-5.89-.19-8.31-1.7a9.89 9.89 0 01-3.76-4.9 5.35 5.35 0 01-.18-4c.66-1.35 2.39-1.67 3.88-1.9a2.79 2.79 0 001.13-.33 1 1 0 00.5-1c-.11-.48-.62-.72-1.09-.87-1-.32-2-.51-3-.88a4.63 4.63 0 01-2.43-1.86 6.1 6.1 0 01-.46-3.62 52.8 52.8 0 01.88-6.25 40.94 40.94 0 00.5-5.42 38 38 0 0115.78.36c2.86.59 7 1.92 8.25 4.9a5.45 5.45 0 01.32 2.68 9.08 9.08 0 01-.28 1.23c-.06.25-.31 1.24.3.78a1.12 1.12 0 00.3-.41l2-4a2.4 2.4 0 01.8-1.05c.8-.5 3-.11 5.23 1.7a8.7 8.7 0 012.82 4.95c.67 3 .16 6.14-.35 9.18l1.95-2.72c2.29-3.17 3.69 2.63 3.79 3.88a27.48 27.48 0 01-1.81 11.44c-1.58 3.5-3.67 6.6-6.9 8.65z"
              style={{
                WebkitTransformOrigin: 414.308,
                MsTransformOrigin: 414.308,
                transformOrigin: 414.308,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M426.77 358.45c-.24.15-2.24.85-2.52.93-3.2.92-7.36.43-11.13-.13-2.48-.37-5.82-.89-7.66-2.74a3.28 3.28 0 01-.89-3.53c.57-1.26 2.21-2 2.34-3.37-2.7-.9-5.89-.19-8.31-1.7a9.89 9.89 0 01-3.76-4.9 5.35 5.35 0 01-.18-4c.66-1.35 2.39-1.67 3.88-1.9a2.79 2.79 0 001.13-.33 1 1 0 00.5-1c-.11-.48-.62-.72-1.09-.87-1-.32-2-.51-3-.88a4.63 4.63 0 01-2.43-1.86 6.1 6.1 0 01-.46-3.62 52.8 52.8 0 01.88-6.25 40.94 40.94 0 00.5-5.42 38 38 0 0115.78.36c2.86.59 7 1.92 8.25 4.9a5.45 5.45 0 01.32 2.68 9.08 9.08 0 01-.28 1.23c-.06.25-.31 1.24.3.78a1.12 1.12 0 00.3-.41l2-4a2.4 2.4 0 01.8-1.05c.8-.5 3-.11 5.23 1.7a8.7 8.7 0 012.82 4.95c.67 3 .16 6.14-.35 9.18l1.95-2.72c2.29-3.17 3.69 2.63 3.79 3.88a27.48 27.48 0 01-1.81 11.44c-1.58 3.5-3.67 6.6-6.9 8.65z"
              style={{
                WebkitTransformOrigin: 414.308,
                MsTransformOrigin: 414.308,
                transformOrigin: 414.308,
              }}
              className="animable"
              opacity="0.15"
            ></path>
            <path
              d="M420.69 343.47a35 35 0 013.87-14.7l.05-.1a.39.39 0 00-.19-.52.4.4 0 00-.52.18v.11a36.52 36.52 0 00-3.9 13.79c-6.22-10.47-15.33-20.43-20.13-23a.42.42 0 00-.56.17.41.41 0 00.16.57c4.73 2.57 13.73 12.41 19.85 22.75a24 24 0 00-15.6-1.05.39.39 0 00-.25.5.41.41 0 00.31.26.43.43 0 00.19 0 23.17 23.17 0 0116 1.46c2.81 4.92 4.91 9.9 5.53 14.21a.43.43 0 00.28.34.41.41 0 00.19 0 .4.4 0 00.35-.47c-.64-4.43-2.77-9.49-5.63-14.5z"
              style={{
                WebkitTransformOrigin: 412.79,
                MsTransformOrigin: 412.79,
                transformOrigin: 412.79,
              }}
              fill="#FFF"
              className="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 353.95,
          MsTransformOrigin: 353.95,
          transformOrigin: 353.95,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 353.95,
            MsTransformOrigin: 353.95,
            transformOrigin: 353.95,
          }}
        >
          <g
            className="animable"
            style={{
              WebkitTransformOrigin: 353.95,
              MsTransformOrigin: 353.95,
              transformOrigin: 353.95,
            }}
          >
            <path
              d="M352 126.32l3.83-10.88-3.08-8.89v-5.44l9.42 5.44V112l-4.05 10.71a.65.65 0 01-.28.24z"
              style={{
                WebkitTransformOrigin: 357.085,
                MsTransformOrigin: 357.085,
                transformOrigin: 357.085,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M352 126.32l3.83-10.88-3.08-8.89v-5.44l9.42 5.44V112l-4.05 10.71a.65.65 0 01-.28.24z"
              style={{
                WebkitTransformOrigin: 357.085,
                MsTransformOrigin: 357.085,
                transformOrigin: 357.085,
              }}
              className="animable"
              opacity="0.2"
            ></path>
            <path
              d="M329.52 57.88a6.25 6.25 0 012.82-4.9l2.3-1.32a6.25 6.25 0 015.65 0l35.26 20.39a6.26 6.26 0 012.83 4.9v38.8a6.25 6.25 0 01-2.83 4.89l-2.3 1.33a6.23 6.23 0 01-5.64 0l-11.35-6.55-4 10.69a.6.6 0 01-1.09-.11l-4.27-16-14.52-8.41a6.25 6.25 0 01-2.83-4.89z"
              style={{
                WebkitTransformOrigin: 353.95,
                MsTransformOrigin: 353.95,
                transformOrigin: 353.95,
              }}
              fill="#DD26D7"
              className="animable"
            ></path>
            <path
              d="M378.38 115.75a6.25 6.25 0 01-2.83 4.89l-2.3 1.33a6.34 6.34 0 01-5.16.23c1.33.5 2.34-.25 2.34-1.86v-38.8a5.8 5.8 0 00-.83-2.79l7.95-4.59a5.83 5.83 0 01.83 2.79z"
              style={{
                WebkitTransformOrigin: 373.235,
                MsTransformOrigin: 373.235,
                transformOrigin: 373.235,
              }}
              className="animable"
              opacity="0.2"
            ></path>
            <path
              d="M329.61 57.07c.33-1.2 1.42-1.58 2.72-.83l35.28 20.41a5.53 5.53 0 012 2.1l7.94-4.58a5.64 5.64 0 00-2-2.11l-35.26-20.4a6.25 6.25 0 00-5.65 0L332.33 53a6.2 6.2 0 00-2.72 4.07z"
              style={{
                WebkitTransformOrigin: 353.58,
                MsTransformOrigin: 353.58,
                transformOrigin: 353.58,
              }}
              fill="#FFF"
              className="animable"
              opacity="0.3"
            ></path>
          </g>
          <path
            d="M361 92.89a13.43 13.43 0 00-1.38-5.72 11.34 11.34 0 00-4.14-4.9c-2.4-1.42-4.47.36-5.26 3a.26.26 0 01-.51 0c-.71-3.64-2.79-8-5.26-9.47-1.65-1-3.14-.88-4.17 0a5.43 5.43 0 00-1.43 4.08c0 5.84 4 11.46 7.42 17.29l3.67 5.67 3.53-1.45c3.38-1.82 7.47-2.66 7.53-8.5z"
            style={{
              WebkitTransformOrigin: 349.918,
              MsTransformOrigin: 349.918,
              transformOrigin: 349.918,
            }}
            fill="#FAFAFA"
            className="animable"
          ></path>
        </g>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 355.069,
          MsTransformOrigin: 355.069,
          transformOrigin: 355.069,
        }}
      >
        <g
          className="animable"
          style={{
            WebkitTransformOrigin: 355.069,
            MsTransformOrigin: 355.069,
            transformOrigin: 355.069,
          }}
        >
          <path
            d="M407.09 144H407a.38.38 0 01-.23-.18l-6-10.78a.31.31 0 01-.05-.21c.18-2.12.43-5.48.36-6a16.36 16.36 0 00-1.68-3l-.43-.69c-.08-.15-.21-.54.36-1a3.17 3.17 0 013.94-.07 8.7 8.7 0 013.16 4.51l5.34-3a2.16 2.16 0 012-.1 2.67 2.67 0 011.23 1.09 2.58 2.58 0 01.25 2 2.25 2.25 0 011.81 1.21 2.5 2.5 0 01-.17 2.63 3.33 3.33 0 01.88 1.06 2.76 2.76 0 01-.43 2.79 4.13 4.13 0 01.73 1 2.32 2.32 0 01.14 1.87 3 3 0 01-1.39 1.63l-9.53 5.33a.39.39 0 01-.2-.09zm-5.64-11.23l5.78 10.35 9.21-5.15a2.17 2.17 0 001-1.21 1.55 1.55 0 00-.07-1.27 3.58 3.58 0 00-.88-1 .38.38 0 01-.15-.32.4.4 0 01.19-.31 1.91 1.91 0 00.54-2.07 2.2 2.2 0 00-1-1 .37.37 0 01-.23-.28.38.38 0 01.12-.35 1.7 1.7 0 00.35-2.06 1.45 1.45 0 00-1.64-.8.37.37 0 01-.39-.13.38.38 0 010-.42 1.84 1.84 0 000-1.83 1.93 1.93 0 00-.87-.78 1.45 1.45 0 00-1.34.07l-5.76 3.22a.35.35 0 01-.33 0 .37.37 0 01-.22-.25 8 8 0 00-3-4.57 2.32 2.32 0 00-2.16-.38 2 2 0 00-1 .59c.08.13.21.34.37.58a13.15 13.15 0 011.79 3.35c.13.67-.23 5.07-.31 5.98z"
            style={{
              WebkitTransformOrigin: 408.621,
              MsTransformOrigin: 408.621,
              transformOrigin: 408.621,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M402.38 148.5a.39.39 0 01-.33-.19l-7-12.54a.35.35 0 010-.28.36.36 0 01.17-.23l4.16-2.32a.37.37 0 01.51.14l7 12.54a.37.37 0 010 .29.33.33 0 01-.18.22l-4.15 2.33a.39.39 0 01-.18.04zm-6.5-12.77l6.64 11.89 3.5-2-6.64-11.88z"
            style={{
              WebkitTransformOrigin: 400.97,
              MsTransformOrigin: 400.97,
              transformOrigin: 400.97,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M296.08 191.43a3.38 3.38 0 01-.63-.07l-10.7-2.17a.34.34 0 01-.24-.16.35.35 0 01-.06-.28l2.46-12.1a.35.35 0 01.1-.19c1.52-1.49 3.89-3.88 4.19-4.32a16.81 16.81 0 00.7-3.41c.05-.37.09-.66.12-.8s.19-.54.94-.54a3.17 3.17 0 013 2.51 8.72 8.72 0 01-.54 5.48l6 1.22a2.16 2.16 0 011.59 1.23 2.72 2.72 0 01.22 1.63 2.62 2.62 0 01-1.1 1.66 2.22 2.22 0 01.59 2.09 2.49 2.49 0 01-1.84 1.89 3.56 3.56 0 010 1.38 2.45 2.45 0 01-1 1.31 2.55 2.55 0 01-1.19.52 4.42 4.42 0 01-.07 1.21 2.33 2.33 0 01-1.13 1.51 2.84 2.84 0 01-1.41.4zm-10.82-2.9l10.33 2.1a2.29 2.29 0 001.58-.24 1.6 1.6 0 00.77-1 3.81 3.81 0 000-1.37.39.39 0 01.1-.34.4.4 0 01.34-.11 1.47 1.47 0 001.09-.36 1.75 1.75 0 00.67-.86 2.17 2.17 0 00-.07-1.37.38.38 0 010-.37.42.42 0 01.32-.19 1.68 1.68 0 001.6-1.34 1.43 1.43 0 00-.72-1.66.39.39 0 01-.21-.36.38.38 0 01.25-.33 1.89 1.89 0 001.19-1.4 1.86 1.86 0 00-.16-1.16 1.44 1.44 0 00-1.06-.82l-6.44-1.35a.38.38 0 01-.26-.2.38.38 0 010-.33 8 8 0 00.68-5.45 2.32 2.32 0 00-1.39-1.69 2 2 0 00-1.13-.19c0 .15-.06.39-.1.68a13.42 13.42 0 01-.82 3.71c-.41.62-3.55 3.72-4.2 4.36z"
            style={{
              WebkitTransformOrigin: 293.856,
              MsTransformOrigin: 293.856,
              transformOrigin: 293.856,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M283 190.52h-.07l-4.67-.94a.38.38 0 01-.29-.44l2.8-14.14a.39.39 0 01.45-.29l4.66.95a.36.36 0 01.24.16.39.39 0 01.05.28l-2.85 14.08a.4.4 0 01-.16.24.39.39 0 01-.16.1zm-4.22-1.61l3.93.79 2.7-13.34-3.93-.8z"
            style={{
              WebkitTransformOrigin: 282.071,
              MsTransformOrigin: 282.071,
              transformOrigin: 282.071,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M412.36 194.33a.36.36 0 01-.22-.07l-2.3-1.73c-3.11-2.34-6.71-6-6.17-10.23A5.38 5.38 0 01414 181a5.38 5.38 0 019.66 3.87c-.53 4.15-4.91 6.82-8.49 8.34l-2.65 1.12zm-3.37-16a4.63 4.63 0 00-4.58 4.05c-.49 3.86 2.93 7.31 5.88 9.53l2.13 1.6 2.45-1c3.4-1.44 7.55-4 8-7.74a4.63 4.63 0 00-8.69-2.73.36.36 0 01-.38.2.37.37 0 01-.32-.28 4.65 4.65 0 00-3.92-3.55 2.53 2.53 0 00-.56-.06z"
            style={{
              WebkitTransformOrigin: 413.659,
              MsTransformOrigin: 413.659,
              transformOrigin: 413.659,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M423.4 204.32a.37.37 0 01-.31-.15l-1-1.43c-1.44-2-2.92-4.84-1.82-7.3a3.47 3.47 0 016.58.88 3.47 3.47 0 013.28-.23 3.45 3.45 0 011.74 4.57c-1.09 2.42-4.2 3.23-6.63 3.48l-1.77.18zm0-10.17a2.73 2.73 0 00-2.47 1.6c-1 2.13.41 4.73 1.73 6.54l.92 1.26 1.55-.16c2.23-.23 5.09-.95 6-3a2.72 2.72 0 00-4.41-3 .39.39 0 01-.42.08.37.37 0 01-.22-.37 2.74 2.74 0 00-1.6-2.66 2.67 2.67 0 00-1.09-.29z"
            style={{
              WebkitTransformOrigin: 426.031,
              MsTransformOrigin: 426.031,
              transformOrigin: 426.031,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M295.85 120.58h-.05l-2.85-.43c-3.84-.58-8.75-2.11-10.28-6.08a5.38 5.38 0 018.45-6.07 5.39 5.39 0 013.39-4.25 5.38 5.38 0 016.95 3.08c1.5 3.9-1.08 8.33-3.51 11.36l-1.8 2.25a.39.39 0 01-.3.14zm-8.17-13.06a4.77 4.77 0 00-1.66.3 4.63 4.63 0 00-2.65 6c1.4 3.64 6 5.06 9.69 5.61l2.64.39 1.66-2.08c2.31-2.87 4.77-7 3.4-10.62a4.63 4.63 0 00-6-2.65 4.63 4.63 0 00-3 4.38.38.38 0 01-.24.35.37.37 0 01-.42-.1 4.64 4.64 0 00-3.42-1.58z"
            style={{
              WebkitTransformOrigin: 292.11,
              MsTransformOrigin: 292.11,
              transformOrigin: 292.11,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
          <path
            d="M310.31 124.14a.35.35 0 01-.17 0l-1.6-.76c-2.2-1-4.86-2.87-5.05-5.56a3.42 3.42 0 01.83-2.51 3.46 3.46 0 015.37.16 3.47 3.47 0 016.48 1.44c.19 2.64-2.17 4.83-4.19 6.21l-1.46 1a.42.42 0 01-.21.02zm-3.37-9.32h-.19a2.66 2.66 0 00-1.86.93 2.7 2.7 0 00-.66 2c.17 2.32 2.61 4 4.63 4.93l1.41.68 1.29-.88c1.85-1.27 4-3.25 3.86-5.53a2.66 2.66 0 00-.93-1.86 2.72 2.72 0 00-2-.66 2.76 2.76 0 00-2.41 2 .37.37 0 01-.7 0 2.73 2.73 0 00-2.44-1.61z"
            style={{
              WebkitTransformOrigin: 309.83,
              MsTransformOrigin: 309.83,
              transformOrigin: 309.83,
            }}
            fill="#DD26D7"
            className="animable"
          ></path>
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
