import StepWizard from "react-step-wizard";
import CreatorAcceptTerms from "./CreatorAcceptTerms";
import CreatorSocials from "./CreatorSocial";
import "./CreatorSetup.css";
import { useEffect, useRef, useState } from "react";
import { fetchGet } from "../util/helpers";

export default function CreatorSetup() {
  const wizardInstance = useRef(null);
  const [isAccepted, setIsAccepted] = useState(false);

  const fetchAcceptedStatus = async () => {
    try {
      const acceptedData = await fetchGet("/creator/terms");
      if(acceptedData.data){
        wizardInstance.current.goToStep(2); 
      }
      setIsAccepted(acceptedData.data)
    } catch (e) {
      console.log("Erorr while fetching accepting Terms", e);
    }
  }
  useEffect(()=>{
    fetchAcceptedStatus();
  },[]);
  return (
    <div style={{ paddingTop: 5, maxWidth: "100vw" }}>
      <StepWizard isLazyMount className="wizard" ref={wizardInstance}>
        <CreatorAcceptTerms isAccepted={isAccepted}/>
        <CreatorSocials />
      </StepWizard>
    </div>
  );
}
