import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import LineChart from "../components/LineChart";
import FileUpload from "../components/FileUpload";
import host from "../assets/host.jpg";
import { ReactComponent as EmptyAnalytics } from "../assets/empty-analytics.svg";
import { INDUSTRIES } from "../util/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function QA(props) {
  const { question, answer } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </Accordion>
  );
}

export default function FrequentlyAskedQuestions() {
  const navigate = useNavigate();

  return (
    <>
     
      <Grid container style={{padding: "3%"}}>
        <Typography variant="h3" color={"var(--main-text-color)"}>Support & FAQ</Typography>
        <div style={{ width: "100%" }}>
          <Grid container>
            

            <Grid item xs={9} style={{ marginTop: 50 }}>
              <Typography>
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  1. Want to receive custom applications from creators who love
                  your brand?{" "}
                </span>
                <br />
                <br />
                Set your campaign as “public” by clicking the button on the top
                right of your campaigns page. You can always make your campaign
                private again and update your campaign details at any point. It
                can take up to 48 hours to start seeing creator applications
                roll in. Naturally, the longer your campaign is open the more
                applications you will receive.
                <br />
                <br />
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  2. Want to Discover More Amazing Creators? Identify your next
                  match in your Discovery Section!{" "}
                </span>
                <br />
                <br />
                Outreach to new creators at any time, inviting them to apply for
                your campaign. Filter based on creator handle, level, industry,
                and follower count.
                <br />
                <br />
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  3. How do you Outreach to new creators?{" "}
                </span>
                <br />
                <br />
                Send a customized email in seconds to invite new creators to
                work with you on this campaign. Our standardized emails are
                recognized by creators to drive engagement for your campaign.
                Check it out here.
                <br />
                <br />
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  4. How do I partner with a creator, and what happens next?{" "}
                </span>
                <br />
                <br />
                Select your creator match from your applications. On the creator
                application, select “partner”. Creators that you are matched
                with will glow. This partnership will unlock your Creator
                Workstream Section. Visit this page and the creator will be
                waiting for your first message. Say “Hi” and share any
                additional campaign details to get started!
                <br />
                <br />
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  5. How do I modify my creator brief?
                </span>
                <br />
                <br />
                Download the brief, and convert it to a Word doc to modify. Then
                send your custom brief to creators by uploading it in your
                creator workstream section.
                <br />
                <br />
                <br />
                <br />
                <span style={{ fontWeight: 600 }}>
                  Have More Questions? See Below, or contact us
                  support@giglgroup.com
                </span>
                <br />
                <br />
              </Typography>

              <QA
                question="What are the standard rates for influencer marketing?"
                answer={
                  <>
                    Visit your Analytics Dashboard to see the most recent data
                    on paid partnership rates.
                    <br />
                    Please Note: these rates can vary based on the influencer
                    and their set rates. Pricing can also change based on
                    factors such as, but not limited to, usage rights,
                    exclusivity, link in bio, campaign length, and timeline
                    rush.
                  </>
                }
              />

              <QA
                question="What if I want only to launch a gifted / merchandise campaign?"
                answer={`When launching your campaign, select "merchandise campaign." You can also outline the details of your merchandise campaign in the "details" section specifying what type of merchandise and the merchandise value you will provide in this campaign.`}
              />
              <QA
                question="How do I launch an influencer marketing campaign?"
                answer={
                  <>
                    Select the "launch campaign" button at the top of your
                    dashboard and fill out the form with all the details of the
                    campaign you would like to launch. Once your campaign is
                    launched you can go to your campaign page and select "Make
                    Public." This will make your campaign public to creators.
                    <br />
                    <br />
                    You can keep your campaign public for as long as you would
                    like, and wait for creators to show interest in your
                    campaign. The creators that are interested in partnering
                    with you will be shown in your "Featured for You" creators
                    section and under "More creators that love your brand" You
                    can then select the creators you would like to partner with
                  </>
                }
              />

              <QA
                question="What is the difference between Featured Creators and More Creators who love my brand?"
                answer={
                  <>
                    Featured creators for you are ones that according to your
                    brand campaign are a great fit and match your criteria. Or
                    have submitted a custom application to stand out because
                    they really love your brand!
                    <br />
                    <br />
                    More creators who love your brand have directly applied to
                    your campaign, however, may not exactly match all your
                    criteria. The creators may still be a great fit for your
                    campaign, so you could find your perfect influencer match in
                    this pool of creators!
                  </>
                }
              />

              <QA
                question="Do I need a marketing brief for an influencer marketing campaign?"
                answer={
                  <>
                    Having a marketing brief is completely up to you! Some
                    brands prefer having a brief for creators to work with.
                    However, other brands simply prefer to share the campaign
                    guidelines and details via communications with the creator.
                    <br />
                    <br />
                    At Gigl, we help you skip the paperwork! You can generate a
                    custom marketing brief to send creators with just one click!
                    Select the "Generate Brief" button at the top right corner
                    of your campaign page.
                  </>
                }
              />

              <QA
                question="What if I already have specific creators that I am working with for this campaign?"
                answer={
                  <>
                    When launching your campaign you can select “Private
                    Campaign” and outline the creator codes of the specific
                    creators you are working with. Each creator on Gigl has a
                    unique creator code. Ask them to provide you with their
                    specific creator code and input that in the “creator code
                    field.” Once you launch that campaign you will automatically
                    be partnered with those creators.
                  </>
                }
              />

              <QA
                question="How do I cancel my monthly susbscription?"
                answer={
                  <>
                    Email us at support@giglgroup.com saying 1. Your account
                    email, 2. You would like to cancel your subscription. Our
                    team will cancel your subscription moving forward. We thank
                    you for using Gigl.
                  </>
                }
              />

              <Typography
                variant="h4"
                style={{
                  paddingTop: 50,
                  paddingBottom: 100,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Have more questions? Contact Us: support@giglgroup.com
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
