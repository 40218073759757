import { memo, useEffect, useState, useContext } from "react";
import CreatorStepper from "./CreatorStepper";
import Terms from "../Terms";
import { useMediaQuery } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { fetchPost, fetchGet } from "../util/helpers";
import UserContext from "../contexts/UserContext";



function CreatorAcceptTerms(props) {
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery("(max-width:800px)");
  const {isAccepted} = props;
  const handleAccept = async () => {
    try {
      await fetchPost("/creator/terms");
      props.nextStep();
    } catch (e) {
      console.log("Erorr while accepting Terms", e);
      toast.error("Something went wrong! Please, try again.")
    }
  }
  
  return <>
    <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

    <div style={{ margin: "0 auto" }}>
      <div style={{ height: "70vh", overflowY: "scroll" }}>
        <Terms />
      </div>
      <div style={{ display: "flex", flexDirection: `${isMobile ? "column" : "row"}`, alignItems: "center", padding: "0 25%" }}>
        <div>
          <CreatorStepper {...props} nextStep={handleAccept} canContinue={!isAccepted} continueText="I accept" />
        </div>
        {isAccepted && <div style={{ cursor: "pointer",textDecoration:"underline" }} onClick={props.nextStep}>Continue</div>}
      </div>
    </div>
  </>
}

export default memo(CreatorAcceptTerms);
