export default function LimitedUse() {
  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      Gigl's use and transfer to any other app of information received from
      Google APIs will adhere to{" "}
      <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
        Google API Services User Data Policy
      </a>
      , including the Limited Use requirement.
    </div>
  );
}
