import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import LineChart from "../components/LineChart";
import FileUpload from "../components/FileUpload";
import host from "../assets/host.jpg";
import { ReactComponent as EmptyAnalytics } from "../assets/empty-analytics.svg";
import { INDUSTRIES } from "../util/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function QA(props) {
  const { question, answer } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </Accordion>
  );
}

export default function SocialTrends() {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          textAlign: "left",
          marginTop: 5,
          color: "white",
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", marginLeft: "15vw" }}
        >
          Social Listening & Trend Analysis
        </Typography>
      </div>

      <Grid container>
        <div style={{ width: "90vw" }}>
          <Grid container>
            <Grid item xs={2} style={{ textAlign: "left" }}>
              <Box
                sx={{
                  textAlign: "center",
                  boxShadow: 1,
                  minHeight: 1350,
                  width: "12vw",
                }}
                alignItems="left"
              >
                <Button
                  basic
                  style={{
                    marginTop: 50,
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns")}
                >
                  My Dashboard
                </Button>

                <Button
                  basic
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  Blog & Insights
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  Analytics
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 1000,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  Social Trends
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  Help & FAQ
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  My Account
                </Button>
              </Box>
            </Grid>

            <Grid item xs={9} style={{ marginTop: 50 }}>
              <Typography>
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  Coming Soon{" "}
                </span>
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
