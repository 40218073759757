import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import contentHub from "./assets/content_hub.PNG";
import launch from "./assets/launch.jpg";
import blogpic1 from "./assets/blogpic1.PNG";
import blogpic2 from "./assets/blogpic2.PNG";
import blogpic3 from "./assets/blogpic3.PNG";
import blogpic4 from "./assets/blogpic4.PNG";

import * as React from "react";
import Button from "./components/Button";
import { useNavigate } from "react-router-dom";

export const BLOG_ARTICLES = [
  {
    title: "Basic",
    subheader: "Standard Plan",
    price: "45.99",
    description: [
      "Limited time: first month free",
      "1-2 campaigns per month",
      "Standard Analytics and Reporting",
      "Creator Access",
      "Help Support",
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
    id: "basic",
  },
  {
    title: "Advantage",
    subheader: "Most popular",
    price: "199.98",
    description: [
      "4 Campaigns per month",
      "Standard Analytics and Reporting",
      "Creator Access",
      "Help Support",
      "Invoicing",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
    id: "advantage",
  },
  {
    title: "Enterprise & Agency",
    description: [
      "Unlimited Campaigns",
      "Advanced Analytics and Reporting",
      "Creator Access",
      "Help Support",
      "Invoicing",
      "Collaboration Studio",
      "Account Team",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    requireContactUs: true,
    id: "enterprise",
  },
];

export default function FAQ() {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          marginTop: 20,
          display: "block",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            float: isMobile ? "" : "left",
            maxWidth: isMobile ? "100vw" : "50vw",
            marginLeft: isMobile ? 0 : 100,
            textAlign: isMobile ? "center" : "left",
            height: isMobile ? 200 : 400,
          }}
        >
          <Typography
            style={{
              fontSize: isMobile ? 12 : 25,
              marginTop: isMobile ? 0 : 40,
            }}
          >
            For Business & Creators
          </Typography>
          <div
            style={{ marginTop: -10, textAlign: isMobile ? "center" : "left" }}
          >
            <Typography
              style={{
                fontSize: isMobile ? 20 : 45,
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              The <span style={{ color: "orange" }}>Gigl</span>
            </Typography>
            <Typography
              style={{ fontSize: isMobile ? 20 : 45, fontWeight: 600 }}
            >
              Content <span style={{ color: "black" }}>Hub</span>.
            </Typography>
          </div>
          <p
            style={{
              width: isMobile ? "80vw" : "40vw",
              ...(isMobile && { margin: "0 auto" }),
              fontSize: isMobile ? 10 : 18,
              marginTop: isMobile ? 10 : 10,
              width: isMobile ? "65%" : "70%",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            Get the latest expert advice from thought leaders, tips, tricks and
            insights on everything creator and influencer marketing. We are your
            trusted source.
          </p>
        </div>

        {isMobile ? (
          <p></p>
        ) : (
          <>
            <Grid
              elevation={3}
              sx={{
                height: isMobile ? 340 : 420,
                float: isMobile ? "" : "right",
                borderRadius: 10,
                marginBottom: 10,
                width: "40vw",
              }}
            >
              <img
                src={contentHub}
                height="100%"
                style={{
                  borderRadius: 20,
                  maxHeight: isMobile ? 400 : 500,
                  maxWidth: isMobile ? "40vw" : "",
                  ...(isMobile && { marginTop: 10 }),
                }}
              ></img>
            </Grid>
          </>
        )}
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          paddingTop: isMobile ? 1 : 0,
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "rgb(0 0 0)", minHeight: isMobile ? 40 : 60 }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 300 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          Tips on how to go viral on social media in 2024
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          How can businesses go Viral on Social Media? I’m often asked this
          question, so here are 3 Ways You Can Go Viral. Ride the Trend Wave:
          Taking part in social media trends helps you increase reach...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - Carlson | Gigl Expert
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic1}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 0 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10 }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 300 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          3 Tips to launch a successful social media campaign, according to top
          creators
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          We asked @feeedmi, one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media. Her
          channel has over 1.4 Million followers and 1 Billion views...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - @feeedmi | 1.4 Million Followers | Top Gigl Creator
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle2")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic2}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 30 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
          marginTop: 5,
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10 }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 300 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          How to measure influencer marketing: Key Tips & Strategies
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          There are many ways to measure the success of your influencer
          marketing campaign, and they largely depend on your goals - Is it
          brand awareness? Sales? Engagement...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - Disha | Gigl Expert
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle3")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic3}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 0 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          marginTop: 5,
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "black",

          minHeight: 10,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 300 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          How Influencer Marketing Works: Answering Your Questions
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          What is influencer marketing? Influencer marketing is the process of
          partnering with a content creator/influencer to market your brand.
          It's a powerful way to drive organic reach and engagement...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - Disha | Gigl Expert
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle4")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic4}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 0 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          marginTop: 5,
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 20, marginBottom: 20 }}
      ></Grid>

      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
