import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, TextField, InputAdornment, Chip, FormControlLabel, Box } from '@mui/material';
import { useState } from 'react';
import { Search as SearchIcon, Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import { fetchPost } from '../util/helpers';
import toast from "react-hot-toast";
import { IconArrowLeft } from '@tabler/icons-react';

function InviteFromListDialog({open, onClose, handleAddToLists, campaignId}) {
    const [myList, setMyList] = useState(JSON.parse(localStorage.getItem("myList")) || []);
    const [search, setSearch] = useState("");
    const [selectedCreators, setSelectedCreators] = useState([]);
    const [selectedMyList, setSelectedMyList] = useState(null);
    const handleListSelection = (platform) => {
        setSelectedMyList(platform);
      }

      const sendEmail = () => {
        for(let creator of selectedCreators){
        fetchPost(`/brand/outreach`, {
          campaignId: campaignId,
          email: creator,
        })
          .then(() => {
            toast.success(
              `Successfully sent an email to ${creator}. Check the status of this email in your outreach table.`,
            );
            onClose();
          })
          .catch(() => {
            toast.error(
              `Failed to send an email to ${creator}. Please check back later.`,
            );
          });
        }
      };
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>{
            selectedMyList ?<>
            <DialogTitle> <div className="dialog-header">
            <IconArrowLeft style={{cursor: "pointer", marginRight: 5}} onClick={() => {
              setSelectedMyList(null)
              setSelectedCreators([])
            }}/>
            <div className="title-main">
            Add From {selectedMyList.name}
            </div>
            <div className="title-sub">
              ({selectedMyList.creators.length} Creators)
            </div> 
          </div>
          
          </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent dividers>
            
          <div className="my-list-content">
            

            {
              selectedMyList.creators.map((creator, index) => {
                return <div key={index} >
                   <div className={`checkbox-container`}> <FormControlLabel
                    key={index}
                    control={
                      <input type="checkbox" className="checkbox" checked={selectedCreators.includes(creator)} value={creator} onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCreators(prev => [...prev, creator])
                        } else {
                          setSelectedCreators(prev => prev.filter(c => c !== creator))
                        }
                      }} />
                    }
                    label={creator}
                   
                  /> </div>
                </div>

              })
            }

         
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="custom-button" onClick={onClose}>Cancel</Button>
          <Button className="custom-button grd-bg-btn" onClick={sendEmail}>Add To Lists</Button>
        </DialogActions>
            </> :
            <><DialogTitle> <div className="dialog-header">
            <div className="title-main">
            Add From My List
            </div>
            <div className="title-sub">
              ({myList.length} List Available)
            </div> 
          </div></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="my-list-content">
            <TextField
              fullWidth
              className="name-search"
              onChange={(e) => {
              }}
              sx={{marginBottom:"1rem"}}
              size="small"
              placeholder="Search Lists..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{
                      fontSize: "20px"
                    }} />
                  </InputAdornment>
                ),
              }}
            />


            {
              myList.map((platform, index) => {
                return <div key={index} >
                  <Box
                  onClick={() => handleListSelection(platform)}
                  justifyContent="space-between"
                  style={{cursor: "pointer", border: "1px solid #D0D5DD", borderRadius: "6px", padding: 8}} display="flex" alignItems="center" width="100%" className={`checkbox-container`}> { platform.name || platform} 
                  <Chip 
                  size="small"
                  sx={{
                    backgroundColor: '#F2F4F7'
                  }} label={`${platform.creators.length} Creators`} />
                  </Box>
                </div>

              })
            }

         
          </div>
        </DialogContent>
       </>
        }
        
      </Dialog>
    );
}

export default InviteFromListDialog;