import { memo } from "react";
import FormInput from "./FormInput";
import Stepper from "./Stepper";
import { Typography } from "@mui/material";
import Terms from "../Terms";
function AcceptTerms(props) {
  console.log("props", props);
  return (
    <div style={{ margin: "0 auto" }}>
      <div style={{ height: "70vh", overflowY: "scroll" }}>
        <Terms />
      </div>
      <Stepper {...props} canContinue={true} continueText="I accept" />
    </div>
  );
}

export default memo(AcceptTerms);
