import { TextField } from "@mui/material";
import { useState } from "react";
import Button from "../components/Button";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../util/firebase";
import toast, { Toaster } from "react-hot-toast";

export default function Unsubscribe(props) {
  const [email, setEmail] = useState("");
  const handleSubmit = async () => {
    try {
      await addDoc(collection(db, "mailing_unsubscribe"), {
        email: email,
        submittedAt: new Date(),
      });
      toast.success("We're sorry to see you go. You've been unsubscribed");
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong, please try again later");
    }
  };
  return (
    <>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

      <div style={{ width: 500, margin: "0 auto" }}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button style={{ width: "100%", marginTop: 10 }} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </>
  );
}
