import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import { signInWithGoogle, signUpWithEmail, creatorSignUpWithEmail, fetchPut } from "../util/helpers";
import { TextField, Typography, FormControl, Select, MenuItem,TextareaAutosize, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchPost, getBase64 } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import FileUpload from "../components/FileUpload";
import { INDUSTRIES, COUNTRIES } from "../util/constants";
import LoadingContext from "../contexts/LoadingContext";




const AutoInviteRegister = () => {
  const { setIsLoading } = useContext(LoadingContext);

    const navigate = useNavigate();
    const { authed, refreshUser, user } = useContext(UserContext);
    const [isMatched,setIsMatched] = useState(false);
    const [newCreatorProfile, setNewCreatorProfile] = useState({name:"",email:"",password:"",description:"",industries:[],imageBlobs:[]});
    const { campaignId } = useParams();


    const matchUser = async () => {
        try {
            const baseImageList = [];
            for(let i = 0; i<newCreatorProfile.imageBlobs.length;i++){
                const data = await getBase64(newCreatorProfile.imageBlobs[i]);
                baseImageList.push({blob:data,format:newCreatorProfile.imageBlobs[i].name.split('.').pop()})
            }
            await fetchPut(`/creator/users`, { ...newCreatorProfile,imageBlobs:baseImageList}, setIsLoading)
            await fetchPost(`/brand/campaigns/${campaignId}/matches`, {
                creatorUserDocId: user.userDocId
            });

            toast.success("You have matched!");
            setIsMatched(true);

        } catch (e) {
            console.log(e);
            toast.error("Error while matching Campaign");
        }

    }

    useEffect(() => {
        if (authed) {
            matchUser();
        }
    }, [authed]);

    useEffect(()=>{
        const timer = setTimeout(() => {
            if(isMatched) navigate("/creators");
        }, 2000);

        return () => clearTimeout(timer);
        
    },[isMatched])

    const handleChange = (name, value) => {
        setNewCreatorProfile((prev) => ({
            ...prev,
            [name]: value
        }));
    }
    return <>
        <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

        <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
            <div style={{ display: "flex", flexDirection: "column", width: "50%", gap: 15 }}>


                <Typography variant="h4">Create Your Profile</Typography>
                <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Full Name</Typography>
                <TextField
                    type="name"
                    name="name"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.name}
                    placeholder="Enter full name"
                />

                <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Email</Typography>
                <TextField
                    type="email"
                    name="email"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.email}
                    placeholder="Enter email"
                />
                <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Password</Typography>
                <TextField
                    type="password"
                    name="password"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.password}
                    placeholder="Enter strong password"
                />

                <Typography sx={{ textAlign: "left", fontWeight: 800, }}>Description</Typography>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        '&:hover': {
                            border: "1px solid black"
                        },
                        '&:focus-within': {
                            border: "2px solid #1976d2"
                        },
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '4px',
                    }}
                >
                    <TextareaAutosize
                        name="description"
                        required
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        fullWidth
                        style={{ width: "100%", padding: 10, border: 'none', outline: 'none', boxSizing: 'border-box', }}
                        minRows={5}
                        value={newCreatorProfile.description}
                        placeholder="Share more details about you"
                    />
                </Box>
                {/* <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    Region
                </Typography>
                <FormControl required style={{ width: "100%" }}>
                    <Select
                        value={newCreatorProfile.region || []}
                        name="region"
                        required
                        onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: "grey" }}>Select regions</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {COUNTRIES.map((country) => ({
                            label: country,
                            value: country,
                        })).map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography sx={{ textAlign: "left", fontWeight: 800 }}>
                    What City Are You Based In?
                </Typography>
                <TextField
                    type="city"
                    name="city"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.city}
                    placeholder="Enter city"
                />

                <Typography sx={{ textAlign: "left", fontWeight: 800 }}>
                    Date of Birth (mm/dd/yyyy){" "}
                </Typography>
                <TextField
                    type="birthday"
                    name="birthday"
                    fullWidth
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={newCreatorProfile.birthday}
                    placeholder="Enter DOB in mm/dd/yyyy formate"
                /> */}


                <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    What is the focus of your content?
                </Typography>
                <FormControl required style={{ width: "100%", }}>
                    <Select
                        value={newCreatorProfile.industries || []}
                        name="industries"
                        required
                        onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: "grey" }}>Select industries</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {INDUSTRIES.map((item) => (
                            <MenuItem value={item} key={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    Gender
                </Typography>

                <FormControl required style={{ width: "100%" }}>
                    <Select
                        value={newCreatorProfile.gender || []}
                        name="gender"
                        required
                        onChange={(e) => handleChange(e.target.name, typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: "grey" }}>Select gender</em>;
                            }
                            return selected.join(', ');
                        }}
                    >
                        {[
                            { label: "Female", value: "female" },
                            { label: "Male", value: "male" },
                            { label: "Non-Binary", value: "non-binary" },
                        ].map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left", fontWeight: 800
                    }}
                >
                    Upload 2-3 Profile Pictures that brands can see
                </Typography>
                <FileUpload
                    name="imageBlobs"
                    containerProps={{
                        style: { width: "90%", marginBottom: 20, textAlign: "center" },
                    }}
                    handleFilesChange={async(files) => {
                        if (files !== null && files?.length > 0) {
                            // setNewCreatorImage(files[0]);
                            handleChange("imageBlobs", [...files]);
                        }
                    }}
                />
                <Button
                    appearance="default"
                    marginTop={10}
                    onClick={async () => {
                        try {
                            const result = await creatorSignUpWithEmail(newCreatorProfile.email, newCreatorProfile.password);
                            await refreshUser(result, true);
                        } catch (err) {
                            alert(err);
                        }
                    }}
                    style={{ width: "100%" }}
                >
                    Get Started!
                </Button>
                <hr
                    style={{
                        width: 350,
                        height: 1,
                        border: 0,
                        borderTop: "1px solid #ccc",
                        padding: 0,
                    }}
                />

                <Typography
                    variant="body2"
                    sx={{ textAlign: "center", marginBottom: 20, fontweight: 600 }}
                >
                    Already have an account?{" "}
                    <span
                        style={{ cursor: "pointer", color: "#004bb9" }}
                        onClick={() => navigate(`/match/${campaignId}`)}
                    >
                        {" "}
                        Sign in now
                    </span>
                </Typography>

            </div>
        </div>
    </>
}

export default AutoInviteRegister;