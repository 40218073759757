import { formatStat, getRandomNumber } from "../util/helpers";
import { Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { IconMapPin, IconUsersGroup } from "@tabler/icons-react";
import { useMemo } from "react";

export default function CreatorCarousel({creators, title}) {
    const sortedCreators = useMemo(() => {
        return creators?.sort((a, b) => {
            let reachB = b.reach;
            if (!Number.isInteger(reachB)) {
                reachB = 0;
            }
            let reachA = a.reach;
            if (!Number.isInteger(reachA)) {
                reachA = 0;
            }
            return reachB - reachA;
        });
    }, [creators]);
    console.log({sortedCreators});

    return (
        <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Box className="gradient-circle" sx={{padding: "8px", alignItems: "center", justifyItems: "center"}}>
                            <IconUsersGroup size="20" className="icon"/>
                        </Box>
                        <Typography variant="h3">{title}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                    {sortedCreators && sortedCreators.slice(0, 5).map((creator, index) => {
                        return (
                            <Card key={index} sx={{ width: "230px", borderRadius: "var(--radius-xl)", boxShadow: "none"}}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="140"
                                image={creator.profileImage ? creator.profileImage : `../gradient${getRandomNumber()}.png`}
                                alt="creator profile image"
                              />
                              <CardContent>
                                <Stack directin="column" spacing={2}>
                                <Stack direction="column">
                                    <Typography variant="h5" component="div">
                                        {creator.handle}
                                    </Typography>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <IconMapPin className="icon-colored" size="12" sx={{color: "var(--body-text-color-600)"}}/>
                                        {(creator.city || creator?.location?.length > 0) && <Typography variant="bodyxs" color="var(--body-text-color-600">
                                             {creator.city}{creator?.city && creator?.location?.length > 0 && ", "} {creator?.location?.join(", ")}
                                        </Typography>}
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-between" width="100%">
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="bodyxs" color="var(--body-text-color-500)">Followers</Typography>
                                        <Typography variant="bodys" color="var(--main-text-color)">{creator.followers}</Typography>
                                    </Stack>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="bodyxs" color="var(--body-text-color-500)">Reach</Typography>
                                        <Typography variant="bodys" color="var(--main-text-color)">{formatStat(creator.reach)}</Typography>
                                    </Stack>
                                </Stack>
                                </Stack>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        )}
                    )}
                    </Stack>
                </Stack>
    )
}