import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar from '@mui/material/AppBar';

import Button from "./Button";
import { styled, useTheme } from '@mui/material/styles';
import { useContext, useState } from "react";
import { Menu } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import { logout } from "../util/helpers";
import { ReactComponent as DashboardIcon} from "../assets/dashboard-icon.svg";
import { ReactComponent as CampaignsIcon} from "../assets/my-campaigns-icon.svg";
import { ReactComponent as CreatorsIcon} from "../assets/discover-creators-icon.svg";
import { ReactComponent as TrendingIcon} from "../assets/trending-icon.svg";
import { ReactComponent as MyListsIcon} from "../assets/my-list-icon.svg";
import { ReactComponent as InsightsIcon} from "../assets/insights-icon.svg";
import { ReactComponent as PlusIcon} from "../assets/plus-icon.svg";
import { IconChartDots, IconDeviceDesktopAnalytics, IconSpeakerphone, IconUsers } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
const drawerWidth = 260;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));
const Layout = ({children}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { authed, user } = useContext(UserContext);
  const isDesktop = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const loginRedirect = () => {
    window.location.href = "/login";
  };
  console.log("user", user);
  if (!user) return <></>;
  let content = null;
  if (user.type === "creator") {
    content = (
      <List>
      {[{label: "Dashboard", icon: IconDeviceDesktopAnalytics, path: '/creators'}, 
      {label: "My Account", icon: IconUsers, path: '/creators/account'}, 
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{backgroundColor: window.location.pathname === item.path ? "var(--main-bg-color)" : "", borderRadius: "var(--radius-lg)", marginx: 1}}>
          <ListItemButton onClick={() => navigate(item.path)}>
            <ListItemIcon>
              {<item.icon className="icon-colored" size="24" style={{stroke: window.location.pathname === item.path ? theme.palette.secondary.main : "black"}} />}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>)
  } else {
    content =  (<>
    <Button  
    style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 10, marginBottom: 10}} gradient onClick={() => navigate("/campaigns/create")}>
      <PlusIcon style={{marginRight: 5}}/> 
      Create Campaign
    </Button>
    <List>
      {[{label: "Dashboard", icon: IconDeviceDesktopAnalytics, path: '/dashboard'}, 
      {label: "My Campaigns", icon: IconSpeakerphone, path: '/campaigns'}, 
      {label: "Discover Creators", icon: IconUsers, path: '/campaigns/creatordiscovery'},
      {label: "Landscape & Trends", icon: IconChartDots, path: '/competitive/landscape'},
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{backgroundColor: window.location.pathname === item.path ? "var(--main-bg-color)" : "", borderRadius: "var(--radius-lg)", marginx: 1}}>
          <ListItemButton onClick={() => navigate(item.path)}>
            <ListItemIcon>
              {<item.icon className="icon-colored" size="24" style={{stroke: window.location.pathname === item.path ? theme.palette.secondary.main : "black"}} />}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    </>)
  }

  return <>
<Box sx={{ display: 'flex' }}>
<AppBar
          position="fixed"
          style={{
            background: "white",
            height: 65,
          }}
          sx={{boxShadow: "none"}}
        >
          <Toolbar style={{ width: "100%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Menu sx={{color: "black"}} />
          </IconButton>
            <Typography
              variant="h4"
              component="div"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => (window.location.href = "/")}
            >
              GIGL
            </Typography>
            <div
              style={{
                marginLeft: "auto",
                color: "black",
                cursor: "pointer",
                display: "flex",
              }}
            >
              {/* {(!authed || window.location.pathname === "/") && (
                <Typography
                  variant="body"
                  onClick={() => {
                    window.location.href = "/pricing";
                  }}
                  style={{ marginRight: isDesktop ? 50 : 10, fontSize: 12 }}
                >
                  Pricing
                </Typography>
              )} */}

              {/* <Typography
              variant="body"
              sx={{marginRight: 5}}
                onClick={
                 () => {
                  window.location.href = "/creatorhome";
                 }
                }
                style={{ marginRight: isDesktop ? 50 : 10, fontSize: 12 }}
              >
                Creator Hub
              </Typography>  */}

           

       
              <Typography
                style={{ fontSize: 12 }}
                onClick={
                  authed
                    ? () => {
                        logout();
                      }
                    : loginRedirect
                }
              >
                {authed ? "Logout" : "Login"}
              </Typography>
            </div>
          </Toolbar>
          <Divider />
        </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader style={{height: 64}}>
          <Typography variant="h4" style={{color: "black", textAlign: "center", marginRight: "20%"}}>GIGL</Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon /> 
          </IconButton>
        </DrawerHeader>
        <Divider />
       {content}
        
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div style={{ backgroundColor: "#f5f5ff"}}>
       {children}
       </div>
      </Main>
      
    </Box>
    {/* <Box
      sx={{
        textAlign: "center",
        boxShadow: 1,
        width: "12vw",
        backgroundColor: "#f5f5f5",
        height: "110%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
      alignItems="left"
    >
      <div style={{ gap: 15, display: "flex", flexDirection: "column", marginTop: 50, }}>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns")}
        >
          My Dashboard
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/blog")}
        >
          Blog & Insights
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/analyticsdashboard")}
        >
          Analytics
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/creatordiscovery")}
        >
          Creator Discovery
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/competitive/landscape")}
        >
          Landscape & Trends
        </Button>
        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/favorites")}
        >
          My Favorites
        </Button>
      </div>
      <div style={{ gap: 15, display: "flex", flexDirection: "column", marginBottom: 50}}>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/FAQ")}
        >
          Help & FAQ
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/account")}
        >
          My Account
        </Button>
      </div>
    </Box> */}
  </>

}

export default Layout;