import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from "react";
import { fetchGet, formatStat, PLATFORMS, INDUSTRIES, CREATOR_LEVEL, getCelebrityRange, parseFollowers, celebrityRanges, GROWTH_RATE, getRandomNumber, capitalizeFirstLetter } from "../util/helpers";
import { COUNTRIES } from "../util/constants";
import React from "react";


import toast, { Toaster } from "react-hot-toast";
import { InstagramEmbed } from 'react-social-media-embed';

import launch from "../assets/creatorOutreachAvatar.PNG";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Grid,
  Avatar,
  DialogContentText,
  Box,
  InputAdornment,
  Button as MuiButton,
  IconButton,
  Tooltip,
  tooltipClasses,
  Menu,
  FormControlLabel,
  Divider,
  Drawer,
  Slider,
  DialogActions,
  Chip,
  Button,
  Stack,
  Typography,
  Checkbox
} from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon, Add as AddIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faSliders, faClose, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok, IconBrandTwitter, IconBrandYoutube, IconFilter } from "@tabler/icons-react";



const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};

const PLATFORM_ICONS = {
  'www.instagram.com': <IconBrandInstagram size="25" className="icon-colored" color="var(--main-text-color)"/>,
  'www.facebook.com': <IconBrandFacebook size="25" className="icon-colored" color="var(--main-text-color)"/>,
  'www.tiktok.com': <IconBrandTiktok size="25" className="icon-colored" color="var(--main-text-color)"/>,
  'www.youtube.com': <IconBrandYoutube size="25" className="icon-colored" color="var(--main-text-color)"/>,
  'www.linkedin.com': <IconBrandLinkedin size="25" className="icon-colored" color="var(--main-text-color)"/>,
  'www.twitter.com': <IconBrandTwitter size="25" className="icon-colored" color="var(--main-text-color)"/>
};

export const CustomGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  height:'calc(100vh - 17rem)',
  width:"100%",
  [`& .${gridClasses.checkboxInput}.Mui-checked`]: {
    color: "#C334EB",
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: "#F9FAFB",
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontSize: ".8rem",
    fontWeight: "600"
  },
  [`& .${gridClasses.row}`]: {
    '&:hover': {
      backgroundColor: "#F9FAFB"
    }
  },
  '& .MuiDataGrid-row': {
    minHeight: '100px',
    maxHeight: '150px',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: '150px',
    alignContent:"center",
  },
  '& .MuiDataGrid-row:hover .custom-hover': {
    borderImage: "linear-gradient(to right, #4D8DFF, #C334EB) 2 !important",
    borderWidth: "2px !important"
  }
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1.5px solid black',
    BorderBottom: '2px solid black',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px', // Rounded border for the Menu
    marginTop: theme.spacing(.8), // Space between button and menu
  },
}));

const initialNewList = {
  "name": "",
  "description": "",
  "creators": []
}
export default function DiscoveryTable(props) {
  const [data, setData] = useState([]);
  const { campaignId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCreatorCardOpen, setConfirmCreatorCardOpen] = useState(false);
  const [creatorData, setCreatorData] = useState({});
  const [creatorName, setCreatorName] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [externalDialogOpen, setExternalDialogOpen] = useState(false);
  const [addNewListOpen, setAddNewListOpen] = useState(false);
  const [externalCreator, setExternalCreator] = useState(null)
  const [externalLoading, setExternalLoading] = useState(false)


  const [nameSearch, setNameSearch] = useState("");
  const [selectedPlatformOptions, setSelectedPlatformOptions] = useState([]);
  const [selectedIndustryOptions, setSelectedIndustryOptions] = useState([]);
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
  const [selectedFollowersOptions, setSelectedFollowersOptions] = useState([]);
  const [selectedGrowthOption, setSelectedGrowthOption] = useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [engagementRate, setEngagementRate] = useState([50, 70]);
  const [totalFilter, setTotalFilter] = useState(0);
  const [myList, setMyList] = useState(JSON.parse(localStorage.getItem("myList")) || []);
  const [newList, setNewList] = useState({ ...initialNewList });
  const [selectedMyList, setSelectedMyList] = useState([]);
  const [selectedRows,setSelectedRows] = useState([]);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const resetFilter = () => {
    setSelectedPlatformOptions([]);
    setSelectedIndustryOptions([]);
    setSelectedFollowersOptions([]);
    setSelectedCountryOptions([]);
    setSelectedGrowthOption([]);
    setEngagementRate([50, 70]);
  }


  const fetchOutreach = () => {
    fetchGet(`/brand/outreach?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        const listData = result.map((res) => ({ ...res, id: res.email, celebrityType: getCelebrityRange(parseFollowers(res.followers)) }));
        // listData[0]["link"] = ["https://www.instagram.com/chloenicholle/?hl=en", "https://www.facebook.com/profile.php?id=100092043892074"]
        setData([...listData]);
      });
  };

  useEffect(() => {
    let totalNumber = 0
    if (selectedCountryOptions.length > 0) totalNumber += 1;
    if (selectedPlatformOptions.length > 0) totalNumber += 1;
    if (selectedIndustryOptions.length > 0) totalNumber += 1;
    if (selectedFollowersOptions.length > 0) totalNumber += 1;

    setTotalFilter(totalNumber);

  }, [selectedCountryOptions, selectedPlatformOptions, selectedIndustryOptions, selectedFollowersOptions]);

  const columns = useMemo(
    () => [
      {
        field: "name", headerName: "CREATOR PROFILE", flex:2,
        renderCell: (params) => {
          return params.value && 
            <Stack direction="row" spacing={2} alignItems="center">
              <img 
                fetchPriority="high" 
                className="profile-image" 
                src={params.row?.profileImage 
                  ? params.row.profileImage 
                  : `../gradient${getRandomNumber(1, 10)}.png`} 
                alt={params.value}
              />
              <Stack direction="column" spacing={0}>
                <Typography variant="bodyregm" color="var(--main-text-color)">{params.value}</Typography>
                <Typography variant="bodyxs" color="var(--main-text-color)">@{params.row.handle}</Typography>
              </Stack>
            </Stack>
        }
      },
      { field: "industry", headerName: "INDUSTRY", flex:1, 
        renderCell: (params)=><LightTooltip title={params.value} slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10]
                }
              }
            ]
          }
        }}><Typography variant="body2" >{params.value.split(",").map(industry => capitalizeFirstLetter(industry.trim())).join(", ")}</Typography></LightTooltip>
      },

      {
        field: "platforms", headerName: "PLATFORMS", flex:1,
        renderCell: (params) => {
          console.log("params", params)
          const row = params.row
          const links = [row.tiktok, row.facebook, row.youtube, row.instagram, row.twitter, row.linkedin]
          return <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} style={{height: "50px"}}>{links.filter(link => link).map(link => <Linkplatform data={link} />)}</Stack>
        }
      },
      {
        field: "followers",
        headerName: "FOLLOWERS",
        flex:1,
        valueGetter: (params) => params.toUpperCase(),
        sortComparator: followerComparator,
        renderCell: (params) => {
          return <LightTooltip title={params.value} slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10]
                  }
                }
              ]
            }
          }}><Typography variant="body2">{params.value}</Typography></LightTooltip>
        }
      },
      {
        field: "location",
        headerName: "LOCATION",
       flex:1,
        valueGetter: (params) => (params || []).join(", "),
        renderCell: (params) => {
          return params.value && <LightTooltip title={params.value} slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10]
                  }
                }
              ]
            }
          }}>
              <Typography variant="body2" >
                {params.value}
              </Typography>
          </LightTooltip>
        }
      },
      {
        field: "action",
        type: "actions",
        headerName: "Action",
        width: 160,
        sortable: false,
        getActions: (params) => [
          <Button
                            onClick={() => {
                              setConfirmCreatorCardOpen(true);
                              setCreatorData(params.row);
                            }}
                            variant="outlined" className="outlined-button" color="info">Learn More</Button>,
            <Button
              variant="outlined"
              className="outlined-button"
              color="info"
              onClick={() => {
                if (params.row.id === "custom-row") {
                  setExternalDialogOpen(true)
                } else {
                  setConfirmCreatorCardOpen(true);
                  setCreatorData(params.row);
                }

              }}
            >
              Add to My List
            </Button>,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    fetchOutreach();
  }, [campaignId]);

  useEffect(() => {
    if (externalDialogOpen && nameSearch) {
      setExternalLoading(true)
      fetchGet(`/brand/external/outreach?username=${nameSearch}`)
        .then((res) => {
          console.log("response", res)
          setExternalCreator(res)
        })
        .finally(() => setExternalLoading(false))
    }
  }, [externalDialogOpen, nameSearch])

  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.handle.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };

    const checkPlatform = (row) => {
      if (selectedPlatformOptions.length !== 0) {
        const selectedPlatforms = selectedPlatformOptions.map(p => p.toLowerCase())
        const rowPlatforms = row?.platforms?.map(p => p.toLowerCase())
        console.log("checkplatform",selectedPlatforms, rowPlatforms)
        return selectedPlatforms.some(platform => rowPlatforms?.includes(platform));
      }
      return true;
    }

    const checkIndusties = (row) => {
      if (selectedIndustryOptions.length > 0) {
        const industryData = row.industry.split(",");
        const hasOther = selectedIndustryOptions.includes("other")
        return industryData.filter(value => selectedIndustryOptions.includes(value) || (hasOther)).length > 0;
      }
      return true;
    }

    const checkLocation = (row) => {
      if (selectedCountryOptions.length > 0) {
        return row.location.filter(value => selectedCountryOptions.includes(value)).length > 0;
      }
      return true;
    }

    const checkFollowers = (row) => {
      if (selectedFollowersOptions.length > 0) {
        return selectedFollowersOptions.includes(row.celebrityType);
      }
      return true;
    }
    const filtered = data.filter((rowData) => {
      
      return (
        checkName(rowData) && checkPlatform(rowData) && checkIndusties(rowData) && checkLocation(rowData) && checkFollowers(rowData)
      );
    });
    if (nameSearch && filtered.length < 10) {
      filtered.push({
        id: 'custom-row',
        name: "",
        followers: "",
        industry: "Can't find the user you're looking for?",
        location: [],
        relevance: "",
        viewMore: ""
    })
    }
 
    return filtered

  }, [
    creatorLevelOutreach,
    data,
    nameSearch,
    selectedPlatformOptions,
    selectedIndustryOptions,
    selectedCountryOptions,
    selectedFollowersOptions
  ]);


  const closeDialog = () => {
    setConfirmOpen(false);
    setCreatorEmail("");
    setCreatorName("");
  };

  const closeCreatorCardDialog = () => {
    setConfirmCreatorCardOpen(false);
    setSelectedMyList([])
    setCreatorData({});

  };

  const closeAddNewListDialog = () => {
    setAddNewListOpen(false);
    setNewList({...initialNewList})
    setConfirmCreatorCardOpen(true);
  }

  const createNewList = () => {
    myList.push({ ...newList });

    setMyList([...myList]);
    // save to local storage
    localStorage.setItem("myList", JSON.stringify(myList));
    closeAddNewListDialog();
  }

  const handleNewListChange = (e) => {
    setNewList({ ...newList, [e.target.name]: e.target.value });
  }

  const handleListSelection = (e) => {
    const value = e.target.value;
    setSelectedMyList((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  }

   const handleAddToLists = () => {
    selectedMyList.map((list)=>{
      const myListIndex = myList.findIndex((data)=>data.name === list)
      const newData = myList[myListIndex];
      if(creatorData.data !="multi"){
        if(!newData.creators.includes(creatorData.id)){
          newData.creators = [...newData.creators, creatorData.id]; 
        }
      }else{
        const arrayFromSet = new Set([...newData.creators, ...selectedRows]) 
        newData.creators = [...arrayFromSet];
        
      }
      myList[myListIndex] = {...newData};

    });
    setMyList(myList)
    localStorage.setItem("myList", JSON.stringify(myList));
    setSelectedMyList([]);
    closeCreatorCardDialog();
   }

  return (
    <div className="discovery-table-body">
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} style={{ width: 5000 }} />
      <CustomDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} setSelectedPlatformOptions={setSelectedPlatformOptions} selectedPlatformOptions={selectedPlatformOptions} selectedCountryOptions={selectedCountryOptions} selectedIndustryOptions={selectedIndustryOptions} setSelectedIndustryOptions={setSelectedIndustryOptions} setSelectedCountryOptions={setSelectedCountryOptions} selectedGrowthOption={selectedGrowthOption} setSelectedGrowthOption={setSelectedGrowthOption} engagementRate={engagementRate} setEngagementRate={setEngagementRate} />
      <Dialog fullWidth maxWidth="lg" open={externalDialogOpen} onClose={() => { setExternalDialogOpen(false); setExternalCreator(null) }}>
        {externalLoading && <DialogTitle>Fetching user data!</DialogTitle>}
        {!externalLoading && externalCreator === null && <DialogContent>
          <DialogTitle>Not Found</DialogTitle>
          The user {nameSearch} could not be found!
        </DialogContent>}
        {externalCreator !== null && <DialogContent>
          <DialogTitle>{nameSearch} <Avatar
            fetchPriority="high"
            src={launch}
            style={{ width: 80, height: 80, marginRight: 5, float: "right" }}
          /></DialogTitle>
          <Box sx={{ width: "95%", margin: "0 auto" }}>
            <DialogContentText>{externalCreator.full_name}</DialogContentText>
            <DialogContentText>{externalCreator.biography}</DialogContentText>
            <DialogContentText>{formatStat(externalCreator.follower_count)} Followers</DialogContentText>
            <DialogContentText>{formatStat(externalCreator.media_count)} Posts</DialogContentText>
            <Grid container>

              {externalCreator?.medias?.slice(0, 3).map(media => (<Grid item xs={4}> <InstagramEmbed width="100%" key={media.code} url={`https://www.instagram.com/p/${media.code}/`} /></Grid>))}
            </Grid>
          </Box>

        </DialogContent>}
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={confirmCreatorCardOpen} onClose={closeCreatorCardDialog}>
        <DialogTitle> <div className="dialog-header">
            <div className="title-main">
            Add to My List
            </div>
            <div className="title-sub">
              ({myList.length} List Available)
            </div> 
          </div></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeCreatorCardDialog}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="my-list-content">
            <TextField
              fullWidth
              className="name-search"
              onChange={(e) => {
              }}
              sx={{marginBottom:"1rem"}}
              size="small"
              placeholder="Search Lists..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{
                      fontSize: "20px"
                    }} />
                  </InputAdornment>
                ),
              }}
            />


            {
              myList.map((platform, index) => {
                return <div key={index} className={`mylist-option ${selectedMyList.includes(platform.name || platform) && 'checked-option'}`}>
                  <div className={`checkbox-container`}> <FormControlLabel
                    key={index}
                    control={
                      <input type="checkbox" className="checkbox" checked={selectedMyList.includes(platform.name || platform)} value={platform.name || platform} onChange={handleListSelection} />
                    }
                    label={platform.name || platform}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontWeight: 'bold', // Make the label bold
                      }
                    }}
                  /> </div>
                  <Chip sx={{
                    backgroundColor: '#F2F4F7'
                  }} label={`${platform.creators.length} Creators`} />
                </div>

              })
            }

            <MuiButton  onClick={() => { setConfirmCreatorCardOpen(false); setAddNewListOpen(true) }} variant="text" sx={{ color: "black", textTransform: "none", fontWeight: "600",display: 'flex',
            justifyContent: 'flex-start ',  // Aligns the content to the start
            width: '30% ', }} startIcon={<AddIcon sx={{color:"#667085"}} />} >Create New List</MuiButton>
          </div>
        </DialogContent>
        <DialogActions>
          <MuiButton className="custom-button" onClick={closeCreatorCardDialog}>Cancel</MuiButton>
          <MuiButton className="custom-button grd-bg-btn" onClick={handleAddToLists}>Add To Lists</MuiButton>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={addNewListOpen} onClose={closeAddNewListDialog}>
        <DialogTitle sx={{fontWeight:"600"}}>
          <div className="dialog-header">
              <div className="title-main">Create New List</div>
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeAddNewListDialog}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="my-list-content">
            <div className="field-title">Name</div>
            <TextField onChange={handleNewListChange} value={newList.name} name="name" size="small" placeholder="Enter my list name..." fullWidth variant="outlined" />
          
            <div className="field-title mrg-top">Description <span className="field-sub-title">(Optional)</span></div>
            <TextField onChange={handleNewListChange} value={newList.description} name="description" size="small" variant="outlined" placeholder="Write Description" multiline maxRows={4} minRows={2} fullWidth />
          </div>
        </DialogContent>
        <DialogActions>
          <MuiButton className="custom-button" onClick={closeAddNewListDialog}>Cancel</MuiButton>
          <MuiButton className="custom-button grd-bg-btn"  onClick={createNewList}>Create List</MuiButton>

        </DialogActions>
      </Dialog>
      <div className="discover_header">
        <TextField
          className="name-search"
          defaultValue={nameSearch}
          onChange={(e) => {
            setNameSearch(e.target.value);
          }}
          placeholder="Search Creators"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{
                  fontSize: "20px"
                }} />
              </InputAdornment>
            ),
          }}
        />
        {/* <MuiButton variant="outlined" className="custom-button"
          startIcon={<FontAwesomeIcon className="btn-icon" icon={faDownload} />}>Export</MuiButton> */}
      </div>

      <div className="discovery-table">
        <div className="row tbl-filter">
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={2}>
              <FilterDropdown btnText="Platform" btnIcon={faAngleDown} menuList={PLATFORMS} selectedOptions={selectedPlatformOptions} setSelectedOptions={setSelectedPlatformOptions} />
              <FilterDropdown btnText="Industry" btnIcon={faAngleDown} menuList={INDUSTRIES} selectedOptions={selectedIndustryOptions} setSelectedOptions={setSelectedIndustryOptions} />
              <FilterDropdown btnText="Followers" btnIcon={faAngleDown} menuList={CREATOR_LEVEL} selectedOptions={selectedFollowersOptions} setSelectedOptions={setSelectedFollowersOptions} />
              <FilterDropdown btnText="Location" btnIcon={faAngleDown} menuList={COUNTRIES} selectedOptions={selectedCountryOptions} setSelectedOptions={setSelectedCountryOptions} />
              <Button variant="outlined" className="outlined-button" color="info" onClick={toggleDrawer(true)}
                startIcon={<IconFilter className="icon-colored" size="18" color="var(--body-text-color-500)"/>}>All Filters</Button>
              {totalFilter !== 0 && <Button onClick={resetFilter} sx={{ color: "black", fontWeight: "600" }}>Reset</Button>}
            </Stack>
            {selectedRows.length > 0 && <Button
              variant="outlined"
              className="outlined-button"
              color="secondary"
              onClick={() => {
                  setConfirmCreatorCardOpen(true);
                  setCreatorData({data:"multi"});
              }}
            >
              Add Selected to My List
               </Button> }
          </Stack>
          {/* <div className="custom-icon-button"> */}
          {/* <IconButton sx={{
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            },
            backgroundColor: 'white',
            border: '1px solid #D0D5DD',
            borderRadius: "5px",
            color: "black",
            padding: "8px"
          }} aria-label="delete" size="small" className="cutom-b-icon">
            <FontAwesomeIcon className="btn-icon" icon={faSliders} />
          </IconButton> */}
          {/* </div> */}

        </div>

        {/* <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Creator Level</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Creator Level"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>Micro</MenuItem>
          <MenuItem value={"major"}>Major</MenuItem>
          <MenuItem value={"celebrity"}>Celebrity</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Follower Count</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Follower Count"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>0-50K</MenuItem>
          <MenuItem value={"major"}>50K-150K</MenuItem>
          <MenuItem value={"celebrity"}>150K-1M+ </MenuItem>


        </Select>
      </FormControl>
      <TextField
        label="Location"
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8, }}
        defaultValue={locationSearch}
        onChange={(e) => {
          setLocationSearch(e.target.value);
        }}
        InputProps={{
          style: {
            borderRadius: "50px",
          },
        }}
      /> */}

        {/* <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Compensation</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Compensation"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>$100-$2500</MenuItem>
          <MenuItem value={"major"}>$2500-$3000</MenuItem>
          <MenuItem value={"celebrity"}>$3000+ </MenuItem>


        </Select>
      </FormControl>


      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Gender</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorGenderSearch}
          label="Gender"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorGenderSearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>

        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Audience Tier</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorAudienceTier}
          label="Audience Tier"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorAudienceTier(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Luxury Audience</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Ethnicity</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorEthnicitySearch}
          label="Ethnicity"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorEthnicitySearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"asian"}>Asian</MenuItem>
          <MenuItem value={"white/caucasion"}>White/Caucasian</MenuItem>
          <MenuItem value={"black"}>Black</MenuItem>
          <MenuItem value={"hispanic"}>Hispanic</MenuItem>
          <MenuItem value={"indigenous"}>Indigenous</MenuItem>
        </Select>
      </FormControl> */}

        {/* <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
        /> */}
        <CustomGrid rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          pinnedColumns={{ right: ['action'] }}
          rowHeight={60}
          onRowSelectionModelChange={(item)=>{
            setSelectedRows([...item]);
          }}

        />
      </div>
    </div>
  );
}


const Linkplatform = ({ data }) => {
  const getPlatformIcon = (link) => {
    const parsedUrl = new URL(link);
    const hostname = parsedUrl.hostname.toLowerCase();

    return <a href={link} target="_blank" rel="noreferrer">{PLATFORM_ICONS[hostname]}</a>
  }


  return <Stack direction="row" spacing={1} alignItems="center">{
    typeof (data) === "string" ? getPlatformIcon(data) : data.map((link) => getPlatformIcon(link))
  }
  </Stack>
}

const FilterDropdown = ({ btnText, btnIcon, menuList, selectedOptions, setSelectedOptions, fullWidth = false, startIcon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (event) => {
    const value = event.target.value;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  };

  const selectedCount = selectedOptions.length;

  return (
    <div>
      {
        startIcon ? <Button variant="outlined"
          className="outlined-button"
          color="info"
          onClick={handleClick}
          endIcon={<FontAwesomeIcon className="btn-icon" icon={btnIcon} />}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textTransform: 'none',
            color: '#344054',
            width: "100%"
          }}
        ><div className="btn-body"><FontAwesomeIcon className="btn-icon" icon={startIcon} />{btnText} </div></Button>
          :
          <Button
            variant="outlined"
            color={selectedCount > 0 ? "secondary" : "info"}
            className="outlined-button"
            onClick={handleClick}
            endIcon={<FontAwesomeIcon className="btn-icon" icon={btnIcon} />}
          >
            {btnText} {selectedCount > 0 && `(${selectedCount})`}
          </Button>}
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          borderRadius: "20px !important"
        }}
      >
        {menuList.map((platform) => (
          <MenuItem key={platform.value || platform}>
            <FormControlLabel control={<Checkbox color="secondary" defaultChecked={selectedOptions.includes(platform.value || platform)} />} label={platform.label || platform} value={platform.value || platform} onChange={handleSelect}/>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

const CheckBoxGrid = ({ optionsList, selectedOptions, setSelectedOptions }) => {

  const handleSelect = (event) => {
    const value = event.target.value;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  };

  return <>{optionsList.map((platform, index) => (
    <FormControlLabel
      key={index}
      control={
        <input type="checkbox" className="checkbox" checked={selectedOptions.includes(platform.value || platform)} value={platform.value || platform} onChange={handleSelect} />
      }
      label={platform.label || platform}
    />
  ))}</>

}

const CustomDrawer = ({ openDrawer, toggleDrawer, setSelectedCountryOptions, selectedCountryOptions, setSelectedPlatformOptions, selectedPlatformOptions, setSelectedIndustryOptions, selectedIndustryOptions, engagementRate, setEngagementRate, setSelectedGrowthOption, selectedGrowthOption }) => {
  const [locationOptions, setLocationOptions] = useState(selectedCountryOptions);
  const [platformOptions, setPlatformOptions] = useState(selectedPlatformOptions);
  const [industryOptions, setIndustryOptions] = useState(selectedIndustryOptions);
  const [growthOptions, setGrowthOptions] = useState(selectedGrowthOption);
  const [selectedEngagementRate, setSelectedEngagementRate] = useState(engagementRate);
  const [totalFilter, setTotalFilter] = useState(0);

  useEffect(() => {
    let totalNumber = 0
    if (locationOptions.length > 0) totalNumber += 1;
    if (platformOptions.length > 0) totalNumber += 1;
    if (industryOptions.length > 0) totalNumber += 1;
    if (growthOptions.length > 0) totalNumber += 1;
    if (selectedEngagementRate.toString() != "50,70") totalNumber += 1;

    setTotalFilter(totalNumber);

  }, [locationOptions, platformOptions, industryOptions, selectedEngagementRate, growthOptions]);


  const handleSliderChange = (e, newValue) => {
    setSelectedEngagementRate(newValue);
  }

  const resetLocalFilter = () => {
    setLocationOptions([]);
    setPlatformOptions([]);
    setIndustryOptions([]);
    setGrowthOptions([]);
    setSelectedEngagementRate([50, 70])
  }
  const submitFilter = () => {
    setSelectedCountryOptions(locationOptions);
    setSelectedPlatformOptions(platformOptions);
    setSelectedIndustryOptions(industryOptions);
    setSelectedGrowthOption(growthOptions);
    setEngagementRate(selectedEngagementRate);
    toggleDrawer(false);
  }
  return <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}
    ModalProps={{
      keepMounted: true, // Better performance on mobile
      BackdropProps: {
        invisible: true, // This removes the backdrop
      },
    }}
    sx={{
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', top: 70, width: 400, height: "calc(100% - 70px)" },

    }}>
    <Box sx={{ overflowY: 'auto', width: 400, height: "100%" }} role="presentation">
      <div className="drawer-header">
        <div className="filter-header" ><FontAwesomeIcon className="btn-icon" icon={faSliders} />All Filters</div>
        <span onClick={toggleDrawer(false)} className="pointer-curser"><FontAwesomeIcon icon={faClose} className="bnt-icon" /></span>
      </div>
      <Divider />
      <div className="filter-body">
        <div className="title-text">
          Location
        </div>
        <FilterDropdown fullWidth btnText={"Location"} btnIcon={faAngleDown} startIcon={faLocationCrosshairs} menuList={COUNTRIES} selectedOptions={locationOptions} setSelectedOptions={setLocationOptions} />
        <Divider />
        <div className="title-text">
          Social Media Platform
        </div>
        <div className="checkbox-container">
          <CheckBoxGrid optionsList={PLATFORMS} setSelectedOptions={setPlatformOptions} selectedOptions={platformOptions} />
        </div>
        <Divider />
        <div className="title-text">
          Industry
        </div>
        <div className="checkbox-container">
          <CheckBoxGrid optionsList={INDUSTRIES} setSelectedOptions={setIndustryOptions} selectedOptions={industryOptions} />
        </div>
        <Divider />
        <div className="title-text">
          Engagement Rate
        </div>
        <Slider
          getAriaLabel={() => 'Temperature range'}
          value={selectedEngagementRate}
          onChange={handleSliderChange}
          valueLabelFormat={(value) => `${value}%`}
          valueLabelDisplay="on"
          min={0}
          max={100}
          sx={{
            '& .MuiSlider-track': {
              background: 'linear-gradient(to right, #4D8DFF, #C334EB )',
              border: 'none',
              height: "5px"
            },
            '& .MuiSlider-thumb': {
              border: '1.5px solid #4D8DFF',
              color: "white"

            },
            '& .MuiSlider-valueLabel': {
              color: '#000',
              fontSize: '0.875rem',
              backgroundColor: "unset",
              top: '50px'
            },
            '& .MuiSlider-rail': {
              opacity: 1,
              backgroundColor: '#e0e0e0',
            },
          }}
        />
        <Divider sx={{ marginTop: "25px" }} />
        <div className="title-text">

          Growth Rate
        </div>
        <div className="checkbox-container"><CheckBoxGrid optionsList={GROWTH_RATE} selectedOptions={growthOptions} setSelectedOptions={setGrowthOptions} /></div>
      </div>
      <Divider sx={{ height: "3px", backgroundColor: "#EAECF0" }} />
      <div className="filter-bottom">
        <MuiButton className="custom-button" onClick={() => { resetLocalFilter() }}>Reset</MuiButton>
        <MuiButton className="custom-button grd-bg-btn" onClick={submitFilter}>Show {totalFilter} Results</MuiButton>
      </div>




    </Box>

  </Drawer>
}
