import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import LineChart from "../components/LineChart";
import FileUpload from "../components/FileUpload";
import host from "../assets/host.jpg";
import { ReactComponent as EmptyAnalytics } from "../assets/empty-analytics.svg";
import { INDUSTRIES } from "../util/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";
import { logout } from "../util/helpers";

export default function MyAccount() {
  const navigate = useNavigate();
  const { authed, refreshUser, user } = useContext(UserContext);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          textAlign: "left",
          marginTop: 5,
          color: "white",
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", marginLeft: "15vw" }}
        >
          My Account
        </Typography>
      </div>

      <Grid container>
        <div style={{ width: "90vw" }}>
          <Grid container>
            <Grid item xs={2} style={{ textAlign: "left" }}>
              <Box
                sx={{
                  textAlign: "center",
                  boxShadow: 1,
                  minHeight: 800,
                  width: "12vw",
                  backgroundColor: "#f5f5f5",
                }}
                alignItems="left"
              >
                <Button
                  basic
                  style={{
                    marginTop: 50,
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns")}
                >
                  My Dashboard
                </Button>

                <Button
                  basic
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/blog")}
                >
                  Blog & Insights
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns/analyticsdashboard")}
                >
                  Analytics
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 480,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns/creatordiscovery")}
                >
                  Creator Discovery
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    backgroundColor: "#4158D0",
                    backgroundImage:
                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns/FAQ")}
                >
                  Help & FAQ
                </Button>

                <Button
                  style={{
                    borderRadius: 1,
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                  }}
                  onClick={() => navigate("/campaigns/account")}
                >
                  My Account
                </Button>
              </Box>
            </Grid>

            <Grid item xs={9} style={{ marginTop: 50 }}>
              <Typography style={{ fontWeight: 600 }}>Email:</Typography>

              <Typography>{user.email}</Typography>

              <br />
              <br />

              <Typography style={{ fontWeight: 600 }}>
                Want to add new team members to your Gigl Account?
              </Typography>
              <br />

              <Typography>
                Contact us with your request and the additional emails you would
                like to give access: support@giglgroup.com
                <br></br>
                Please note: Gigl's Collaboration studio is only available with
                an Enterprise plan.
              </Typography>

              <br />

              <Button
                style={{
                  borderRadius: 1,
                  width: "40%",
                  marginBottom: 10,
                  fontSize: 12,
                  borderRadius: 6,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                }}
                href={
                  "mailto:support@giglgroup.com?subject=Collaboration Studio Request"
                }
              >
                Add New Team Members
              </Button>
              <br />
              <br />

              <Typography style={{ fontWeight: 600 }}>
                Want to upgrade or close your subscription?
              </Typography>
              <br />

              <Typography>
                Contact us with your request: support@giglgroup.com
                <br></br>
                In your email please share your account email and your account
                change requirements.
              </Typography>

              <br />

              <Button
                style={{
                  borderRadius: 1,
                  width: "40%",
                  marginBottom: 10,
                  fontSize: 12,
                  borderRadius: 6,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                }}
                href={"mailto:support@giglgroup.com?subject=Account Update"}
              >
                Email Us
              </Button>
              <br />
              <br />

              <Typography style={{ fontWeight: 600 }}>Sign Out</Typography>
              <br />

              <Button
                style={{
                  borderRadius: 6,
                  width: "40%",
                  marginBottom: 10,
                  fontSize: 12,
                }}
                onClick={() => logout()}
              >
                Logout
              </Button>

              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
