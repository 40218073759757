// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDr7Vax9F5Xyn87wSoaabr7OiBgu2Lknwc",
  authDomain: "gigl-bd30b.firebaseapp.com",
  projectId: "gigl-bd30b",
  storageBucket: "gigl-bd30b.appspot.com",
  messagingSenderId: "156889051729",
  appId: "1:156889051729:web:d5d99a7d50ffe7a95bbe70",
  measurementId: "G-6TM8KT50Z1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
