import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";
import blogpic2 from "../assets/blogpic2.PNG";

import * as React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function BlogArticle2(props) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 200 : 400,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 16 : 40,
            width: isMobile ? "80%" : "75%",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          3 Tips to launch a successful social media campaign, according to top
          creators
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: isMobile ? 10 : 25,
            width: isMobile ? "80%" : "80%",
          }}
        >
          We asked @feeedmi, one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media.
          <br></br>
          <br></br>
          By: @feeedmi | 1.4 Million Followers | Top Gigl Creator
          <br></br>
          Date: 01/15/2024
        </p>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <Grid
            elevation={3}
            sx={{
              height: isMobile ? 340 : 420,
              float: isMobile ? "" : "right",
              borderRadius: 10,
              marginBottom: 2,
              width: "35vw",
              marginRight: 10,
            }}
          >
            <img
              src={blogpic2}
              height="100%"
              style={{
                borderRadius: 20,
                maxHeight: isMobile ? 400 : 500,
                maxWidth: isMobile ? "40vw" : "",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
          marginBottom: 100,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "left" : "center",
          maxWidth: isMobile ? "100vw" : "80vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: 1500,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 80 : "",
            width: isMobile ? "80%" : "80%",
          }}
        >
          We asked @feeedmi, one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media. Her
          channel has over 1.4 Million followers and 1 Billion views!<br></br>
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 5 : 5,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          #1 ➡️ Influencer Marketing is key to driving reach and engagement:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 300 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          If you’re not using #influencermarketing as part of your marketing
          strategy you’re likely not effectively reaching and engaging people.
          Almost 60% of consumers said they purchased a product because they saw
          an influencer using it, and I personally have also purchased many
          products after seeing other creators use them. If you want to work
          with top influencers and creators check out Gigl: www.giglgroup.com
          <br></br>,<br></br>
          As a result, influencer marketing is one of the most cost-effective
          marketing strategies for 2024. As economic uncertainty grows and
          budgets are constrained, influencer marketing is one of the best
          strategies to boost reach, engagement, and sales for a small to large
          brand.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          #2 ➡️ #storytelling is key:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 80 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          I love to share my personal stories and experiences with my community,
          and those stories resonate. This helps you build a community that
          connects with you.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
            fontStyle: "italic",
            height: isMobile ? 100 : "",
          }}
        >
          “As a social media influencer, staying consistent and relevant is key
          to building a community” - @feeedmi
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          #3 ➡️ The Hook is Everything:
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 200 : "",
          }}
        >
          These days, everyone is busy and there is so much amazing content out
          there. So to stand out, you need to show why your content is valuable
          in the first three seconds before someone swipes away and misses out
          on your #insights. <br></br>
          <br></br>
          <br></br>
          As a social media influencer, and I am very grateful and thankful for
          the amazing community I have built on my channel. Social media is an
          incredibly powerful way to reach and engage people, and you can do it
          too!
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
            marginTop: 50,
            height: isMobile ? 80 : "",
          }}
        >
          Want to take your business to the next level through social media
          marketing?
          <br></br>
          Sign up on Gigl to launch your next influencer marketing campaign.
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: 40,
            marginLeft: isMobile ? 30 : 0,
          }}
          onClick={() => navigate("/register")}
        >
          Get Started on Gigl Today
        </Button>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 250 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
          height: isMobile ? 350 : 450,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 15 : 18,
            marginBottom: 10,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          Check Out the Next Article
        </Typography>

        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          How to measure influencer marketing: Key Tips & Strategies
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 25,
          }}
        >
          There are many ways to measure the success of your influencer
          marketing campaign, and they largely depend on your goals - Is it
          brand awareness? Sales? Engagement...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - Gigl Expert
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle3")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic3}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 0 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 20,
        }}
      ></Grid>
    </>
  );
}
