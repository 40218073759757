import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";


import { Button, CreatorLeftSideBar } from "../components";
import TikTokImg from "../assets/tiktok.png"
import YutubeImg from "../assets/youtube.png"
import FaceBookImg from "../assets/facebook.png"
import { fetchGet, fetchPut, GenderOptions, GenderValueToLabelMap } from "../util/helpers";

import {
    Typography,
    Grid,
    TextField,
    TextareaAutosize,
    IconButton,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";

import { EditOutlined, EditOffOutlined } from "@mui/icons-material";

const CreatorMyAccount = () => {
    const [rightGridHeight, setRightGridHeight] = useState(0);
    const rightGridRef = useRef(null);
    const navigate = useNavigate();
    const [isEditable,setIsEditable] = useState(false);
    const [myAccountData,setMyAccountData] = useState({description:"",
        dob:"",
        gender:"",
        thirtySecRate:"",
        staticPostRate:"",
        carouselPostRate:"",
        longVideoRate:""});

    const editIconStyle = {
        width:50,
        height:50,
        color:isEditable?"gray":"blue"
    }

    useEffect(()=>{
        fetchUserData();
    },[]);

    const fetchUserData = async () => {
        const response = await fetchGet("/creator/users/me");
        setMyAccountData(response.result)

    }



    useEffect(() => {
        if (rightGridRef.current) {
            const updateHeight = () => {
                setRightGridHeight(rightGridRef.current.clientHeight);
            };


            updateHeight();

            window.addEventListener('resize', updateHeight);

            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, [rightGridRef.current]);

    const setLogin = async (type) => {
        try {
            const redirectLink = await fetchGet(`/oauth/${type}`);
            if (redirectLink.url) window.open(redirectLink.url, "_blank");
        } catch (e) {
            console.log("Error while fetching platform login URL", e);
            toast.error("Something went wrong! Please, try again later.")
        }
    }

    const handleChansge = (event) =>{

        setMyAccountData({...myAccountData,[event.target.name]:event.target.value});
    }

    const updateMyaccount = async () => {
        try {
            const response = await fetchPut("/creator/users/me", { ...myAccountData });
            toast.success("Profile updated succfully!");
        } catch (e) {
            toast.error("Something went wrong! Please try again");
        }
    }
    return <>
        <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

        <div
            style={{
                width: "100%",
                height: "100%",
                background: "black",
                textAlign: "left",
                marginTop: 5,
                color: "white",
                paddingTop: 10,
                paddingBottom: 20,
            }}
        >
            <Typography
                variant="h2"
                component="div"
                style={{ color: "white", marginLeft: "16vw" }}
            >
                My Account
            </Typography>
        </div>
        <Grid container>
            <div style={{ width: "100vw" }}>
                <Grid container>
                    <Grid item xs={2} style={{ textAlign: "left", height: { rightGridHeight } }}>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginTop: 50, display: "flex", flexDirection: "column", gap: 20 }} ref={rightGridRef}>
                        <Typography style={{ fontWeight: 600 }}>
                            Connect Your Account
                        </Typography>
                        <Typography>
                            Make sure to connect your account so that brands can see your amazing channel and verify that you are a legitimate creator. Complete this step finish your account verification
                        </Typography>
                        <Button
                            style={{
                                textAlign: "center",
                                display: "flex",
                                width: "25rem"
                            }}
                            name="tiktok"
                            onClick={(e) => setLogin(e.target.name)}
                            startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={TikTokImg} height="100%" width="100%" /></div>}
                        >Login with TikTok</Button>
                        <Button
                            style={{
                                textAlign: "center",
                                display: "flex",
                                width: "25rem"
                            }}
                            name="youtube"
                            onClick={(e) => setLogin(e.target.name)}

                            startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={YutubeImg} height="100%" width="100%" /></div>}
                        >Login with Youtube</Button>
                        <Button
                            style={{
                                textAlign: "center",
                                display: "flex",
                                width: "25rem"
                            }}
                            name="facebook"
                            onClick={(e) => setLogin(e.target.name)}

                            startIcon={<div style={{ backgroundColor: "white", height: "2rem", width: "2rem", borderRadius: "50%" }}><img src={FaceBookImg} height="100%" width="100%" /></div>}
                        >Login with Facebook</Button>
                        <Typography>
                            Facebook verification includes Instagram as well. You will need a Facebook Business Account connected to your Instagram to be verified.
                        </Typography>

                        <form style={{backgroundColor:"rgb(196, 195, 208, .2)",padding:20}}>
                            <Typography variant="h6" style={{ fontWeight: 600, marginBottom: 20,display:"flex",width:"100%",justifyContent:"space-between" }}>
                                <span>About Me</span>
                                <IconButton
                                    sx={editIconStyle}
                                    onClick={()=>setIsEditable(!isEditable)}
                                >
                                    {isEditable?<EditOffOutlined/>:<EditOutlined />}
                                </IconButton>
                            </Typography>
                            <TextareaAutosize name="description" placeholder="Tell me about yourself" minRows={5} style={{ width: "100%", marginBottom: 20 }} disabled={!isEditable} value={myAccountData.description} onChange={(event)=>handleChansge(event)} />
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    Date of Birth (mm/dd/yyyy)
                                </Typography>
                                <TextField
                                    type="birthday"
                                    name="dob"
                                    sx={{ width: "40%" }}
                                    placeholder="Enter DOB(mm/dd/yyyy)"
                                    disabled={!isEditable}
                                    value={myAccountData.dob} onChange={(event)=>handleChansge(event)}
                                />

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    Gender
                                </Typography>
                                <FormControl required style={{ width: "40%" }}>
                                    <Select
                                        value={myAccountData.gender || []}
                                        name="gender"
                                        required
                                        onChange={(e) => handleChansge(e)}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em style={{ color: "gray" }}>Select gender</em>;
                                            }
                                            return GenderValueToLabelMap[selected];
                                        }}
                                        disabled={!isEditable}
                                    >
                                        {GenderOptions.map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </div>
                            <Typography variant="h6" style={{ fontWeight: 600, marginBottom: 20 }}>
                                My Collaboration Rates
                            </Typography>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    30s Video
                                </Typography>
                                <TextField
                                    name="thirtySecRate"
                                    sx={{ width: "40%" }}
                                    placeholder="$ Dollar amount"
                                    disabled={!isEditable}
                                    value={myAccountData.thirtySecRate} onChange={(event)=>handleChansge(event)}
                                />

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    Static In-feed Post
                                </Typography>
                                <TextField
                                    name="staticPostRate"
                                    sx={{ width: "40%" }}
                                    placeholder="$ Dollar amount"
                                    disabled={!isEditable}
                                    value={myAccountData.staticPostRate} onChange={(event)=>handleChansge(event)}
                                />

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    Carousel In-feed Post
                                </Typography>
                                <TextField
                                    name="carouselPostRate"
                                    sx={{ width: "40%" }}
                                    placeholder="$ Dollar amount"
                                    disabled={!isEditable}
                                    value={myAccountData.carouselPostRate} onChange={(event)=>handleChansge(event)}
                                />

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", gap: 5, marginBottom: 20, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 600, width: "40%" }}>
                                    Long Form Video (90s-3min)
                                </Typography>
                                <TextField
                                    name="longVideoRate"
                                    sx={{ width: "40%" }}
                                    placeholder="$ Dollar amount"
                                    disabled={!isEditable}
                                    value={myAccountData.longVideoRate} onChange={(event)=>handleChansge(event)}
                                />

                            </div>
                        </form>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                                <Button style={{ width: 300 }} onClick={()=>isEditable && updateMyaccount()}>Submit</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    </>

}

export default CreatorMyAccount;