import { FormControl, TextField } from "@mui/material";
import { useState } from "react";
import Button from "./components/Button";
import { fetchPost } from "./util/helpers";

export default function TempAddAnalytics() {
  const [code, setCode] = useState("");
  const [likeCount, setLikeCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [date, setDate] = useState("");
  const reset = () => {
    setLikeCount(0);
    setShareCount(0);
    setViewCount(0);
    setCommentCount(0);
    setDate("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetchPost("/brand/campaign-analytics", {
        code: code,
        comment_count: commentCount,
        like_count: likeCount,
        share_count: shareCount,
        view_count: viewCount,
        date: date,
      });
      alert("Successfully submitted");

      reset();
    } catch (err) {
      alert("Failed to submit");
    }
  };
  return (
    <>
      <div style={{ margin: "0 auto", width: "50vw" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            label="code"
            onChange={(e) => setCode(e.target.value)}
            fullWidth
          />
          <TextField
            required
            label="Views"
            type="number"
            onChange={(e) => setViewCount(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <TextField
            required
            label="Likes"
            type="number"
            onChange={(e) => setLikeCount(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <TextField
            required
            label="Shares"
            type="number"
            onChange={(e) => setShareCount(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <TextField
            required
            label="Comments"
            type="number"
            onChange={(e) => setCommentCount(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <TextField
            required
            label="Date"
            placeholder="2023-12-11"
            onChange={(e) => setDate(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <Button type="submit" sx={{ marginTop: 2, width: "100%" }}>
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}
