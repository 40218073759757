import { TextField, Typography, useMediaQuery } from "@mui/material";

export default function FormInput(props) {
  const { name, setForm, label, ...other } = props;
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <div
      style={{
        width: isMobile ? "100%" : "40vw",
        margin: "0 auto",
        ...(!isMobile && { minWidth: 400 }),
      }}
    >
      <Typography variant="h5" style={{ textAlign: "left" }}>
        {label}
      </Typography>
      <TextField
        fullWidth
        name={name}
        onChange={(e) =>
          setForm((prev) => ({ ...prev, [name]: e.target.value }))
        }
        {...other}
      />
    </div>
  );
}
